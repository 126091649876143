/*
----------------------------------------------------------------
  * Location List styles 
----------------------------------------------------------------
*/

.location__list {
  display: flex;
  align-items: center;
  // gap: 16px;
}

/*
----------------------------------------------------------------
  * Location Item styles 
----------------------------------------------------------------
*/
.location__item {
  &__wr {
    display: flex;
    align-items: center;
    padding: var(--layout-spacing-4) var(--layout-spacing-8);
    position: relative;

    &::before {
      content: "";
      width: 1px;
      height: var(--layout-size-16);
      background-color: #d4d6db;
      display: block;
      position: absolute;
      left: 0;
    }

    &__left {
      height: 100%;
    }

    &__right {
      padding-left: var(--layout-spacing-8);
      flex: 1;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      max-width: 100%;
      object-fit: contain;

      &.icon__moon {
        width: 16px;
      }
    }
  }

  &__area {
    font-size: var(--x-small-regular-font-size);
    color: #aaaeb7;
    white-space: nowrap;
    line-height: 140%;
  }

  &__time {
    font-size: var(--x-small-regular-font-size);
    color: #7f8694;
    white-space: nowrap;
    font-weight: 500;
    line-height: 140%;
  }

  &--active {
    background: #ecf6f7;

    .location__item__area {
      color: #6a7282;
    }
    .location__item__time {
      color: #245a6c;
    }
  }

  &:first-child,
  &.location__item--active,
  &.location__item--active + .location__item {
    .location__item__wr {
      &::before {
        display: none;
      }
    }
  }
}
