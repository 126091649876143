/*!
 * Bootstrap  v5.2.0 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 :root {
    --h1: 20.25px;
    --h2: 18px;
    --h3: 16px;
    --h4: 14.22px;
    --h5: 12.64px;
    --h6: 11.24px;
    --small: 9.99px;
    --letter-spacing: -0.03rem;
  }
  :root {
    --bs-green: #347084;
    --bs-green-light: #56b5bc;
    --bs-green-dark: #245a6c;
    --bs-red: #eb6161;
    --bs-orange: #e89f7a;
    --bs-beige: #e4d8ca;
    --bs-yellow: #fddb8a;
    --bs-blue: #64c7ff;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #245a6c;
    --bs-secondary: #6c757d;
    --bs-success: #56b5bc;
    --bs-info: #64c7ff;
    --bs-warning: #fddb8a;
    --bs-danger: #eb6161;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 36, 90, 108;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 86, 181, 188;
    --bs-info-rgb: 100, 199, 255;
    --bs-warning-rgb: 253, 219, 138;
    --bs-danger-rgb: 235, 97, 97;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 250, 253, 255;
    --bs-font-sans-serif: "Roboto", "Helvetica Neue", sans-serif;
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
    );
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 0.875rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fafdff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #245a6c;
    --bs-link-hover-color: #1d4856;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff8e8;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  @media (prefers-reduced-motion: no-preference) {
    :root {
      scroll-behavior: smooth;
    }
  }
  body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
  }
  h6,
  .h6,
  h5,
  .h5,
  h4,
  .h4,
  h3,
  .h3,
  h2,
  .h2,
  h1,
  .h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }
  h1,
  .h1 {
    font-size: calc(1.34375rem + 1.125vw);
  }
  @media (min-width: 1200px) {
    h1,
    .h1 {
      font-size: 2.1875rem;
    }
  }
  h2,
  .h2 {
    font-size: calc(1.3rem + 0.6vw);
  }
  @media (min-width: 1200px) {
    h2,
    .h2 {
      font-size: 1.75rem;
    }
  }
  h3,
  .h3 {
    font-size: calc(1.278125rem + 0.3375vw);
  }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      font-size: 1.53125rem;
    }
  }
  h4,
  .h4 {
    font-size: calc(1.25625rem + 0.075vw);
  }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      font-size: 1.3125rem;
    }
  }
  h5,
  .h5 {
    font-size: 1.09375rem;
  }
  h6,
  .h6 {
    font-size: 0.875rem;
  }
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  abbr[title] {
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none;
  }
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  ol,
  ul {
    padding-left: 2rem;
  }
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  dt {
    font-weight: 700;
  }
  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
  blockquote {
    margin: 0 0 1rem;
  }
  b,
  strong {
    font-weight: bolder;
  }
  small,
  .small {
    font-size: 0.875em;
  }
  mark,
  .mark {
    padding: 0.1875em;
    background-color: var(--bs-highlight-bg);
  }
  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
  }
  sub {
    bottom: -0.25em;
  }
  sup {
    top: -0.5em;
  }
  a {
    color: var(--bs-link-color);
    text-decoration: none;
  }
  a:hover {
    color: var(--bs-link-hover-color);
  }
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
  }
  pre,
  code,
  kbd,
  samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
  }
  pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
  }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  code {
    font-size: 0.875em;
    color: var(--bs-code-color);
    word-wrap: break-word;
  }
  a > code {
    color: inherit;
  }
  kbd {
    padding: 0.1875rem 0.375rem;
    font-size: 0.875em;
    color: var(--bs-body-bg);
    background-color: var(--bs-body-color);
    border-radius: 0.25rem;
  }
  kbd kbd {
    padding: 0;
    font-size: 1em;
  }
  figure {
    margin: 0 0 1rem;
  }
  img,
  svg {
    vertical-align: middle;
  }
  table {
    caption-side: bottom;
    border-collapse: collapse;
  }
  caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #6c757d;
    text-align: left;
  }
  th {
    text-align: inherit;
    text-align: -webkit-match-parent;
  }
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }
  label {
    display: inline-block;
  }
  button {
    border-radius: 0;
  }
  button:focus:not(:focus-visible) {
    outline: 0;
  }
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  button,
  select {
    text-transform: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  select {
    word-wrap: normal;
  }
  select:disabled {
    opacity: 1;
  }
  [list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
    display: none !important;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer;
  }
  ::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  textarea {
    resize: vertical;
  }
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
  }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem;
    }
  }
  legend + * {
    clear: left;
  }
  ::-webkit-datetime-edit-fields-wrapper,
  ::-webkit-datetime-edit-text,
  ::-webkit-datetime-edit-minute,
  ::-webkit-datetime-edit-hour-field,
  ::-webkit-datetime-edit-day-field,
  ::-webkit-datetime-edit-month-field,
  ::-webkit-datetime-edit-year-field {
    padding: 0;
  }
  ::-webkit-inner-spin-button {
    height: auto;
  }
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
  }
  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::file-selector-button {
    font: inherit;
    -webkit-appearance: button;
  }
  output {
    display: inline-block;
  }
  iframe {
    border: 0;
  }
  summary {
    display: list-item;
    cursor: pointer;
  }
  progress {
    vertical-align: baseline;
  }
  [hidden] {
    display: none !important;
  }
  .lead {
    font-size: 1.09375rem;
    font-weight: 300;
  }
  .display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2;
  }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem;
    }
  }
  .display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2;
  }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem;
    }
  }
  .display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2;
  }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem;
    }
  }
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2;
  }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem;
    }
  }
  .display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2;
  }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem;
    }
  }
  .display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2;
  }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem;
    }
  }
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  .list-inline-item {
    display: inline-block;
  }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  .initialism {
    font-size: 0.875em;
    text-transform: uppercase;
  }
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem;
  }
  .blockquote > :last-child {
    margin-bottom: 0;
  }
  .blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.875em;
    color: #6c757d;
  }
  .blockquote-footer::before {
    content: "— ";
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fafdff;
    border: 1px solid var(--bs-border-color);
    border-radius: 0.375rem;
    max-width: 100%;
    height: auto;
  }
  .figure {
    display: inline-block;
  }
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  .figure-caption {
    font-size: 0.875em;
    color: #6c757d;
  }
  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }
  @media (min-width: 576px) {
    .container-sm,
    .container {
      max-width: 540px;
    }
  }
  @media (min-width: 768px) {
    .container-md,
    .container-sm,
    .container {
      max-width: 720px;
    }
  }
  @media (min-width: 992px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1140px;
    }
  }
  @media (min-width: 1400px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
      max-width: 1320px;
    }
  }
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
  .grid {
    display: grid;
    grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
    grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
    gap: var(--bs-gap, 1.5rem);
  }
  .grid .g-col-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-1 {
    grid-column-start: 1;
  }
  .grid .g-start-2 {
    grid-column-start: 2;
  }
  .grid .g-start-3 {
    grid-column-start: 3;
  }
  .grid .g-start-4 {
    grid-column-start: 4;
  }
  .grid .g-start-5 {
    grid-column-start: 5;
  }
  .grid .g-start-6 {
    grid-column-start: 6;
  }
  .grid .g-start-7 {
    grid-column-start: 7;
  }
  .grid .g-start-8 {
    grid-column-start: 8;
  }
  .grid .g-start-9 {
    grid-column-start: 9;
  }
  .grid .g-start-10 {
    grid-column-start: 10;
  }
  .grid .g-start-11 {
    grid-column-start: 11;
  }
  @media (min-width: 576px) {
    .grid .g-col-sm-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-sm-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-sm-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-sm-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-sm-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-sm-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-sm-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-sm-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-sm-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-sm-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-sm-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-sm-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-sm-1 {
      grid-column-start: 1;
    }
    .grid .g-start-sm-2 {
      grid-column-start: 2;
    }
    .grid .g-start-sm-3 {
      grid-column-start: 3;
    }
    .grid .g-start-sm-4 {
      grid-column-start: 4;
    }
    .grid .g-start-sm-5 {
      grid-column-start: 5;
    }
    .grid .g-start-sm-6 {
      grid-column-start: 6;
    }
    .grid .g-start-sm-7 {
      grid-column-start: 7;
    }
    .grid .g-start-sm-8 {
      grid-column-start: 8;
    }
    .grid .g-start-sm-9 {
      grid-column-start: 9;
    }
    .grid .g-start-sm-10 {
      grid-column-start: 10;
    }
    .grid .g-start-sm-11 {
      grid-column-start: 11;
    }
  }
  @media (min-width: 768px) {
    .grid .g-col-md-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-md-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-md-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-md-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-md-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-md-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-md-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-md-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-md-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-md-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-md-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-md-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-md-1 {
      grid-column-start: 1;
    }
    .grid .g-start-md-2 {
      grid-column-start: 2;
    }
    .grid .g-start-md-3 {
      grid-column-start: 3;
    }
    .grid .g-start-md-4 {
      grid-column-start: 4;
    }
    .grid .g-start-md-5 {
      grid-column-start: 5;
    }
    .grid .g-start-md-6 {
      grid-column-start: 6;
    }
    .grid .g-start-md-7 {
      grid-column-start: 7;
    }
    .grid .g-start-md-8 {
      grid-column-start: 8;
    }
    .grid .g-start-md-9 {
      grid-column-start: 9;
    }
    .grid .g-start-md-10 {
      grid-column-start: 10;
    }
    .grid .g-start-md-11 {
      grid-column-start: 11;
    }
  }
  @media (min-width: 992px) {
    .grid .g-col-lg-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-lg-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-lg-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-lg-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-lg-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-lg-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-lg-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-lg-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-lg-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-lg-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-lg-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-lg-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-lg-1 {
      grid-column-start: 1;
    }
    .grid .g-start-lg-2 {
      grid-column-start: 2;
    }
    .grid .g-start-lg-3 {
      grid-column-start: 3;
    }
    .grid .g-start-lg-4 {
      grid-column-start: 4;
    }
    .grid .g-start-lg-5 {
      grid-column-start: 5;
    }
    .grid .g-start-lg-6 {
      grid-column-start: 6;
    }
    .grid .g-start-lg-7 {
      grid-column-start: 7;
    }
    .grid .g-start-lg-8 {
      grid-column-start: 8;
    }
    .grid .g-start-lg-9 {
      grid-column-start: 9;
    }
    .grid .g-start-lg-10 {
      grid-column-start: 10;
    }
    .grid .g-start-lg-11 {
      grid-column-start: 11;
    }
  }
  @media (min-width: 1200px) {
    .grid .g-col-xl-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-xl-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-xl-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-xl-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-xl-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-xl-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-xl-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-xl-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-xl-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-xl-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-xl-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-xl-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-xl-1 {
      grid-column-start: 1;
    }
    .grid .g-start-xl-2 {
      grid-column-start: 2;
    }
    .grid .g-start-xl-3 {
      grid-column-start: 3;
    }
    .grid .g-start-xl-4 {
      grid-column-start: 4;
    }
    .grid .g-start-xl-5 {
      grid-column-start: 5;
    }
    .grid .g-start-xl-6 {
      grid-column-start: 6;
    }
    .grid .g-start-xl-7 {
      grid-column-start: 7;
    }
    .grid .g-start-xl-8 {
      grid-column-start: 8;
    }
    .grid .g-start-xl-9 {
      grid-column-start: 9;
    }
    .grid .g-start-xl-10 {
      grid-column-start: 10;
    }
    .grid .g-start-xl-11 {
      grid-column-start: 11;
    }
  }
  @media (min-width: 1400px) {
    .grid .g-col-xxl-1 {
      grid-column: auto/span 1;
    }
    .grid .g-col-xxl-2 {
      grid-column: auto/span 2;
    }
    .grid .g-col-xxl-3 {
      grid-column: auto/span 3;
    }
    .grid .g-col-xxl-4 {
      grid-column: auto/span 4;
    }
    .grid .g-col-xxl-5 {
      grid-column: auto/span 5;
    }
    .grid .g-col-xxl-6 {
      grid-column: auto/span 6;
    }
    .grid .g-col-xxl-7 {
      grid-column: auto/span 7;
    }
    .grid .g-col-xxl-8 {
      grid-column: auto/span 8;
    }
    .grid .g-col-xxl-9 {
      grid-column: auto/span 9;
    }
    .grid .g-col-xxl-10 {
      grid-column: auto/span 10;
    }
    .grid .g-col-xxl-11 {
      grid-column: auto/span 11;
    }
    .grid .g-col-xxl-12 {
      grid-column: auto/span 12;
    }
    .grid .g-start-xxl-1 {
      grid-column-start: 1;
    }
    .grid .g-start-xxl-2 {
      grid-column-start: 2;
    }
    .grid .g-start-xxl-3 {
      grid-column-start: 3;
    }
    .grid .g-start-xxl-4 {
      grid-column-start: 4;
    }
    .grid .g-start-xxl-5 {
      grid-column-start: 5;
    }
    .grid .g-start-xxl-6 {
      grid-column-start: 6;
    }
    .grid .g-start-xxl-7 {
      grid-column-start: 7;
    }
    .grid .g-start-xxl-8 {
      grid-column-start: 8;
    }
    .grid .g-start-xxl-9 {
      grid-column-start: 9;
    }
    .grid .g-start-xxl-10 {
      grid-column-start: 10;
    }
    .grid .g-start-xxl-11 {
      grid-column-start: 11;
    }
  }
  .col {
    flex: 1 0 0%;
  }
  .row-cols-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-1 {
    margin-left: 8.33333333%;
  }
  .offset-2 {
    margin-left: 16.66666667%;
  }
  .offset-3 {
    margin-left: 25%;
  }
  .offset-4 {
    margin-left: 33.33333333%;
  }
  .offset-5 {
    margin-left: 41.66666667%;
  }
  .offset-6 {
    margin-left: 50%;
  }
  .offset-7 {
    margin-left: 58.33333333%;
  }
  .offset-8 {
    margin-left: 66.66666667%;
  }
  .offset-9 {
    margin-left: 75%;
  }
  .offset-10 {
    margin-left: 83.33333333%;
  }
  .offset-11 {
    margin-left: 91.66666667%;
  }
  .g-0,
  .gx-0 {
    --bs-gutter-x: 0;
  }
  .g-0,
  .gy-0 {
    --bs-gutter-y: 0;
  }
  .g-1,
  .gx-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-1,
  .gy-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-2,
  .gx-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-2,
  .gy-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-3,
  .gx-3 {
    --bs-gutter-x: 1rem;
  }
  .g-3,
  .gy-3 {
    --bs-gutter-y: 1rem;
  }
  .g-4,
  .gx-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-4,
  .gy-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-5,
  .gx-5 {
    --bs-gutter-x: 3rem;
  }
  .g-5,
  .gy-5 {
    --bs-gutter-y: 3rem;
  }
  @media (min-width: 576px) {
    .col-sm {
      flex: 1 0 0%;
    }
    .row-cols-sm-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-sm-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-sm-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-sm-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-sm-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-sm-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-sm-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-sm-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-sm-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-sm-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.33333333%;
    }
    .offset-sm-2 {
      margin-left: 16.66666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.33333333%;
    }
    .offset-sm-5 {
      margin-left: 41.66666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.33333333%;
    }
    .offset-sm-8 {
      margin-left: 66.66666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.33333333%;
    }
    .offset-sm-11 {
      margin-left: 91.66666667%;
    }
    .g-sm-0,
    .gx-sm-0 {
      --bs-gutter-x: 0;
    }
    .g-sm-0,
    .gy-sm-0 {
      --bs-gutter-y: 0;
    }
    .g-sm-1,
    .gx-sm-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-sm-1,
    .gy-sm-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-sm-2,
    .gx-sm-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-sm-2,
    .gy-sm-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-sm-3,
    .gx-sm-3 {
      --bs-gutter-x: 1rem;
    }
    .g-sm-3,
    .gy-sm-3 {
      --bs-gutter-y: 1rem;
    }
    .g-sm-4,
    .gx-sm-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-sm-4,
    .gy-sm-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-sm-5,
    .gx-sm-5 {
      --bs-gutter-x: 3rem;
    }
    .g-sm-5,
    .gy-sm-5 {
      --bs-gutter-y: 3rem;
    }
  }
  @media (min-width: 768px) {
    .col-md {
      flex: 1 0 0%;
    }
    .row-cols-md-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-md-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-md-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-md-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-md-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-md-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-md-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-md-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.33333333%;
    }
    .offset-md-2 {
      margin-left: 16.66666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.33333333%;
    }
    .offset-md-5 {
      margin-left: 41.66666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.33333333%;
    }
    .offset-md-8 {
      margin-left: 66.66666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.33333333%;
    }
    .offset-md-11 {
      margin-left: 91.66666667%;
    }
    .g-md-0,
    .gx-md-0 {
      --bs-gutter-x: 0;
    }
    .g-md-0,
    .gy-md-0 {
      --bs-gutter-y: 0;
    }
    .g-md-1,
    .gx-md-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-md-1,
    .gy-md-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-md-2,
    .gx-md-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-md-2,
    .gy-md-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-md-3,
    .gx-md-3 {
      --bs-gutter-x: 1rem;
    }
    .g-md-3,
    .gy-md-3 {
      --bs-gutter-y: 1rem;
    }
    .g-md-4,
    .gx-md-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-md-4,
    .gy-md-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-md-5,
    .gx-md-5 {
      --bs-gutter-x: 3rem;
    }
    .g-md-5,
    .gy-md-5 {
      --bs-gutter-y: 3rem;
    }
  }
  @media (min-width: 992px) {
    .col-lg {
      flex: 1 0 0%;
    }
    .row-cols-lg-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-lg-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-lg-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-lg-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-lg-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-lg-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-lg-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-lg-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.33333333%;
    }
    .offset-lg-2 {
      margin-left: 16.66666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.33333333%;
    }
    .offset-lg-5 {
      margin-left: 41.66666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.33333333%;
    }
    .offset-lg-8 {
      margin-left: 66.66666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.33333333%;
    }
    .offset-lg-11 {
      margin-left: 91.66666667%;
    }
    .g-lg-0,
    .gx-lg-0 {
      --bs-gutter-x: 0;
    }
    .g-lg-0,
    .gy-lg-0 {
      --bs-gutter-y: 0;
    }
    .g-lg-1,
    .gx-lg-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-lg-1,
    .gy-lg-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-lg-2,
    .gx-lg-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-lg-2,
    .gy-lg-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-lg-3,
    .gx-lg-3 {
      --bs-gutter-x: 1rem;
    }
    .g-lg-3,
    .gy-lg-3 {
      --bs-gutter-y: 1rem;
    }
    .g-lg-4,
    .gx-lg-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-lg-4,
    .gy-lg-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-lg-5,
    .gx-lg-5 {
      --bs-gutter-x: 3rem;
    }
    .g-lg-5,
    .gy-lg-5 {
      --bs-gutter-y: 3rem;
    }
  }
  @media (min-width: 1200px) {
    .col-xl {
      flex: 1 0 0%;
    }
    .row-cols-xl-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xl-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xl-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-xl-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xl-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.33333333%;
    }
    .offset-xl-2 {
      margin-left: 16.66666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.33333333%;
    }
    .offset-xl-5 {
      margin-left: 41.66666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.33333333%;
    }
    .offset-xl-8 {
      margin-left: 66.66666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.33333333%;
    }
    .offset-xl-11 {
      margin-left: 91.66666667%;
    }
    .g-xl-0,
    .gx-xl-0 {
      --bs-gutter-x: 0;
    }
    .g-xl-0,
    .gy-xl-0 {
      --bs-gutter-y: 0;
    }
    .g-xl-1,
    .gx-xl-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-xl-1,
    .gy-xl-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-xl-2,
    .gx-xl-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-xl-2,
    .gy-xl-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-xl-3,
    .gx-xl-3 {
      --bs-gutter-x: 1rem;
    }
    .g-xl-3,
    .gy-xl-3 {
      --bs-gutter-y: 1rem;
    }
    .g-xl-4,
    .gx-xl-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-xl-4,
    .gy-xl-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-xl-5,
    .gx-xl-5 {
      --bs-gutter-x: 3rem;
    }
    .g-xl-5,
    .gy-xl-5 {
      --bs-gutter-y: 3rem;
    }
  }
  @media (min-width: 1400px) {
    .col-xxl {
      flex: 1 0 0%;
    }
    .row-cols-xxl-auto > * {
      flex: 0 0 auto;
      width: auto;
    }
    .row-cols-xxl-1 > * {
      flex: 0 0 auto;
      width: 100%;
    }
    .row-cols-xxl-2 > * {
      flex: 0 0 auto;
      width: 50%;
    }
    .row-cols-xxl-3 > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
    .row-cols-xxl-4 > * {
      flex: 0 0 auto;
      width: 25%;
    }
    .row-cols-xxl-5 > * {
      flex: 0 0 auto;
      width: 20%;
    }
    .row-cols-xxl-6 > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
    .col-xxl-auto {
      flex: 0 0 auto;
      width: auto;
    }
    .col-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    .col-xxl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    .col-xxl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    .col-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    .col-xxl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    .col-xxl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    .col-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    .col-xxl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    .col-xxl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    .col-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    .col-xxl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    .col-xxl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .offset-xxl-0 {
      margin-left: 0;
    }
    .offset-xxl-1 {
      margin-left: 8.33333333%;
    }
    .offset-xxl-2 {
      margin-left: 16.66666667%;
    }
    .offset-xxl-3 {
      margin-left: 25%;
    }
    .offset-xxl-4 {
      margin-left: 33.33333333%;
    }
    .offset-xxl-5 {
      margin-left: 41.66666667%;
    }
    .offset-xxl-6 {
      margin-left: 50%;
    }
    .offset-xxl-7 {
      margin-left: 58.33333333%;
    }
    .offset-xxl-8 {
      margin-left: 66.66666667%;
    }
    .offset-xxl-9 {
      margin-left: 75%;
    }
    .offset-xxl-10 {
      margin-left: 83.33333333%;
    }
    .offset-xxl-11 {
      margin-left: 91.66666667%;
    }
    .g-xxl-0,
    .gx-xxl-0 {
      --bs-gutter-x: 0;
    }
    .g-xxl-0,
    .gy-xxl-0 {
      --bs-gutter-y: 0;
    }
    .g-xxl-1,
    .gx-xxl-1 {
      --bs-gutter-x: 0.25rem;
    }
    .g-xxl-1,
    .gy-xxl-1 {
      --bs-gutter-y: 0.25rem;
    }
    .g-xxl-2,
    .gx-xxl-2 {
      --bs-gutter-x: 0.5rem;
    }
    .g-xxl-2,
    .gy-xxl-2 {
      --bs-gutter-y: 0.5rem;
    }
    .g-xxl-3,
    .gx-xxl-3 {
      --bs-gutter-x: 1rem;
    }
    .g-xxl-3,
    .gy-xxl-3 {
      --bs-gutter-y: 1rem;
    }
    .g-xxl-4,
    .gx-xxl-4 {
      --bs-gutter-x: 1.5rem;
    }
    .g-xxl-4,
    .gy-xxl-4 {
      --bs-gutter-y: 1.5rem;
    }
    .g-xxl-5,
    .gx-xxl-5 {
      --bs-gutter-x: 3rem;
    }
    .g-xxl-5,
    .gy-xxl-5 {
      --bs-gutter-y: 3rem;
    }
  }
  .table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color);
  }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }
  .table > tbody {
    vertical-align: inherit;
  }
  .table > thead {
    vertical-align: bottom;
  }
  .table-group-divider {
    border-top: 2px solid currentcolor;
  }
  .caption-top {
    caption-side: top;
  }
  .table-sm > :not(caption) > * > * {
    padding: 0.25rem 0.25rem;
  }
  .table-bordered > :not(caption) > * {
    border-width: 1px 0;
  }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px;
  }
  .table-borderless > :not(caption) > * > * {
    border-bottom-width: 0;
  }
  .table-borderless > :not(:first-child) {
    border-top-width: 0;
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
  }
  .table-striped-columns > :not(caption) > tr > :nth-child(even) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
  }
  .table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color);
  }
  .table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
  }
  .table-primary {
    --bs-table-color: #000;
    --bs-table-bg: #d3dee2;
    --bs-table-border-color: #bec8cb;
    --bs-table-striped-bg: #c8d3d7;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bec8cb;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #c3cdd1;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-secondary {
    --bs-table-color: #000;
    --bs-table-bg: #e2e3e5;
    --bs-table-border-color: #cbccce;
    --bs-table-striped-bg: #d7d8da;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cbccce;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #d1d2d4;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-success {
    --bs-table-color: #000;
    --bs-table-bg: #ddf0f2;
    --bs-table-border-color: #c7d8da;
    --bs-table-striped-bg: #d2e4e6;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #c7d8da;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ccdee0;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-info {
    --bs-table-color: #000;
    --bs-table-bg: #e0f4ff;
    --bs-table-border-color: #cadce6;
    --bs-table-striped-bg: #d5e8f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #cadce6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #cfe2ec;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-warning {
    --bs-table-color: #000;
    --bs-table-bg: #fff8e8;
    --bs-table-border-color: #e6dfd1;
    --bs-table-striped-bg: #f2ecdc;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6dfd1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece5d7;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-danger {
    --bs-table-color: #000;
    --bs-table-bg: #fbdfdf;
    --bs-table-border-color: #e2c9c9;
    --bs-table-striped-bg: #eed4d4;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e2c9c9;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e8cece;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-light {
    --bs-table-color: #000;
    --bs-table-bg: #f8f9fa;
    --bs-table-border-color: #dfe0e1;
    --bs-table-striped-bg: #ecedee;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #dfe0e1;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e5e6e7;
    --bs-table-hover-color: #000;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-dark {
    --bs-table-color: #ffffff;
    --bs-table-bg: #212529;
    --bs-table-border-color: #373b3e;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #ffffff;
    color: var(--bs-table-color);
    border-color: var(--bs-table-border-color);
  }
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 767.98px) {
    .table-responsive-md {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  @media (max-width: 1399.98px) {
    .table-responsive-xxl {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
  .form-label {
    margin-bottom: 0.5rem;
  }
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
  }
  .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.765625rem;
  }
  .form-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #6c757d;
  }
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: var(--h5);
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c1e9ff;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  .form-control[type="file"] {
    overflow: hidden;
  }
  .form-control[type="file"]:not(:disabled):not([readonly]) {
    cursor: pointer;
  }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #56b5bc;
    outline: 0;
    box-shadow: none;
  }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em;
  }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #6c757d;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-control::file-selector-button {
      transition: none;
    }
  }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2;
  }
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #212529;
    background-color: rgba(0, 0, 0, 0);
    border: solid rgba(0, 0, 0, 0);
    border-width: 1px 0;
  }
  .form-control-plaintext:focus {
    outline: 0;
  }
  .form-control-plaintext.form-control-sm,
  .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
  .form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
    border-radius: 0.25rem;
  }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem;
  }
  .form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    border-radius: 0.5rem;
  }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem;
  }
  textarea.form-control {
    min-height: calc(1.5em + 0.75rem + 2px);
  }
  textarea.form-control-sm {
    min-height: calc(1.5em + 0.5rem + 2px);
  }
  textarea.form-control-lg {
    min-height: calc(1.5em + 1rem + 2px);
  }
  .form-control-color {
    width: 3rem;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem;
  }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer;
  }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.375rem;
  }
  .form-control-color::-webkit-color-swatch {
    border-radius: 0.375rem;
  }
  .form-control-color.form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
  }
  .form-control-color.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
  }
  .form-select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: var(--h5);
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #c1e9ff;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none;
    }
  }
  .form-select:focus {
    border-color: #56b5bc;
    outline: 0;
    box-shadow: 0 0 0 2 rgba(36, 90, 108, 0.25);
  }
  .form-select[multiple],
  .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none;
  }
  .form-select:disabled {
    background-color: #e9ecef;
  }
  .form-select:-moz-focusring {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0 0 0 #6c757d;
  }
  .form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.765625rem;
    border-radius: 0.25rem;
  }
  .form-select-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.09375rem;
    border-radius: 0.5rem;
  }
  .form-check {
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
  }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
  }
  .form-check-reverse {
    padding-right: 1.5em;
    padding-left: 0;
    text-align: right;
  }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0;
  }
  .form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    appearance: none;
    print-color-adjust: exact;
  }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em;
  }
  .form-check-input[type="radio"] {
    border-radius: 50%;
  }
  .form-check-input:active {
    filter: brightness(90%);
  }
  .form-check-input:focus {
    border-color: #56b5bc;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(36, 90, 108, 0.25);
  }
  .form-check-input:checked {
    background-color: #245a6c;
    border-color: #245a6c;
  }
  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
  }
  .form-check-input:checked[type="radio"] {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
  }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #245a6c;
    border-color: #245a6c;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27M6 10h8%27/%3e%3c/svg%3e");
  }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
  }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5;
  }
  .form-switch {
    padding-left: 2.5em;
  }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
      transition: none;
    }
  }
  .form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%2356B5BC%27/%3e%3c/svg%3e");
  }
  .form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
  }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0;
  }
  .form-switch.form-check-reverse .form-check-input {
    margin-right: -2.5em;
    margin-left: 0;
  }
  .form-check-inline {
    display: inline-block;
    margin-right: 1rem;
  }
  .btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  .btn-check[disabled] + .btn,
  .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65;
  }
  .form-range {
    width: 100%;
    height: 5rem;
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    appearance: none;
  }
  .form-range:focus {
    outline: 0;
  }
  .form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fafdff, none;
  }
  .form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fafdff, none;
  }
  .form-range::-moz-focus-outer {
    border: 0;
  }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #245a6c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
      transition: none;
    }
  }
  .form-range::-webkit-slider-thumb:active {
    background-color: #bdced3;
  }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    background-color: #dee2e6;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #245a6c;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    appearance: none;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
      transition: none;
    }
  }
  .form-range::-moz-range-thumb:active {
    background-color: #bdced3;
  }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
    background-color: #dee2e6;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 1rem;
  }
  .form-range:disabled {
    pointer-events: none;
  }
  .form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
  }
  .form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
  }
  .form-floating {
    position: relative;
  }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
  }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid rgba(0, 0, 0, 0);
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .form-floating > label {
      transition: none;
    }
  }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.75rem;
  }
  .form-floating > .form-control::placeholder,
  .form-floating > .form-control-plaintext::placeholder {
    color: rgba(0, 0, 0, 0);
  }
  .form-floating > .form-control:focus,
  .form-floating > .form-control:not(:placeholder-shown),
  .form-floating > .form-control-plaintext:focus,
  .form-floating > .form-control-plaintext:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:-webkit-autofill,
  .form-floating > .form-control-plaintext:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
  }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0;
  }
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 3;
  }
  .input-group .btn {
    position: relative;
    z-index: 2;
  }
  .input-group .btn:focus {
    z-index: 3;
  }
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: var(--h5);
    font-weight: 400;
    line-height: 1.5;
    color: #6c757d;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #c1e9ff;
    border-radius: 0.375rem;
  }
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    border-radius: 0.5rem;
  }
  .input-group-sm > .form-control,
  .input-group-sm > .form-select,
  .input-group-sm > .input-group-text,
  .input-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.765625rem;
    border-radius: 0.25rem;
  }
  .input-group-lg > .form-select,
  .input-group-sm > .form-select {
    padding-right: 3rem;
  }
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
  .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > .form-floating:not(:last-child)
    > .form-control,
  .input-group:not(.has-validation)
    > .form-floating:not(:last-child)
    > .form-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group.has-validation
    > :nth-last-child(n
      + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
  .input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
  .input-group.has-validation
    > .form-floating:nth-last-child(n + 3)
    > .form-control,
  .input-group.has-validation
    > .form-floating:nth-last-child(n + 3)
    > .form-select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
  .input-group > .form-floating:not(:first-child) > .form-control,
  .input-group > .form-floating:not(:first-child) > .form-select {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #56b5bc;
  }
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.765625rem;
    color: #000;
    background-color: rgba(86, 181, 188, 0.9);
    border-radius: 0.375rem;
  }
  .was-validated :valid ~ .valid-feedback,
  .was-validated :valid ~ .valid-tooltip,
  .is-valid ~ .valid-feedback,
  .is-valid ~ .valid-tooltip {
    display: block;
  }
  .was-validated .form-control:valid,
  .form-control.is-valid {
    border-color: #56b5bc;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2356B5BC%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus {
    border-color: #56b5bc;
    box-shadow: 0 0 0 2 rgba(86, 181, 188, 0.25);
  }
  .was-validated textarea.form-control:valid,
  textarea.form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right
      calc(0.375em + 0.1875rem);
  }
  .was-validated .form-select:valid,
  .form-select.is-valid {
    border-color: #56b5bc;
  }
  .was-validated .form-select:valid:not([multiple]):not([size]),
  .was-validated .form-select:valid:not([multiple])[size="1"],
  .form-select.is-valid:not([multiple]):not([size]),
  .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2356B5BC%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .was-validated .form-select:valid:focus,
  .form-select.is-valid:focus {
    border-color: #56b5bc;
    box-shadow: 0 0 0 2 rgba(86, 181, 188, 0.25);
  }
  .was-validated .form-control-color:valid,
  .form-control-color.is-valid {
    width: calc(3rem + calc(1.5em + 0.75rem));
  }
  .was-validated .form-check-input:valid,
  .form-check-input.is-valid {
    border-color: #56b5bc;
  }
  .was-validated .form-check-input:valid:checked,
  .form-check-input.is-valid:checked {
    background-color: #56b5bc;
  }
  .was-validated .form-check-input:valid:focus,
  .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 2 rgba(86, 181, 188, 0.25);
  }
  .was-validated .form-check-input:valid ~ .form-check-label,
  .form-check-input.is-valid ~ .form-check-label {
    color: #56b5bc;
  }
  .form-check-inline .form-check-input ~ .valid-feedback {
    margin-left: 0.5em;
  }
  .was-validated .input-group .form-control:valid,
  .input-group .form-control.is-valid,
  .was-validated .input-group .form-select:valid,
  .input-group .form-select.is-valid {
    z-index: 1;
  }
  .was-validated .input-group .form-control:valid:focus,
  .input-group .form-control.is-valid:focus,
  .was-validated .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3;
  }
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #eb6161;
  }
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.765625rem;
    color: #000;
    background-color: rgba(235, 97, 97, 0.9);
    border-radius: 0.375rem;
  }
  .was-validated :invalid ~ .invalid-feedback,
  .was-validated :invalid ~ .invalid-tooltip,
  .is-invalid ~ .invalid-feedback,
  .is-invalid ~ .invalid-tooltip {
    display: block;
  }
  .was-validated .form-control:invalid,
  .form-control.is-invalid {
    border-color: #eb6161;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23EB6161%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23EB6161%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .was-validated .form-control:invalid:focus,
  .form-control.is-invalid:focus {
    border-color: #eb6161;
    box-shadow: 0 0 0 2 rgba(235, 97, 97, 0.25);
  }
  .was-validated textarea.form-control:invalid,
  textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right
      calc(0.375em + 0.1875rem);
  }
  .was-validated .form-select:invalid,
  .form-select.is-invalid {
    border-color: #eb6161;
  }
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size="1"],
  .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e"),
      url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23EB6161%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23EB6161%27 stroke=%27none%27/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .was-validated .form-select:invalid:focus,
  .form-select.is-invalid:focus {
    border-color: #eb6161;
    box-shadow: 0 0 0 2 rgba(235, 97, 97, 0.25);
  }
  .was-validated .form-control-color:invalid,
  .form-control-color.is-invalid {
    width: calc(3rem + calc(1.5em + 0.75rem));
  }
  .was-validated .form-check-input:invalid,
  .form-check-input.is-invalid {
    border-color: #eb6161;
  }
  .was-validated .form-check-input:invalid:checked,
  .form-check-input.is-invalid:checked {
    background-color: #eb6161;
  }
  .was-validated .form-check-input:invalid:focus,
  .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 2 rgba(235, 97, 97, 0.25);
  }
  .was-validated .form-check-input:invalid ~ .form-check-label,
  .form-check-input.is-invalid ~ .form-check-label {
    color: #eb6161;
  }
  .form-check-inline .form-check-input ~ .invalid-feedback {
    margin-left: 0.5em;
  }
  .was-validated .input-group .form-control:invalid,
  .input-group .form-control.is-invalid,
  .was-validated .input-group .form-select:invalid,
  .input-group .form-select.is-invalid {
    z-index: 2;
  }
  .was-validated .input-group .form-control:invalid:focus,
  .input-group .form-control.is-invalid:focus,
  .was-validated .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3;
  }
  .btn {
    --bs-btn-padding-x: 0.75rem;
    --bs-btn-padding-y: 0.375rem;
    --bs-btn-font-family: ;
    --bs-btn-font-size: 0.875rem;
    --bs-btn-font-weight: 400;
    --bs-btn-line-height: 1.5;
    --bs-btn-color: #212529;
    --bs-btn-bg: transparent;
    --bs-btn-border-width: 1px;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-box-shadow: none;
    --bs-btn-disabled-opacity: 0.65;
    --bs-btn-focus-box-shadow: 0 0 0 0.25rem
      rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
    display: inline-block;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
    font-family: var(--bs-btn-font-family);
    font-size: var(--bs-btn-font-size);
    font-weight: var(--bs-btn-font-weight);
    line-height: var(--bs-btn-line-height);
    color: var(--bs-btn-color);
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    border-radius: var(--bs-btn-border-radius);
    background-color: var(--bs-btn-bg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none;
    }
  }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }
  .btn-check:focus + .btn,
  .btn:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
  .btn-check:checked + .btn,
  .btn-check:active + .btn,
  .btn:active,
  .btn.active,
  .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
  }
  .btn-check:checked + .btn:focus,
  .btn-check:active + .btn:focus,
  .btn:active:focus,
  .btn.active:focus,
  .btn.show:focus {
    box-shadow: var(--bs-btn-focus-box-shadow);
  }
  .btn:disabled,
  .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
  }
  .btn-primary {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #245a6c;
    --bs-btn-border-color: #245a6c;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #1f4d5c;
    --bs-btn-hover-border-color: #1d4856;
    --bs-btn-focus-shadow-rgb: 69, 115, 130;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #1d4856;
    --bs-btn-active-border-color: #1b4451;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #245a6c;
    --bs-btn-disabled-border-color: #245a6c;
  }
  .btn-secondary {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #5c636a;
    --bs-btn-hover-border-color: #565e64;
    --bs-btn-focus-shadow-rgb: 130, 138, 145;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #565e64;
    --bs-btn-active-border-color: #51585e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #6c757d;
    --bs-btn-disabled-border-color: #6c757d;
  }
  .btn-success {
    --bs-btn-color: #000;
    --bs-btn-bg: #56b5bc;
    --bs-btn-border-color: #56b5bc;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #6fc0c6;
    --bs-btn-hover-border-color: #67bcc3;
    --bs-btn-focus-shadow-rgb: 73, 154, 160;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #78c4c9;
    --bs-btn-active-border-color: #67bcc3;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #56b5bc;
    --bs-btn-disabled-border-color: #56b5bc;
  }
  .btn-info {
    --bs-btn-color: #000;
    --bs-btn-bg: #64c7ff;
    --bs-btn-border-color: #64c7ff;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #7bcfff;
    --bs-btn-hover-border-color: #74cdff;
    --bs-btn-focus-shadow-rgb: 85, 169, 217;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #83d2ff;
    --bs-btn-active-border-color: #74cdff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #64c7ff;
    --bs-btn-disabled-border-color: #64c7ff;
  }
  .btn-warning {
    --bs-btn-color: #000;
    --bs-btn-bg: #fddb8a;
    --bs-btn-border-color: #fddb8a;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fde09c;
    --bs-btn-hover-border-color: #fddf96;
    --bs-btn-focus-shadow-rgb: 215, 186, 117;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fde2a1;
    --bs-btn-active-border-color: #fddf96;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #fddb8a;
    --bs-btn-disabled-border-color: #fddb8a;
  }
  .btn-danger {
    --bs-btn-color: #000;
    --bs-btn-bg: #eb6161;
    --bs-btn-border-color: #eb6161;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ee7979;
    --bs-btn-hover-border-color: #ed7171;
    --bs-btn-focus-shadow-rgb: 200, 82, 82;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ef8181;
    --bs-btn-active-border-color: #ed7171;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #eb6161;
    --bs-btn-disabled-border-color: #eb6161;
  }
  .btn-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d3d4d5;
    --bs-btn-hover-border-color: #c6c7c8;
    --bs-btn-focus-shadow-rgb: 211, 212, 213;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #c6c7c8;
    --bs-btn-active-border-color: #babbbc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f8f9fa;
    --bs-btn-disabled-border-color: #f8f9fa;
  }
  .btn-dark {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #424649;
    --bs-btn-hover-border-color: #373b3e;
    --bs-btn-focus-shadow-rgb: 66, 70, 73;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #4d5154;
    --bs-btn-active-border-color: #373b3e;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #212529;
    --bs-btn-disabled-border-color: #212529;
  }
  .btn-green {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #347084;
    --bs-btn-border-color: #347084;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #2c5f70;
    --bs-btn-hover-border-color: #2a5a6a;
    --bs-btn-focus-shadow-rgb: 82, 133, 150;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #2a5a6a;
    --bs-btn-active-border-color: #275463;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #347084;
    --bs-btn-disabled-border-color: #347084;
  }
  .btn-green-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #56b5bc;
    --bs-btn-border-color: #56b5bc;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #6fc0c6;
    --bs-btn-hover-border-color: #67bcc3;
    --bs-btn-focus-shadow-rgb: 73, 154, 160;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #78c4c9;
    --bs-btn-active-border-color: #67bcc3;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #56b5bc;
    --bs-btn-disabled-border-color: #56b5bc;
  }
  .btn-green-dark {
    --bs-btn-color: #ffffff;
    --bs-btn-bg: #245a6c;
    --bs-btn-border-color: #245a6c;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #1f4d5c;
    --bs-btn-hover-border-color: #1d4856;
    --bs-btn-focus-shadow-rgb: 69, 115, 130;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #1d4856;
    --bs-btn-active-border-color: #1b4451;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ffffff;
    --bs-btn-disabled-bg: #245a6c;
    --bs-btn-disabled-border-color: #245a6c;
  }
  .btn-red {
    --bs-btn-color: #000;
    --bs-btn-bg: #eb6161;
    --bs-btn-border-color: #eb6161;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ee7979;
    --bs-btn-hover-border-color: #ed7171;
    --bs-btn-focus-shadow-rgb: 200, 82, 82;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #ef8181;
    --bs-btn-active-border-color: #ed7171;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #eb6161;
    --bs-btn-disabled-border-color: #eb6161;
  }
  .btn-orange {
    --bs-btn-color: #000;
    --bs-btn-bg: #e89f7a;
    --bs-btn-border-color: #e89f7a;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #ebad8e;
    --bs-btn-hover-border-color: #eaa987;
    --bs-btn-focus-shadow-rgb: 197, 135, 104;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #edb295;
    --bs-btn-active-border-color: #eaa987;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #e89f7a;
    --bs-btn-disabled-border-color: #e89f7a;
  }
  .btn-beige {
    --bs-btn-color: #000;
    --bs-btn-bg: #e4d8ca;
    --bs-btn-border-color: #e4d8ca;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #e8ded2;
    --bs-btn-hover-border-color: #e7dccf;
    --bs-btn-focus-shadow-rgb: 194, 184, 172;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #e9e0d5;
    --bs-btn-active-border-color: #e7dccf;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #e4d8ca;
    --bs-btn-disabled-border-color: #e4d8ca;
  }
  .btn-yellow {
    --bs-btn-color: #000;
    --bs-btn-bg: #fddb8a;
    --bs-btn-border-color: #fddb8a;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fde09c;
    --bs-btn-hover-border-color: #fddf96;
    --bs-btn-focus-shadow-rgb: 215, 186, 117;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fde2a1;
    --bs-btn-active-border-color: #fddf96;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #fddb8a;
    --bs-btn-disabled-border-color: #fddb8a;
  }
  .btn-blue {
    --bs-btn-color: #000;
    --bs-btn-bg: #64c7ff;
    --bs-btn-border-color: #64c7ff;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #7bcfff;
    --bs-btn-hover-border-color: #74cdff;
    --bs-btn-focus-shadow-rgb: 85, 169, 217;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #83d2ff;
    --bs-btn-active-border-color: #74cdff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #64c7ff;
    --bs-btn-disabled-border-color: #64c7ff;
  }
  .btn-outline-primary {
    --bs-btn-color: #245a6c;
    --bs-btn-border-color: #245a6c;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #245a6c;
    --bs-btn-hover-border-color: #245a6c;
    --bs-btn-focus-shadow-rgb: 36, 90, 108;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #245a6c;
    --bs-btn-active-border-color: #245a6c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #245a6c;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #245a6c;
    --bs-gradient: none;
  }
  .btn-outline-secondary {
    --bs-btn-color: #6c757d;
    --bs-btn-border-color: #6c757d;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #6c757d;
    --bs-btn-hover-border-color: #6c757d;
    --bs-btn-focus-shadow-rgb: 108, 117, 125;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #6c757d;
    --bs-btn-active-border-color: #6c757d;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #6c757d;
    --bs-gradient: none;
  }
  .btn-outline-success {
    --bs-btn-color: #56b5bc;
    --bs-btn-border-color: #56b5bc;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #56b5bc;
    --bs-btn-hover-border-color: #56b5bc;
    --bs-btn-focus-shadow-rgb: 86, 181, 188;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #56b5bc;
    --bs-btn-active-border-color: #56b5bc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #56b5bc;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #56b5bc;
    --bs-gradient: none;
  }
  .btn-outline-info {
    --bs-btn-color: #64c7ff;
    --bs-btn-border-color: #64c7ff;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #64c7ff;
    --bs-btn-hover-border-color: #64c7ff;
    --bs-btn-focus-shadow-rgb: 100, 199, 255;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #64c7ff;
    --bs-btn-active-border-color: #64c7ff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #64c7ff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #64c7ff;
    --bs-gradient: none;
  }
  .btn-outline-warning {
    --bs-btn-color: #fddb8a;
    --bs-btn-border-color: #fddb8a;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fddb8a;
    --bs-btn-hover-border-color: #fddb8a;
    --bs-btn-focus-shadow-rgb: 253, 219, 138;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fddb8a;
    --bs-btn-active-border-color: #fddb8a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fddb8a;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fddb8a;
    --bs-gradient: none;
  }
  .btn-outline-danger {
    --bs-btn-color: #eb6161;
    --bs-btn-border-color: #eb6161;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #eb6161;
    --bs-btn-hover-border-color: #eb6161;
    --bs-btn-focus-shadow-rgb: 235, 97, 97;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #eb6161;
    --bs-btn-active-border-color: #eb6161;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #eb6161;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #eb6161;
    --bs-gradient: none;
  }
  .btn-outline-light {
    --bs-btn-color: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #f8f9fa;
    --bs-btn-hover-border-color: #f8f9fa;
    --bs-btn-focus-shadow-rgb: 248, 249, 250;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #f8f9fa;
    --bs-btn-active-border-color: #f8f9fa;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #f8f9fa;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #f8f9fa;
    --bs-gradient: none;
  }
  .btn-outline-dark {
    --bs-btn-color: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #212529;
    --bs-btn-hover-border-color: #212529;
    --bs-btn-focus-shadow-rgb: 33, 37, 41;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #212529;
    --bs-btn-active-border-color: #212529;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212529;
    --bs-gradient: none;
  }
  .btn-outline-green {
    --bs-btn-color: #347084;
    --bs-btn-border-color: #347084;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #347084;
    --bs-btn-hover-border-color: #347084;
    --bs-btn-focus-shadow-rgb: 52, 112, 132;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #347084;
    --bs-btn-active-border-color: #347084;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #347084;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #347084;
    --bs-gradient: none;
  }
  .btn-outline-green-light {
    --bs-btn-color: #56b5bc;
    --bs-btn-border-color: #56b5bc;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #56b5bc;
    --bs-btn-hover-border-color: #56b5bc;
    --bs-btn-focus-shadow-rgb: 86, 181, 188;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #56b5bc;
    --bs-btn-active-border-color: #56b5bc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #56b5bc;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #56b5bc;
    --bs-gradient: none;
  }
  .btn-outline-green-dark {
    --bs-btn-color: #245a6c;
    --bs-btn-border-color: #245a6c;
    --bs-btn-hover-color: #ffffff;
    --bs-btn-hover-bg: #245a6c;
    --bs-btn-hover-border-color: #245a6c;
    --bs-btn-focus-shadow-rgb: 36, 90, 108;
    --bs-btn-active-color: #ffffff;
    --bs-btn-active-bg: #245a6c;
    --bs-btn-active-border-color: #245a6c;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #245a6c;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #245a6c;
    --bs-gradient: none;
  }
  .btn-outline-red {
    --bs-btn-color: #eb6161;
    --bs-btn-border-color: #eb6161;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #eb6161;
    --bs-btn-hover-border-color: #eb6161;
    --bs-btn-focus-shadow-rgb: 235, 97, 97;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #eb6161;
    --bs-btn-active-border-color: #eb6161;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #eb6161;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #eb6161;
    --bs-gradient: none;
  }
  .btn-outline-orange {
    --bs-btn-color: #e89f7a;
    --bs-btn-border-color: #e89f7a;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #e89f7a;
    --bs-btn-hover-border-color: #e89f7a;
    --bs-btn-focus-shadow-rgb: 232, 159, 122;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #e89f7a;
    --bs-btn-active-border-color: #e89f7a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #e89f7a;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #e89f7a;
    --bs-gradient: none;
  }
  .btn-outline-beige {
    --bs-btn-color: #e4d8ca;
    --bs-btn-border-color: #e4d8ca;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #e4d8ca;
    --bs-btn-hover-border-color: #e4d8ca;
    --bs-btn-focus-shadow-rgb: 228, 216, 202;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #e4d8ca;
    --bs-btn-active-border-color: #e4d8ca;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #e4d8ca;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #e4d8ca;
    --bs-gradient: none;
  }
  .btn-outline-yellow {
    --bs-btn-color: #fddb8a;
    --bs-btn-border-color: #fddb8a;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fddb8a;
    --bs-btn-hover-border-color: #fddb8a;
    --bs-btn-focus-shadow-rgb: 253, 219, 138;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fddb8a;
    --bs-btn-active-border-color: #fddb8a;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fddb8a;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fddb8a;
    --bs-gradient: none;
  }
  .btn-outline-blue {
    --bs-btn-color: #64c7ff;
    --bs-btn-border-color: #64c7ff;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #64c7ff;
    --bs-btn-hover-border-color: #64c7ff;
    --bs-btn-focus-shadow-rgb: 100, 199, 255;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #64c7ff;
    --bs-btn-active-border-color: #64c7ff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #64c7ff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #64c7ff;
    --bs-gradient: none;
  }
  .btn-link {
    --bs-btn-font-weight: 400;
    --bs-btn-color: var(--bs-link-color);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-hover-color: var(--bs-link-hover-color);
    --bs-btn-hover-border-color: transparent;
    --bs-btn-active-color: var(--bs-link-hover-color);
    --bs-btn-active-border-color: transparent;
    --bs-btn-disabled-color: #6c757d;
    --bs-btn-disabled-border-color: transparent;
    --bs-btn-box-shadow: none;
    --bs-btn-focus-shadow-rgb: 69, 115, 130;
    text-decoration: none;
  }
  .btn-link:focus {
    color: var(--bs-btn-color);
  }
  .btn-link:hover {
    color: var(--bs-btn-hover-color);
  }
  .btn-lg {
    --bs-btn-padding-y: 0.5rem;
    --bs-btn-padding-x: 1rem;
    --bs-btn-font-size: 1.09375rem;
    --bs-btn-border-radius: 0.5rem;
  }
  .btn-sm {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 0.765625rem;
    --bs-btn-border-radius: 0.25rem;
  }
  .fade {
    transition: opacity 0.15s linear;
  }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  .fade:not(.show) {
    opacity: 0;
  }
  .collapse:not(.show) {
    display: none;
  }
  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease;
  }
  @media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
      transition: none;
    }
  }
  .dropup,
  .dropend,
  .dropdown,
  .dropstart,
  .dropup-center,
  .dropdown-center {
    position: relative;
  }
  .dropdown-toggle {
    white-space: nowrap;
  }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid rgba(0, 0, 0, 0);
    border-bottom: 0;
    border-left: 0.3em solid rgba(0, 0, 0, 0);
  }
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropdown-menu {
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0.5rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 0.875rem;
    --bs-dropdown-color: #212529;
    --bs-dropdown-bg: #ffffff;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: 0.375rem;
    --bs-dropdown-border-width: 1px;
    --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0.5rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-dropdown-link-color: #212529;
    --bs-dropdown-link-hover-color: #1e2125;
    --bs-dropdown-link-hover-bg: #e9ecef;
    --bs-dropdown-link-active-color: #ffffff;
    --bs-dropdown-link-active-bg: #245a6c;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-item-padding-x: 1rem;
    --bs-dropdown-item-padding-y: 0.25rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 0.5rem;
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
  }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer);
  }
  .dropdown-menu-start {
    --bs-position: start;
  }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-end {
    --bs-position: end;
  }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
  @media (min-width: 576px) {
    .dropdown-menu-sm-start {
      --bs-position: start;
    }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-sm-end {
      --bs-position: end;
    }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 768px) {
    .dropdown-menu-md-start {
      --bs-position: start;
    }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-md-end {
      --bs-position: end;
    }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 992px) {
    .dropdown-menu-lg-start {
      --bs-position: start;
    }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-lg-end {
      --bs-position: end;
    }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1200px) {
    .dropdown-menu-xl-start {
      --bs-position: start;
    }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xl-end {
      --bs-position: end;
    }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  @media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
      --bs-position: start;
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0;
    }
    .dropdown-menu-xxl-end {
      --bs-position: end;
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto;
    }
  }
  .dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--bs-dropdown-spacer);
  }
  .dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid rgba(0, 0, 0, 0);
    border-bottom: 0.3em solid;
    border-left: 0.3em solid rgba(0, 0, 0, 0);
  }
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--bs-dropdown-spacer);
  }
  .dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid rgba(0, 0, 0, 0);
    border-right: 0;
    border-bottom: 0.3em solid rgba(0, 0, 0, 0);
    border-left: 0.3em solid;
  }
  .dropend .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropend .dropdown-toggle::after {
    vertical-align: 0;
  }
  .dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--bs-dropdown-spacer);
  }
  .dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  .dropstart .dropdown-toggle::after {
    display: none;
  }
  .dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid rgba(0, 0, 0, 0);
    border-right: 0.3em solid;
    border-bottom: 0.3em solid rgba(0, 0, 0, 0);
  }
  .dropstart .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  .dropstart .dropdown-toggle::before {
    vertical-align: 0;
  }
  .dropdown-divider {
    height: 0;
    margin: var(--bs-dropdown-divider-margin-y) 0;
    overflow: hidden;
    border-top: 1px solid var(--bs-dropdown-divider-bg);
    opacity: 1;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    clear: both;
    font-weight: 400;
    color: var(--bs-dropdown-link-color);
    text-align: inherit;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg);
  }
  .dropdown-item.disabled,
  .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0);
  }
  .dropdown-menu.show {
    display: block;
  }
  .dropdown-header {
    display: block;
    padding: var(--bs-dropdown-header-padding-y)
      var(--bs-dropdown-header-padding-x);
    margin-bottom: 0;
    font-size: 0.765625rem;
    color: var(--bs-dropdown-header-color);
    white-space: nowrap;
  }
  .dropdown-item-text {
    display: block;
    padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
    color: var(--bs-dropdown-link-color);
  }
  .dropdown-menu-dark {
    --bs-dropdown-color: #dee2e6;
    --bs-dropdown-bg: #343a40;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-box-shadow: ;
    --bs-dropdown-link-color: #dee2e6;
    --bs-dropdown-link-hover-color: #ffffff;
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
    --bs-dropdown-link-active-color: #ffffff;
    --bs-dropdown-link-active-bg: #245a6c;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-header-color: #adb5bd;
  }
  .breadcrumb {
    --bs-breadcrumb-padding-x: 0;
    --bs-breadcrumb-padding-y: 0;
    --bs-breadcrumb-margin-bottom: 1rem;
    --bs-breadcrumb-bg: ;
    --bs-breadcrumb-border-radius: ;
    --bs-breadcrumb-divider-color: #6c757d;
    --bs-breadcrumb-item-padding-x: 0.5rem;
    --bs-breadcrumb-item-active-color: #6c757d;
    display: flex;
    flex-wrap: wrap;
    padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
    margin-bottom: var(--bs-breadcrumb-margin-bottom);
    font-size: var(--bs-breadcrumb-font-size);
    list-style: none;
    background-color: var(--bs-breadcrumb-bg);
    border-radius: var(--bs-breadcrumb-border-radius);
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: var(--bs-breadcrumb-item-padding-x);
  }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(
      --bs-breadcrumb-divider,
      "/"
    ); /* rtl: var(--bs-breadcrumb-divider, "/") */
  }
  .breadcrumb-item.active {
    color: var(--bs-breadcrumb-item-active-color);
  }
  .pagination {
    --bs-pagination-padding-x: 0.75rem;
    --bs-pagination-padding-y: 0.375rem;
    --bs-pagination-font-size: 0.875rem;
    --bs-pagination-color: var(--bs-link-color);
    --bs-pagination-bg: #ffffff;
    --bs-pagination-border-width: 1px;
    --bs-pagination-border-color: #dee2e6;
    --bs-pagination-border-radius: 0.375rem;
    --bs-pagination-hover-color: var(--bs-link-hover-color);
    --bs-pagination-hover-bg: #e9ecef;
    --bs-pagination-hover-border-color: #dee2e6;
    --bs-pagination-focus-color: var(--bs-link-hover-color);
    --bs-pagination-focus-bg: #e9ecef;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(36, 90, 108, 0.25);
    --bs-pagination-active-color: #ffffff;
    --bs-pagination-active-bg: #245a6c;
    --bs-pagination-active-border-color: #245a6c;
    --bs-pagination-disabled-color: #6c757d;
    --bs-pagination-disabled-bg: #ffffff;
    --bs-pagination-disabled-border-color: #dee2e6;
    display: flex;
    padding-left: 0;
    list-style: none;
  }
  .page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: var(--bs-pagination-font-size);
    color: var(--bs-pagination-color);
    background-color: var(--bs-pagination-bg);
    border: var(--bs-pagination-border-width) solid
      var(--bs-pagination-border-color);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none;
    }
  }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color);
  }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow);
  }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color);
  }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color);
  }
  .page-item:not(:first-child) .page-link {
    margin-left: -1px;
  }
  .page-item:first-child .page-link {
    border-top-left-radius: var(--bs-pagination-border-radius);
    border-bottom-left-radius: var(--bs-pagination-border-radius);
  }
  .page-item:last-child .page-link {
    border-top-right-radius: var(--bs-pagination-border-radius);
    border-bottom-right-radius: var(--bs-pagination-border-radius);
  }
  .pagination-lg {
    --bs-pagination-padding-x: 1.5rem;
    --bs-pagination-padding-y: 0.75rem;
    --bs-pagination-font-size: 1.09375rem;
    --bs-pagination-border-radius: 0.5rem;
  }
  .pagination-sm {
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.25rem;
    --bs-pagination-font-size: 0.765625rem;
    --bs-pagination-border-radius: 0.25rem;
  }
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  .text-bg-primary {
    color: #fff !important;
    background-color: RGBA(36, 90, 108, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-secondary {
    color: #fff !important;
    background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-success {
    color: #000 !important;
    background-color: RGBA(86, 181, 188, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-info {
    color: #000 !important;
    background-color: RGBA(100, 199, 255, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-warning {
    color: #000 !important;
    background-color: RGBA(253, 219, 138, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-danger {
    color: #000 !important;
    background-color: RGBA(235, 97, 97, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-light {
    color: #000 !important;
    background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-dark {
    color: #fff !important;
    background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-green {
    color: #fff !important;
    background-color: RGBA(52, 112, 132, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-green-light {
    color: #000 !important;
    background-color: RGBA(86, 181, 188, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-green-dark {
    color: #fff !important;
    background-color: RGBA(36, 90, 108, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-red {
    color: #000 !important;
    background-color: RGBA(235, 97, 97, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-orange {
    color: #000 !important;
    background-color: RGBA(232, 159, 122, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-beige {
    color: #000 !important;
    background-color: RGBA(228, 216, 202, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-yellow {
    color: #000 !important;
    background-color: RGBA(253, 219, 138, var(--bs-bg-opacity, 1)) !important;
  }
  .text-bg-blue {
    color: #000 !important;
    background-color: RGBA(100, 199, 255, var(--bs-bg-opacity, 1)) !important;
  }
  .link-primary {
    color: #245a6c !important;
  }
  .link-primary:hover,
  .link-primary:focus {
    color: #1d4856 !important;
  }
  .link-secondary {
    color: #6c757d !important;
  }
  .link-secondary:hover,
  .link-secondary:focus {
    color: #565e64 !important;
  }
  .link-success {
    color: #56b5bc !important;
  }
  .link-success:hover,
  .link-success:focus {
    color: #78c4c9 !important;
  }
  .link-info {
    color: #64c7ff !important;
  }
  .link-info:hover,
  .link-info:focus {
    color: #83d2ff !important;
  }
  .link-warning {
    color: #fddb8a !important;
  }
  .link-warning:hover,
  .link-warning:focus {
    color: #fde2a1 !important;
  }
  .link-danger {
    color: #eb6161 !important;
  }
  .link-danger:hover,
  .link-danger:focus {
    color: #ef8181 !important;
  }
  .link-light {
    color: #f8f9fa !important;
  }
  .link-light:hover,
  .link-light:focus {
    color: #f9fafb !important;
  }
  .link-dark {
    color: #212529 !important;
  }
  .link-dark:hover,
  .link-dark:focus {
    color: #1a1e21 !important;
  }
  .link-green {
    color: #347084 !important;
  }
  .link-green:hover,
  .link-green:focus {
    color: #2a5a6a !important;
  }
  .link-green-light {
    color: #56b5bc !important;
  }
  .link-green-light:hover,
  .link-green-light:focus {
    color: #78c4c9 !important;
  }
  .link-green-dark {
    color: #245a6c !important;
  }
  .link-green-dark:hover,
  .link-green-dark:focus {
    color: #1d4856 !important;
  }
  .link-red {
    color: #eb6161 !important;
  }
  .link-red:hover,
  .link-red:focus {
    color: #ef8181 !important;
  }
  .link-orange {
    color: #e89f7a !important;
  }
  .link-orange:hover,
  .link-orange:focus {
    color: #edb295 !important;
  }
  .link-beige {
    color: #e4d8ca !important;
  }
  .link-beige:hover,
  .link-beige:focus {
    color: #e9e0d5 !important;
  }
  .link-yellow {
    color: #fddb8a !important;
  }
  .link-yellow:hover,
  .link-yellow:focus {
    color: #fde2a1 !important;
  }
  .link-blue {
    color: #64c7ff !important;
  }
  .link-blue:hover,
  .link-blue:focus {
    color: #83d2ff !important;
  }
  .ratio {
    position: relative;
    width: 100%;
  }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: "";
  }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .ratio-1x1 {
    --bs-aspect-ratio: 100%;
  }
  .ratio-4x3 {
    --bs-aspect-ratio: 75%;
  }
  .ratio-16x9 {
    --bs-aspect-ratio: 56.25%;
  }
  .ratio-21x9 {
    --bs-aspect-ratio: 42.8571428571%;
  }
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
  @media (min-width: 576px) {
    .sticky-sm-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
    .sticky-sm-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 768px) {
    .sticky-md-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
    .sticky-md-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 992px) {
    .sticky-lg-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
    .sticky-lg-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 1200px) {
    .sticky-xl-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
    .sticky-xl-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }
  @media (min-width: 1400px) {
    .sticky-xxl-top {
      position: sticky;
      top: 0;
      z-index: 1020;
    }
    .sticky-xxl-bottom {
      position: sticky;
      bottom: 0;
      z-index: 1020;
    }
  }
  .hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
  }
  .vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch;
  }
  .visually-hidden,
  .visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
  .stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: "";
  }
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentcolor;
    opacity: 0.25;
  }
  .align-baseline {
    vertical-align: baseline !important;
  }
  .align-top {
    vertical-align: top !important;
  }
  .align-middle {
    vertical-align: middle !important;
  }
  .align-bottom {
    vertical-align: bottom !important;
  }
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .align-text-top {
    vertical-align: text-top !important;
  }
  .float-start {
    float: left !important;
  }
  .float-end {
    float: right !important;
  }
  .float-none {
    float: none !important;
  }
  .opacity-0 {
    opacity: 0 !important;
  }
  .opacity-25 {
    opacity: 0.25 !important;
  }
  .opacity-50 {
    opacity: 0.5 !important;
  }
  .opacity-75 {
    opacity: 0.75 !important;
  }
  .opacity-100 {
    opacity: 1 !important;
  }
  .overflow-auto {
    overflow: auto !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .overflow-visible {
    overflow: visible !important;
  }
  .overflow-scroll {
    overflow: scroll !important;
  }
  .d-inline {
    display: inline !important;
  }
  .d-inline-block {
    display: inline-block !important;
  }
  .d-block {
    display: block !important;
  }
  .d-grid {
    display: grid !important;
  }
  .d-table {
    display: table !important;
  }
  .d-table-row {
    display: table-row !important;
  }
  .d-table-cell {
    display: table-cell !important;
  }
  .d-flex {
    display: flex !important;
  }
  .d-inline-flex {
    display: inline-flex !important;
  }
  .d-none {
    display: none !important;
  }
  .shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }
  .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .shadow-none {
    box-shadow: none !important;
  }
  .position-static {
    position: static !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .position-fixed {
    position: fixed !important;
  }
  .position-sticky {
    position: sticky !important;
  }
  .top-0 {
    top: 0 !important;
  }
  .top-50 {
    top: 50% !important;
  }
  .top-100 {
    top: 100% !important;
  }
  .bottom-0 {
    bottom: 0 !important;
  }
  .bottom-50 {
    bottom: 50% !important;
  }
  .bottom-100 {
    bottom: 100% !important;
  }
  .start-0 {
    left: 0 !important;
  }
  .start-50 {
    left: 50% !important;
  }
  .start-100 {
    left: 100% !important;
  }
  .end-0 {
    right: 0 !important;
  }
  .end-50 {
    right: 50% !important;
  }
  .end-100 {
    right: 100% !important;
  }
  .translate-middle {
    transform: translate(-50%, -50%) !important;
  }
  .translate-middle-x {
    transform: translateX(-50%) !important;
  }
  .translate-middle-y {
    transform: translateY(-50%) !important;
  }
  .border {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
  .border-0 {
    border: 0 !important;
  }
  .border-top {
    border-top: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-top-0 {
    border-top: 0 !important;
  }
  .border-end {
    border-right: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-end-0 {
    border-right: 0 !important;
  }
  .border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  .border-start {
    border-left: var(--bs-border-width) var(--bs-border-style)
      var(--bs-border-color) !important;
  }
  .border-start-0 {
    border-left: 0 !important;
  }
  .border-primary {
    --bs-border-opacity: 1;
    border-color: rgba(
      var(--bs-primary-rgb),
      var(--bs-border-opacity)
    ) !important;
  }
  .border-secondary {
    --bs-border-opacity: 1;
    border-color: rgba(
      var(--bs-secondary-rgb),
      var(--bs-border-opacity)
    ) !important;
  }
  .border-success {
    --bs-border-opacity: 1;
    border-color: rgba(
      var(--bs-success-rgb),
      var(--bs-border-opacity)
    ) !important;
  }
  .border-info {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
  }
  .border-warning {
    --bs-border-opacity: 1;
    border-color: rgba(
      var(--bs-warning-rgb),
      var(--bs-border-opacity)
    ) !important;
  }
  .border-danger {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
  }
  .border-light {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
  }
  .border-dark {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
  }
  .border-white {
    --bs-border-opacity: 1;
    border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
  }
  .border-1 {
    --bs-border-width: 1px;
  }
  .border-2 {
    --bs-border-width: 2px;
  }
  .border-3 {
    --bs-border-width: 3px;
  }
  .border-4 {
    --bs-border-width: 4px;
  }
  .border-5 {
    --bs-border-width: 5px;
  }
  .border-opacity-10 {
    --bs-border-opacity: 0.1;
  }
  .border-opacity-25 {
    --bs-border-opacity: 0.25;
  }
  .border-opacity-50 {
    --bs-border-opacity: 0.5;
  }
  .border-opacity-75 {
    --bs-border-opacity: 0.75;
  }
  .border-opacity-100 {
    --bs-border-opacity: 1;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-75 {
    width: 75% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-auto {
    width: auto !important;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .vw-100 {
    width: 100vw !important;
  }
  .min-vw-100 {
    min-width: 100vw !important;
  }
  .h-25 {
    height: 25% !important;
  }
  .h-50 {
    height: 50% !important;
  }
  .h-75 {
    height: 75% !important;
  }
  .h-100 {
    height: 100% !important;
  }
  .h-auto {
    height: auto !important;
  }
  .mh-100 {
    max-height: 100% !important;
  }
  .vh-100 {
    height: 100vh !important;
  }
  .min-vh-100 {
    min-height: 100vh !important;
  }
  .flex-fill {
    flex: 1 1 auto !important;
  }
  .flex-row {
    flex-direction: row !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-start {
    justify-content: flex-start !important;
  }
  .justify-content-end {
    justify-content: flex-end !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .justify-content-around {
    justify-content: space-around !important;
  }
  .justify-content-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-start {
    align-items: flex-start !important;
  }
  .align-items-end {
    align-items: flex-end !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .align-items-baseline {
    align-items: baseline !important;
  }
  .align-items-stretch {
    align-items: stretch !important;
  }
  .align-content-start {
    align-content: flex-start !important;
  }
  .align-content-end {
    align-content: flex-end !important;
  }
  .align-content-center {
    align-content: center !important;
  }
  .align-content-between {
    align-content: space-between !important;
  }
  .align-content-around {
    align-content: space-around !important;
  }
  .align-content-stretch {
    align-content: stretch !important;
  }
  .align-self-auto {
    align-self: auto !important;
  }
  .align-self-start {
    align-self: flex-start !important;
  }
  .align-self-end {
    align-self: flex-end !important;
  }
  .align-self-center {
    align-self: center !important;
  }
  .align-self-baseline {
    align-self: baseline !important;
  }
  .align-self-stretch {
    align-self: stretch !important;
  }
  .order-first {
    order: -1 !important;
  }
  .order-0 {
    order: 0 !important;
  }
  .order-1 {
    order: 1 !important;
  }
  .order-2 {
    order: 2 !important;
  }
  .order-3 {
    order: 3 !important;
  }
  .order-4 {
    order: 4 !important;
  }
  .order-5 {
    order: 5 !important;
  }
  .order-last {
    order: 6 !important;
  }
  .m-0 {
    margin: 0 !important;
  }
  .m-1 {
    margin: 0.25rem !important;
  }
  .m-2 {
    margin: 0.5rem !important;
  }
  .m-3 {
    margin: 1rem !important;
  }
  .m-4 {
    margin: 1.5rem !important;
  }
  .m-5 {
    margin: 3rem !important;
  }
  .m-auto {
    margin: auto !important;
  }
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .mt-1 {
    margin-top: 0.25rem !important;
  }
  .mt-2 {
    margin-top: 0.5rem !important;
  }
  .mt-3 {
    margin-top: 1rem !important;
  }
  .mt-4 {
    margin-top: 1.5rem !important;
  }
  .mt-5 {
    margin-top: 3rem !important;
  }
  .mt-auto {
    margin-top: auto !important;
  }
  .me-0 {
    margin-right: 0 !important;
  }
  .me-1 {
    margin-right: 0.25rem !important;
  }
  .me-2 {
    margin-right: 0.5rem !important;
  }
  .me-3 {
    margin-right: 1rem !important;
  }
  .me-4 {
    margin-right: 1.5rem !important;
  }
  .me-5 {
    margin-right: 3rem !important;
  }
  .me-auto {
    margin-right: auto !important;
  }
  .mb-0 {
    margin-bottom: 0 !important;
  }
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-5 {
    margin-bottom: 3rem !important;
  }
  .mb-auto {
    margin-bottom: auto !important;
  }
  .ms-0 {
    margin-left: 0 !important;
  }
  .ms-1 {
    margin-left: 0.25rem !important;
  }
  .ms-2 {
    margin-left: 0.5rem !important;
  }
  .ms-3 {
    margin-left: 1rem !important;
  }
  .ms-4 {
    margin-left: 1.5rem !important;
  }
  .ms-5 {
    margin-left: 3rem !important;
  }
  .ms-auto {
    margin-left: auto !important;
  }
  .p-0 {
    padding: 0 !important;
  }
  .p-1 {
    padding: 0.25rem !important;
  }
  .p-2 {
    padding: 0.5rem !important;
  }
  .p-3 {
    padding: 1rem !important;
  }
  .p-4 {
    padding: 1.5rem !important;
  }
  .p-5 {
    padding: 3rem !important;
  }
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .pt-1 {
    padding-top: 0.25rem !important;
  }
  .pt-2 {
    padding-top: 0.5rem !important;
  }
  .pt-3 {
    padding-top: 1rem !important;
  }
  .pt-4 {
    padding-top: 1.5rem !important;
  }
  .pt-5 {
    padding-top: 3rem !important;
  }
  .pe-0 {
    padding-right: 0 !important;
  }
  .pe-1 {
    padding-right: 0.25rem !important;
  }
  .pe-2 {
    padding-right: 0.5rem !important;
  }
  .pe-3 {
    padding-right: 1rem !important;
  }
  .pe-4 {
    padding-right: 1.5rem !important;
  }
  .pe-5 {
    padding-right: 3rem !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-3 {
    padding-bottom: 1rem !important;
  }
  .pb-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-5 {
    padding-bottom: 3rem !important;
  }
  .ps-0 {
    padding-left: 0 !important;
  }
  .ps-1 {
    padding-left: 0.25rem !important;
  }
  .ps-2 {
    padding-left: 0.5rem !important;
  }
  .ps-3 {
    padding-left: 1rem !important;
  }
  .ps-4 {
    padding-left: 1.5rem !important;
  }
  .ps-5 {
    padding-left: 3rem !important;
  }
  .gap-0 {
    gap: 0 !important;
  }
  .gap-1 {
    gap: 0.25rem !important;
  }
  .gap-2 {
    gap: 0.5rem !important;
  }
  .gap-3 {
    gap: 1rem !important;
  }
  .gap-4 {
    gap: 1.5rem !important;
  }
  .gap-5 {
    gap: 3rem !important;
  }
  .font-monospace {
    font-family: var(--bs-font-monospace) !important;
  }
  .fs-1 {
    font-size: calc(1.34375rem + 1.125vw) !important;
  }
  .fs-2 {
    font-size: calc(1.3rem + 0.6vw) !important;
  }
  .fs-3 {
    font-size: calc(1.278125rem + 0.3375vw) !important;
  }
  .fs-4 {
    font-size: calc(1.25625rem + 0.075vw) !important;
  }
  .fs-5 {
    font-size: 1.09375rem !important;
  }
  .fs-6 {
    font-size: 0.875rem !important;
  }
  .fst-italic {
    font-style: italic !important;
  }
  .fst-normal {
    font-style: normal !important;
  }
  .fw-light {
    font-weight: 300 !important;
  }
  .fw-lighter {
    font-weight: lighter !important;
  }
  .fw-normal {
    font-weight: 400 !important;
  }
  .fw-bold {
    font-weight: 700 !important;
  }
  .fw-semibold {
    font-weight: 600 !important;
  }
  .fw-bolder {
    font-weight: bolder !important;
  }
  .lh-1 {
    line-height: 1 !important;
  }
  .lh-sm {
    line-height: 1.25 !important;
  }
  .lh-base {
    line-height: 1.5 !important;
  }
  .lh-lg {
    line-height: 2 !important;
  }
  .text-start {
    text-align: left !important;
  }
  .text-end {
    text-align: right !important;
  }
  .text-center {
    text-align: center !important;
  }
  .text-decoration-none {
    text-decoration: none !important;
  }
  .text-decoration-underline {
    text-decoration: underline !important;
  }
  .text-decoration-line-through {
    text-decoration: line-through !important;
  }
  .text-lowercase {
    text-transform: lowercase !important;
  }
  .text-uppercase {
    text-transform: uppercase !important;
  }
  .text-capitalize {
    text-transform: capitalize !important;
  }
  .text-wrap {
    white-space: normal !important;
  }
  .text-nowrap {
    white-space: nowrap !important;
  }
  .text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
  }
  .text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
  }
  .text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
  }
  .text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
  }
  .text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
  }
  .text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  }
  .text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
  }
  .text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
  }
  .text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
  }
  .text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
  }
  .text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
  }
  .text-muted {
    --bs-text-opacity: 1;
    color: #6c757d !important;
  }
  .text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, 0.5) !important;
  }
  .text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .text-reset {
    --bs-text-opacity: 1;
    color: inherit !important;
  }
  .text-green {
    --bs-text-opacity: 1;
    color: #347084 !important;
  }
  .text-green-light {
    --bs-text-opacity: 1;
    color: #56b5bc !important;
  }
  .text-green-dark {
    --bs-text-opacity: 1;
    color: #245a6c !important;
  }
  .text-red {
    --bs-text-opacity: 1;
    color: #eb6161 !important;
  }
  .text-orange {
    --bs-text-opacity: 1;
    color: #e89f7a !important;
  }
  .text-beige {
    --bs-text-opacity: 1;
    color: #e4d8ca !important;
  }
  .text-yellow {
    --bs-text-opacity: 1;
    color: #fddb8a !important;
  }
  .text-blue {
    --bs-text-opacity: 1;
    color: #64c7ff !important;
  }
  .text-blue-5 {
    --bs-text-opacity: 1;
    color: #fcfeff !important;
  }
  .text-blue-50 {
    --bs-text-opacity: 1;
    color: #f7fcff !important;
  }
  .text-blue-100 {
    --bs-text-opacity: 1;
    color: #e0f4ff !important;
  }
  .text-blue-200 {
    --bs-text-opacity: 1;
    color: #c1e9ff !important;
  }
  .text-blue-300 {
    --bs-text-opacity: 1;
    color: #a2ddff !important;
  }
  .text-blue-400 {
    --bs-text-opacity: 1;
    color: #83d2ff !important;
  }
  .text-blue-500 {
    --bs-text-opacity: 1;
    color: #64c7ff !important;
  }
  .text-blue-600 {
    --bs-text-opacity: 1;
    color: #509fcc !important;
  }
  .text-blue-700 {
    --bs-text-opacity: 1;
    color: #3c7799 !important;
  }
  .text-blue-800 {
    --bs-text-opacity: 1;
    color: #285066 !important;
  }
  .text-blue-900 {
    --bs-text-opacity: 1;
    color: #142833 !important;
  }
  .text-red-5 {
    --bs-text-opacity: 1;
    color: #fffcfc !important;
  }
  .text-red-50 {
    --bs-text-opacity: 1;
    color: #fef7f7 !important;
  }
  .text-red-100 {
    --bs-text-opacity: 1;
    color: #fbdfdf !important;
  }
  .text-red-200 {
    --bs-text-opacity: 1;
    color: #f7c0c0 !important;
  }
  .text-red-300 {
    --bs-text-opacity: 1;
    color: #f3a0a0 !important;
  }
  .text-red-400 {
    --bs-text-opacity: 1;
    color: #ef8181 !important;
  }
  .text-red-500 {
    --bs-text-opacity: 1;
    color: #eb6161 !important;
  }
  .text-red-600 {
    --bs-text-opacity: 1;
    color: #bc4e4e !important;
  }
  .text-red-700 {
    --bs-text-opacity: 1;
    color: #8d3a3a !important;
  }
  .text-red-800 {
    --bs-text-opacity: 1;
    color: #5e2727 !important;
  }
  .text-red-900 {
    --bs-text-opacity: 1;
    color: #2f1313 !important;
  }
  .text-orange-5 {
    --bs-text-opacity: 1;
    color: #fffdfc !important;
  }
  .text-orange-50 {
    --bs-text-opacity: 1;
    color: #fefaf8 !important;
  }
  .text-orange-100 {
    --bs-text-opacity: 1;
    color: #faece4 !important;
  }
  .text-orange-200 {
    --bs-text-opacity: 1;
    color: #f6d9ca !important;
  }
  .text-orange-300 {
    --bs-text-opacity: 1;
    color: #f1c5af !important;
  }
  .text-orange-400 {
    --bs-text-opacity: 1;
    color: #edb295 !important;
  }
  .text-orange-500 {
    --bs-text-opacity: 1;
    color: #e89f7a !important;
  }
  .text-orange-600 {
    --bs-text-opacity: 1;
    color: #ba7f62 !important;
  }
  .text-orange-700 {
    --bs-text-opacity: 1;
    color: #8b5f49 !important;
  }
  .text-orange-800 {
    --bs-text-opacity: 1;
    color: #5d4031 !important;
  }
  .text-orange-900 {
    --bs-text-opacity: 1;
    color: #2e2018 !important;
  }
  .text-yellow-5 {
    --bs-text-opacity: 1;
    color: #fffefd !important;
  }
  .text-yellow-50 {
    --bs-text-opacity: 1;
    color: #fffdf9 !important;
  }
  .text-yellow-100 {
    --bs-text-opacity: 1;
    color: #fff8e8 !important;
  }
  .text-yellow-200 {
    --bs-text-opacity: 1;
    color: #fef1d0 !important;
  }
  .text-yellow-300 {
    --bs-text-opacity: 1;
    color: #fee9b9 !important;
  }
  .text-yellow-400 {
    --bs-text-opacity: 1;
    color: #fde2a1 !important;
  }
  .text-yellow-500 {
    --bs-text-opacity: 1;
    color: #fddb8a !important;
  }
  .text-yellow-600 {
    --bs-text-opacity: 1;
    color: #caaf6e !important;
  }
  .text-yellow-700 {
    --bs-text-opacity: 1;
    color: #988353 !important;
  }
  .text-yellow-800 {
    --bs-text-opacity: 1;
    color: #655837 !important;
  }
  .text-yellow-900 {
    --bs-text-opacity: 1;
    color: #332c1c !important;
  }
  .text-green-5 {
    --bs-text-opacity: 1;
    color: #fbfcfd !important;
  }
  .text-green-50 {
    --bs-text-opacity: 1;
    color: #f5f8f9 !important;
  }
  .text-green-100 {
    --bs-text-opacity: 1;
    color: #d6e2e6 !important;
  }
  .text-green-200 {
    --bs-text-opacity: 1;
    color: #aec6ce !important;
  }
  .text-green-300 {
    --bs-text-opacity: 1;
    color: #85a9b5 !important;
  }
  .text-green-400 {
    --bs-text-opacity: 1;
    color: #5d8d9d !important;
  }
  .text-green-500 {
    --bs-text-opacity: 1;
    color: #347084 !important;
  }
  .text-green-600 {
    --bs-text-opacity: 1;
    color: #2a5a6a !important;
  }
  .text-green-700 {
    --bs-text-opacity: 1;
    color: #1f434f !important;
  }
  .text-green-800 {
    --bs-text-opacity: 1;
    color: #152d35 !important;
  }
  .text-green-900 {
    --bs-text-opacity: 1;
    color: #0a161a !important;
  }
  .text-green-light-5 {
    --bs-text-opacity: 1;
    color: #fcfefe !important;
  }
  .text-green-light-50 {
    --bs-text-opacity: 1;
    color: #f7fbfc !important;
  }
  .text-green-light-100 {
    --bs-text-opacity: 1;
    color: #ddf0f2 !important;
  }
  .text-green-light-200 {
    --bs-text-opacity: 1;
    color: #bbe1e4 !important;
  }
  .text-green-light-300 {
    --bs-text-opacity: 1;
    color: #9ad3d7 !important;
  }
  .text-green-light-400 {
    --bs-text-opacity: 1;
    color: #78c4c9 !important;
  }
  .text-green-light-500 {
    --bs-text-opacity: 1;
    color: #56b5bc !important;
  }
  .text-green-light-600 {
    --bs-text-opacity: 1;
    color: #459196 !important;
  }
  .text-green-light-700 {
    --bs-text-opacity: 1;
    color: #346d71 !important;
  }
  .text-green-light-800 {
    --bs-text-opacity: 1;
    color: #22484b !important;
  }
  .text-green-light-900 {
    --bs-text-opacity: 1;
    color: #112426 !important;
  }
  .text-beige-5 {
    --bs-text-opacity: 1;
    color: #fefefe !important;
  }
  .text-beige-50 {
    --bs-text-opacity: 1;
    color: #fefdfc !important;
  }
  .text-beige-100 {
    --bs-text-opacity: 1;
    color: #faf7f4 !important;
  }
  .text-beige-200 {
    --bs-text-opacity: 1;
    color: #f4efea !important;
  }
  .text-beige-300 {
    --bs-text-opacity: 1;
    color: #efe8df !important;
  }
  .text-beige-400 {
    --bs-text-opacity: 1;
    color: #e9e0d5 !important;
  }
  .text-beige-500 {
    --bs-text-opacity: 1;
    color: #e4d8ca !important;
  }
  .text-beige-600 {
    --bs-text-opacity: 1;
    color: #b6ada2 !important;
  }
  .text-beige-700 {
    --bs-text-opacity: 1;
    color: #898279 !important;
  }
  .text-beige-800 {
    --bs-text-opacity: 1;
    color: #5b5651 !important;
  }
  .text-beige-900 {
    --bs-text-opacity: 1;
    color: #2e2b28 !important;
  }
  .text-opacity-25 {
    --bs-text-opacity: 0.25;
  }
  .text-opacity-50 {
    --bs-text-opacity: 0.5;
  }
  .text-opacity-75 {
    --bs-text-opacity: 0.75;
  }
  .text-opacity-100 {
    --bs-text-opacity: 1;
  }
  .bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-primary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-secondary-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-success-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-warning-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
  }
  .bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(
      var(--bs-body-bg-rgb),
      var(--bs-bg-opacity)
    ) !important;
  }
  .bg-transparent {
    --bs-bg-opacity: 1;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .bg-opacity-10 {
    --bs-bg-opacity: 0.1;
  }
  .bg-opacity-25 {
    --bs-bg-opacity: 0.25;
  }
  .bg-opacity-50 {
    --bs-bg-opacity: 0.5;
  }
  .bg-opacity-75 {
    --bs-bg-opacity: 0.75;
  }
  .bg-opacity-100 {
    --bs-bg-opacity: 1;
  }
  .bg-gradient {
    background-image: var(--bs-gradient) !important;
  }
  .user-select-all {
    user-select: all !important;
  }
  .user-select-auto {
    user-select: auto !important;
  }
  .user-select-none {
    user-select: none !important;
  }
  .pe-none {
    pointer-events: none !important;
  }
  .pe-auto {
    pointer-events: auto !important;
  }
  .rounded {
    border-radius: var(--bs-border-radius) !important;
  }
  .rounded-0 {
    border-radius: 0 !important;
  }
  .rounded-1 {
    border-radius: var(--bs-border-radius-sm) !important;
  }
  .rounded-2 {
    border-radius: var(--bs-border-radius) !important;
  }
  .rounded-3 {
    border-radius: var(--bs-border-radius-lg) !important;
  }
  .rounded-4 {
    border-radius: var(--bs-border-radius-xl) !important;
  }
  .rounded-5 {
    border-radius: var(--bs-border-radius-2xl) !important;
  }
  .rounded-circle {
    border-radius: 50% !important;
  }
  .rounded-pill {
    border-radius: var(--bs-border-radius-pill) !important;
  }
  .rounded-top {
    border-top-left-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important;
  }
  .rounded-end {
    border-top-right-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important;
  }
  .rounded-bottom {
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-bottom-left-radius: var(--bs-border-radius) !important;
  }
  .rounded-start {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important;
  }
  .visible {
    visibility: visible !important;
  }
  .invisible {
    visibility: hidden !important;
  }
  @media (min-width: 576px) {
    .float-sm-start {
      float: left !important;
    }
    .float-sm-end {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-grid {
      display: grid !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
    .d-sm-none {
      display: none !important;
    }
    .flex-sm-fill {
      flex: 1 1 auto !important;
    }
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .justify-content-sm-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
    .order-sm-first {
      order: -1 !important;
    }
    .order-sm-0 {
      order: 0 !important;
    }
    .order-sm-1 {
      order: 1 !important;
    }
    .order-sm-2 {
      order: 2 !important;
    }
    .order-sm-3 {
      order: 3 !important;
    }
    .order-sm-4 {
      order: 4 !important;
    }
    .order-sm-5 {
      order: 5 !important;
    }
    .order-sm-last {
      order: 6 !important;
    }
    .m-sm-0 {
      margin: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.5rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mx-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-sm-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-sm-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-sm-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-sm-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-sm-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-sm-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-sm-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-sm-0 {
      margin-top: 0 !important;
    }
    .mt-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mt-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mt-sm-3 {
      margin-top: 1rem !important;
    }
    .mt-sm-4 {
      margin-top: 1.5rem !important;
    }
    .mt-sm-5 {
      margin-top: 3rem !important;
    }
    .mt-sm-auto {
      margin-top: auto !important;
    }
    .me-sm-0 {
      margin-right: 0 !important;
    }
    .me-sm-1 {
      margin-right: 0.25rem !important;
    }
    .me-sm-2 {
      margin-right: 0.5rem !important;
    }
    .me-sm-3 {
      margin-right: 1rem !important;
    }
    .me-sm-4 {
      margin-right: 1.5rem !important;
    }
    .me-sm-5 {
      margin-right: 3rem !important;
    }
    .me-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-0 {
      margin-bottom: 0 !important;
    }
    .mb-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-sm-3 {
      margin-bottom: 1rem !important;
    }
    .mb-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    .mb-sm-5 {
      margin-bottom: 3rem !important;
    }
    .mb-sm-auto {
      margin-bottom: auto !important;
    }
    .ms-sm-0 {
      margin-left: 0 !important;
    }
    .ms-sm-1 {
      margin-left: 0.25rem !important;
    }
    .ms-sm-2 {
      margin-left: 0.5rem !important;
    }
    .ms-sm-3 {
      margin-left: 1rem !important;
    }
    .ms-sm-4 {
      margin-left: 1.5rem !important;
    }
    .ms-sm-5 {
      margin-left: 3rem !important;
    }
    .ms-sm-auto {
      margin-left: auto !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .px-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-sm-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-sm-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-sm-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-sm-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-sm-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .py-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-sm-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-sm-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .pt-sm-0 {
      padding-top: 0 !important;
    }
    .pt-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pt-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pt-sm-3 {
      padding-top: 1rem !important;
    }
    .pt-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pt-sm-5 {
      padding-top: 3rem !important;
    }
    .pe-sm-0 {
      padding-right: 0 !important;
    }
    .pe-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pe-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pe-sm-3 {
      padding-right: 1rem !important;
    }
    .pe-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pe-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-0 {
      padding-bottom: 0 !important;
    }
    .pb-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pb-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pb-sm-5 {
      padding-bottom: 3rem !important;
    }
    .ps-sm-0 {
      padding-left: 0 !important;
    }
    .ps-sm-1 {
      padding-left: 0.25rem !important;
    }
    .ps-sm-2 {
      padding-left: 0.5rem !important;
    }
    .ps-sm-3 {
      padding-left: 1rem !important;
    }
    .ps-sm-4 {
      padding-left: 1.5rem !important;
    }
    .ps-sm-5 {
      padding-left: 3rem !important;
    }
    .gap-sm-0 {
      gap: 0 !important;
    }
    .gap-sm-1 {
      gap: 0.25rem !important;
    }
    .gap-sm-2 {
      gap: 0.5rem !important;
    }
    .gap-sm-3 {
      gap: 1rem !important;
    }
    .gap-sm-4 {
      gap: 1.5rem !important;
    }
    .gap-sm-5 {
      gap: 3rem !important;
    }
    .text-sm-start {
      text-align: left !important;
    }
    .text-sm-end {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  @media (min-width: 768px) {
    .float-md-start {
      float: left !important;
    }
    .float-md-end {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-grid {
      display: grid !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
    .d-md-none {
      display: none !important;
    }
    .flex-md-fill {
      flex: 1 1 auto !important;
    }
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .justify-content-md-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
    .order-md-first {
      order: -1 !important;
    }
    .order-md-0 {
      order: 0 !important;
    }
    .order-md-1 {
      order: 1 !important;
    }
    .order-md-2 {
      order: 2 !important;
    }
    .order-md-3 {
      order: 3 !important;
    }
    .order-md-4 {
      order: 4 !important;
    }
    .order-md-5 {
      order: 5 !important;
    }
    .order-md-last {
      order: 6 !important;
    }
    .m-md-0 {
      margin: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .m-md-4 {
      margin: 1.5rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-md-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-md-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-md-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-md-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-md-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-md-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-md-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-md-0 {
      margin-top: 0 !important;
    }
    .mt-md-1 {
      margin-top: 0.25rem !important;
    }
    .mt-md-2 {
      margin-top: 0.5rem !important;
    }
    .mt-md-3 {
      margin-top: 1rem !important;
    }
    .mt-md-4 {
      margin-top: 1.5rem !important;
    }
    .mt-md-5 {
      margin-top: 3rem !important;
    }
    .mt-md-auto {
      margin-top: auto !important;
    }
    .me-md-0 {
      margin-right: 0 !important;
    }
    .me-md-1 {
      margin-right: 0.25rem !important;
    }
    .me-md-2 {
      margin-right: 0.5rem !important;
    }
    .me-md-3 {
      margin-right: 1rem !important;
    }
    .me-md-4 {
      margin-right: 1.5rem !important;
    }
    .me-md-5 {
      margin-right: 3rem !important;
    }
    .me-md-auto {
      margin-right: auto !important;
    }
    .mb-md-0 {
      margin-bottom: 0 !important;
    }
    .mb-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-md-3 {
      margin-bottom: 1rem !important;
    }
    .mb-md-4 {
      margin-bottom: 1.5rem !important;
    }
    .mb-md-5 {
      margin-bottom: 3rem !important;
    }
    .mb-md-auto {
      margin-bottom: auto !important;
    }
    .ms-md-0 {
      margin-left: 0 !important;
    }
    .ms-md-1 {
      margin-left: 0.25rem !important;
    }
    .ms-md-2 {
      margin-left: 0.5rem !important;
    }
    .ms-md-3 {
      margin-left: 1rem !important;
    }
    .ms-md-4 {
      margin-left: 1.5rem !important;
    }
    .ms-md-5 {
      margin-left: 3rem !important;
    }
    .ms-md-auto {
      margin-left: auto !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-md-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-md-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-md-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-md-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-md-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-md-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-md-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .pt-md-0 {
      padding-top: 0 !important;
    }
    .pt-md-1 {
      padding-top: 0.25rem !important;
    }
    .pt-md-2 {
      padding-top: 0.5rem !important;
    }
    .pt-md-3 {
      padding-top: 1rem !important;
    }
    .pt-md-4 {
      padding-top: 1.5rem !important;
    }
    .pt-md-5 {
      padding-top: 3rem !important;
    }
    .pe-md-0 {
      padding-right: 0 !important;
    }
    .pe-md-1 {
      padding-right: 0.25rem !important;
    }
    .pe-md-2 {
      padding-right: 0.5rem !important;
    }
    .pe-md-3 {
      padding-right: 1rem !important;
    }
    .pe-md-4 {
      padding-right: 1.5rem !important;
    }
    .pe-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-0 {
      padding-bottom: 0 !important;
    }
    .pb-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-md-3 {
      padding-bottom: 1rem !important;
    }
    .pb-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pb-md-5 {
      padding-bottom: 3rem !important;
    }
    .ps-md-0 {
      padding-left: 0 !important;
    }
    .ps-md-1 {
      padding-left: 0.25rem !important;
    }
    .ps-md-2 {
      padding-left: 0.5rem !important;
    }
    .ps-md-3 {
      padding-left: 1rem !important;
    }
    .ps-md-4 {
      padding-left: 1.5rem !important;
    }
    .ps-md-5 {
      padding-left: 3rem !important;
    }
    .gap-md-0 {
      gap: 0 !important;
    }
    .gap-md-1 {
      gap: 0.25rem !important;
    }
    .gap-md-2 {
      gap: 0.5rem !important;
    }
    .gap-md-3 {
      gap: 1rem !important;
    }
    .gap-md-4 {
      gap: 1.5rem !important;
    }
    .gap-md-5 {
      gap: 3rem !important;
    }
    .text-md-start {
      text-align: left !important;
    }
    .text-md-end {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  @media (min-width: 992px) {
    .float-lg-start {
      float: left !important;
    }
    .float-lg-end {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-grid {
      display: grid !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
    .d-lg-none {
      display: none !important;
    }
    .flex-lg-fill {
      flex: 1 1 auto !important;
    }
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .justify-content-lg-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
    .order-lg-first {
      order: -1 !important;
    }
    .order-lg-0 {
      order: 0 !important;
    }
    .order-lg-1 {
      order: 1 !important;
    }
    .order-lg-2 {
      order: 2 !important;
    }
    .order-lg-3 {
      order: 3 !important;
    }
    .order-lg-4 {
      order: 4 !important;
    }
    .order-lg-5 {
      order: 5 !important;
    }
    .order-lg-last {
      order: 6 !important;
    }
    .m-lg-0 {
      margin: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.5rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-lg-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-lg-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-lg-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-lg-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-lg-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-lg-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-lg-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-lg-0 {
      margin-top: 0 !important;
    }
    .mt-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mt-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mt-lg-3 {
      margin-top: 1rem !important;
    }
    .mt-lg-4 {
      margin-top: 1.5rem !important;
    }
    .mt-lg-5 {
      margin-top: 3rem !important;
    }
    .mt-lg-auto {
      margin-top: auto !important;
    }
    .me-lg-0 {
      margin-right: 0 !important;
    }
    .me-lg-1 {
      margin-right: 0.25rem !important;
    }
    .me-lg-2 {
      margin-right: 0.5rem !important;
    }
    .me-lg-3 {
      margin-right: 1rem !important;
    }
    .me-lg-4 {
      margin-right: 1.5rem !important;
    }
    .me-lg-5 {
      margin-right: 3rem !important;
    }
    .me-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-0 {
      margin-bottom: 0 !important;
    }
    .mb-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-lg-3 {
      margin-bottom: 1rem !important;
    }
    .mb-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    .mb-lg-5 {
      margin-bottom: 3rem !important;
    }
    .mb-lg-auto {
      margin-bottom: auto !important;
    }
    .ms-lg-0 {
      margin-left: 0 !important;
    }
    .ms-lg-1 {
      margin-left: 0.25rem !important;
    }
    .ms-lg-2 {
      margin-left: 0.5rem !important;
    }
    .ms-lg-3 {
      margin-left: 1rem !important;
    }
    .ms-lg-4 {
      margin-left: 1.5rem !important;
    }
    .ms-lg-5 {
      margin-left: 3rem !important;
    }
    .ms-lg-auto {
      margin-left: auto !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-lg-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-lg-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-lg-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-lg-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-lg-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-lg-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-lg-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .pt-lg-0 {
      padding-top: 0 !important;
    }
    .pt-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pt-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pt-lg-3 {
      padding-top: 1rem !important;
    }
    .pt-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pt-lg-5 {
      padding-top: 3rem !important;
    }
    .pe-lg-0 {
      padding-right: 0 !important;
    }
    .pe-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pe-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pe-lg-3 {
      padding-right: 1rem !important;
    }
    .pe-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pe-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-0 {
      padding-bottom: 0 !important;
    }
    .pb-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pb-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pb-lg-5 {
      padding-bottom: 3rem !important;
    }
    .ps-lg-0 {
      padding-left: 0 !important;
    }
    .ps-lg-1 {
      padding-left: 0.25rem !important;
    }
    .ps-lg-2 {
      padding-left: 0.5rem !important;
    }
    .ps-lg-3 {
      padding-left: 1rem !important;
    }
    .ps-lg-4 {
      padding-left: 1.5rem !important;
    }
    .ps-lg-5 {
      padding-left: 3rem !important;
    }
    .gap-lg-0 {
      gap: 0 !important;
    }
    .gap-lg-1 {
      gap: 0.25rem !important;
    }
    .gap-lg-2 {
      gap: 0.5rem !important;
    }
    .gap-lg-3 {
      gap: 1rem !important;
    }
    .gap-lg-4 {
      gap: 1.5rem !important;
    }
    .gap-lg-5 {
      gap: 3rem !important;
    }
    .text-lg-start {
      text-align: left !important;
    }
    .text-lg-end {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1200px) {
    .float-xl-start {
      float: left !important;
    }
    .float-xl-end {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-grid {
      display: grid !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
    .d-xl-none {
      display: none !important;
    }
    .flex-xl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .justify-content-xl-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
    .order-xl-first {
      order: -1 !important;
    }
    .order-xl-0 {
      order: 0 !important;
    }
    .order-xl-1 {
      order: 1 !important;
    }
    .order-xl-2 {
      order: 2 !important;
    }
    .order-xl-3 {
      order: 3 !important;
    }
    .order-xl-4 {
      order: 4 !important;
    }
    .order-xl-5 {
      order: 5 !important;
    }
    .order-xl-last {
      order: 6 !important;
    }
    .m-xl-0 {
      margin: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.5rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mx-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-xl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-xl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-xl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-xl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-xl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-xl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-xl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xl-0 {
      margin-top: 0 !important;
    }
    .mt-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mt-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mt-xl-3 {
      margin-top: 1rem !important;
    }
    .mt-xl-4 {
      margin-top: 1.5rem !important;
    }
    .mt-xl-5 {
      margin-top: 3rem !important;
    }
    .mt-xl-auto {
      margin-top: auto !important;
    }
    .me-xl-0 {
      margin-right: 0 !important;
    }
    .me-xl-1 {
      margin-right: 0.25rem !important;
    }
    .me-xl-2 {
      margin-right: 0.5rem !important;
    }
    .me-xl-3 {
      margin-right: 1rem !important;
    }
    .me-xl-4 {
      margin-right: 1.5rem !important;
    }
    .me-xl-5 {
      margin-right: 3rem !important;
    }
    .me-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-0 {
      margin-bottom: 0 !important;
    }
    .mb-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-xl-3 {
      margin-bottom: 1rem !important;
    }
    .mb-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    .mb-xl-5 {
      margin-bottom: 3rem !important;
    }
    .mb-xl-auto {
      margin-bottom: auto !important;
    }
    .ms-xl-0 {
      margin-left: 0 !important;
    }
    .ms-xl-1 {
      margin-left: 0.25rem !important;
    }
    .ms-xl-2 {
      margin-left: 0.5rem !important;
    }
    .ms-xl-3 {
      margin-left: 1rem !important;
    }
    .ms-xl-4 {
      margin-left: 1.5rem !important;
    }
    .ms-xl-5 {
      margin-left: 3rem !important;
    }
    .ms-xl-auto {
      margin-left: auto !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .px-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-xl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-xl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-xl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-xl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-xl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .py-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-xl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-xl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .pt-xl-0 {
      padding-top: 0 !important;
    }
    .pt-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pt-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pt-xl-3 {
      padding-top: 1rem !important;
    }
    .pt-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pt-xl-5 {
      padding-top: 3rem !important;
    }
    .pe-xl-0 {
      padding-right: 0 !important;
    }
    .pe-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pe-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pe-xl-3 {
      padding-right: 1rem !important;
    }
    .pe-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pe-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-0 {
      padding-bottom: 0 !important;
    }
    .pb-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pb-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pb-xl-5 {
      padding-bottom: 3rem !important;
    }
    .ps-xl-0 {
      padding-left: 0 !important;
    }
    .ps-xl-1 {
      padding-left: 0.25rem !important;
    }
    .ps-xl-2 {
      padding-left: 0.5rem !important;
    }
    .ps-xl-3 {
      padding-left: 1rem !important;
    }
    .ps-xl-4 {
      padding-left: 1.5rem !important;
    }
    .ps-xl-5 {
      padding-left: 3rem !important;
    }
    .gap-xl-0 {
      gap: 0 !important;
    }
    .gap-xl-1 {
      gap: 0.25rem !important;
    }
    .gap-xl-2 {
      gap: 0.5rem !important;
    }
    .gap-xl-3 {
      gap: 1rem !important;
    }
    .gap-xl-4 {
      gap: 1.5rem !important;
    }
    .gap-xl-5 {
      gap: 3rem !important;
    }
    .text-xl-start {
      text-align: left !important;
    }
    .text-xl-end {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1400px) {
    .float-xxl-start {
      float: left !important;
    }
    .float-xxl-end {
      float: right !important;
    }
    .float-xxl-none {
      float: none !important;
    }
    .d-xxl-inline {
      display: inline !important;
    }
    .d-xxl-inline-block {
      display: inline-block !important;
    }
    .d-xxl-block {
      display: block !important;
    }
    .d-xxl-grid {
      display: grid !important;
    }
    .d-xxl-table {
      display: table !important;
    }
    .d-xxl-table-row {
      display: table-row !important;
    }
    .d-xxl-table-cell {
      display: table-cell !important;
    }
    .d-xxl-flex {
      display: flex !important;
    }
    .d-xxl-inline-flex {
      display: inline-flex !important;
    }
    .d-xxl-none {
      display: none !important;
    }
    .flex-xxl-fill {
      flex: 1 1 auto !important;
    }
    .flex-xxl-row {
      flex-direction: row !important;
    }
    .flex-xxl-column {
      flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xxl-grow-0 {
      flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
      flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
      flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
      flex-shrink: 1 !important;
    }
    .flex-xxl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-xxl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
      justify-content: center !important;
    }
    .justify-content-xxl-between {
      justify-content: space-between !important;
    }
    .justify-content-xxl-around {
      justify-content: space-around !important;
    }
    .justify-content-xxl-evenly {
      justify-content: space-evenly !important;
    }
    .align-items-xxl-start {
      align-items: flex-start !important;
    }
    .align-items-xxl-end {
      align-items: flex-end !important;
    }
    .align-items-xxl-center {
      align-items: center !important;
    }
    .align-items-xxl-baseline {
      align-items: baseline !important;
    }
    .align-items-xxl-stretch {
      align-items: stretch !important;
    }
    .align-content-xxl-start {
      align-content: flex-start !important;
    }
    .align-content-xxl-end {
      align-content: flex-end !important;
    }
    .align-content-xxl-center {
      align-content: center !important;
    }
    .align-content-xxl-between {
      align-content: space-between !important;
    }
    .align-content-xxl-around {
      align-content: space-around !important;
    }
    .align-content-xxl-stretch {
      align-content: stretch !important;
    }
    .align-self-xxl-auto {
      align-self: auto !important;
    }
    .align-self-xxl-start {
      align-self: flex-start !important;
    }
    .align-self-xxl-end {
      align-self: flex-end !important;
    }
    .align-self-xxl-center {
      align-self: center !important;
    }
    .align-self-xxl-baseline {
      align-self: baseline !important;
    }
    .align-self-xxl-stretch {
      align-self: stretch !important;
    }
    .order-xxl-first {
      order: -1 !important;
    }
    .order-xxl-0 {
      order: 0 !important;
    }
    .order-xxl-1 {
      order: 1 !important;
    }
    .order-xxl-2 {
      order: 2 !important;
    }
    .order-xxl-3 {
      order: 3 !important;
    }
    .order-xxl-4 {
      order: 4 !important;
    }
    .order-xxl-5 {
      order: 5 !important;
    }
    .order-xxl-last {
      order: 6 !important;
    }
    .m-xxl-0 {
      margin: 0 !important;
    }
    .m-xxl-1 {
      margin: 0.25rem !important;
    }
    .m-xxl-2 {
      margin: 0.5rem !important;
    }
    .m-xxl-3 {
      margin: 1rem !important;
    }
    .m-xxl-4 {
      margin: 1.5rem !important;
    }
    .m-xxl-5 {
      margin: 3rem !important;
    }
    .m-xxl-auto {
      margin: auto !important;
    }
    .mx-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .mx-xxl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    .mx-xxl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    .mx-xxl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    .mx-xxl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    .mx-xxl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    .mx-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
    .my-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .my-xxl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    .my-xxl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    .my-xxl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    .my-xxl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    .my-xxl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    .my-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
    .mt-xxl-0 {
      margin-top: 0 !important;
    }
    .mt-xxl-1 {
      margin-top: 0.25rem !important;
    }
    .mt-xxl-2 {
      margin-top: 0.5rem !important;
    }
    .mt-xxl-3 {
      margin-top: 1rem !important;
    }
    .mt-xxl-4 {
      margin-top: 1.5rem !important;
    }
    .mt-xxl-5 {
      margin-top: 3rem !important;
    }
    .mt-xxl-auto {
      margin-top: auto !important;
    }
    .me-xxl-0 {
      margin-right: 0 !important;
    }
    .me-xxl-1 {
      margin-right: 0.25rem !important;
    }
    .me-xxl-2 {
      margin-right: 0.5rem !important;
    }
    .me-xxl-3 {
      margin-right: 1rem !important;
    }
    .me-xxl-4 {
      margin-right: 1.5rem !important;
    }
    .me-xxl-5 {
      margin-right: 3rem !important;
    }
    .me-xxl-auto {
      margin-right: auto !important;
    }
    .mb-xxl-0 {
      margin-bottom: 0 !important;
    }
    .mb-xxl-1 {
      margin-bottom: 0.25rem !important;
    }
    .mb-xxl-2 {
      margin-bottom: 0.5rem !important;
    }
    .mb-xxl-3 {
      margin-bottom: 1rem !important;
    }
    .mb-xxl-4 {
      margin-bottom: 1.5rem !important;
    }
    .mb-xxl-5 {
      margin-bottom: 3rem !important;
    }
    .mb-xxl-auto {
      margin-bottom: auto !important;
    }
    .ms-xxl-0 {
      margin-left: 0 !important;
    }
    .ms-xxl-1 {
      margin-left: 0.25rem !important;
    }
    .ms-xxl-2 {
      margin-left: 0.5rem !important;
    }
    .ms-xxl-3 {
      margin-left: 1rem !important;
    }
    .ms-xxl-4 {
      margin-left: 1.5rem !important;
    }
    .ms-xxl-5 {
      margin-left: 3rem !important;
    }
    .ms-xxl-auto {
      margin-left: auto !important;
    }
    .p-xxl-0 {
      padding: 0 !important;
    }
    .p-xxl-1 {
      padding: 0.25rem !important;
    }
    .p-xxl-2 {
      padding: 0.5rem !important;
    }
    .p-xxl-3 {
      padding: 1rem !important;
    }
    .p-xxl-4 {
      padding: 1.5rem !important;
    }
    .p-xxl-5 {
      padding: 3rem !important;
    }
    .px-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    .px-xxl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    .px-xxl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    .px-xxl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    .px-xxl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    .px-xxl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
    .py-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .py-xxl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    .py-xxl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    .py-xxl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    .py-xxl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    .py-xxl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
    .pt-xxl-0 {
      padding-top: 0 !important;
    }
    .pt-xxl-1 {
      padding-top: 0.25rem !important;
    }
    .pt-xxl-2 {
      padding-top: 0.5rem !important;
    }
    .pt-xxl-3 {
      padding-top: 1rem !important;
    }
    .pt-xxl-4 {
      padding-top: 1.5rem !important;
    }
    .pt-xxl-5 {
      padding-top: 3rem !important;
    }
    .pe-xxl-0 {
      padding-right: 0 !important;
    }
    .pe-xxl-1 {
      padding-right: 0.25rem !important;
    }
    .pe-xxl-2 {
      padding-right: 0.5rem !important;
    }
    .pe-xxl-3 {
      padding-right: 1rem !important;
    }
    .pe-xxl-4 {
      padding-right: 1.5rem !important;
    }
    .pe-xxl-5 {
      padding-right: 3rem !important;
    }
    .pb-xxl-0 {
      padding-bottom: 0 !important;
    }
    .pb-xxl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pb-xxl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pb-xxl-3 {
      padding-bottom: 1rem !important;
    }
    .pb-xxl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pb-xxl-5 {
      padding-bottom: 3rem !important;
    }
    .ps-xxl-0 {
      padding-left: 0 !important;
    }
    .ps-xxl-1 {
      padding-left: 0.25rem !important;
    }
    .ps-xxl-2 {
      padding-left: 0.5rem !important;
    }
    .ps-xxl-3 {
      padding-left: 1rem !important;
    }
    .ps-xxl-4 {
      padding-left: 1.5rem !important;
    }
    .ps-xxl-5 {
      padding-left: 3rem !important;
    }
    .gap-xxl-0 {
      gap: 0 !important;
    }
    .gap-xxl-1 {
      gap: 0.25rem !important;
    }
    .gap-xxl-2 {
      gap: 0.5rem !important;
    }
    .gap-xxl-3 {
      gap: 1rem !important;
    }
    .gap-xxl-4 {
      gap: 1.5rem !important;
    }
    .gap-xxl-5 {
      gap: 3rem !important;
    }
    .text-xxl-start {
      text-align: left !important;
    }
    .text-xxl-end {
      text-align: right !important;
    }
    .text-xxl-center {
      text-align: center !important;
    }
  }
  @media (min-width: 1200px) {
    .fs-1 {
      font-size: 2.1875rem !important;
    }
    .fs-2 {
      font-size: 1.75rem !important;
    }
    .fs-3 {
      font-size: 1.53125rem !important;
    }
    .fs-4 {
      font-size: 1.3125rem !important;
    }
  }
  @media print {
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-grid {
      display: grid !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: flex !important;
    }
    .d-print-inline-flex {
      display: inline-flex !important;
    }
    .d-print-none {
      display: none !important;
    }
  }
  body {
    background-color: #fafdff;
  }
  * {
    outline: none;
  }
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    letter-spacing: var(--letter-spacing);
  }
  .btn {
    font-size: var(--h5);
    border: none;
  }
  .page-link,
  .dropdown-item,
  .btn {
    transition: all 0.3s ease-in-out;
  }
  .input-group:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .input-before-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem 0.375rem 0;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    border-radius: 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .line-input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #495057;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0px;
    padding-left: 30px;
  }
  .line-input option {
    background-color: #fff;
    padding: 2px 10px;
  }
  .line-input:focus {
    outline: none;
    border-bottom: 1px solid #56b5bc;
    background-color: rgba(0, 0, 0, 0);
  }
  input {
    font-size: var(--h6);
  }
  .line-inputs .input-before-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem 0.375rem 0;
    font-size: 0.875rem;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    border-radius: 0px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .line-inputs input,
  .line-inputs select,
  .line-inputs button,
  .line-inputs .line-input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #495057;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0px;
    padding-left: 30px;
  }
  .line-inputs input.select,
  .line-inputs select.select,
  .line-inputs button.select,
  .line-inputs .line-input.select {
    padding-left: 0px;
  }
  .line-inputs input option,
  .line-inputs select option,
  .line-inputs button option,
  .line-inputs .line-input option {
    background-color: #fff;
  }
  .line-inputs input:focus,
  .line-inputs select:focus,
  .line-inputs button:focus,
  .line-inputs .line-input:focus {
    outline: none;
    border-bottom: 1px solid #56b5bc;
    background-color: rgba(0, 0, 0, 0);
  }
  .line-inputs button {
    padding-left: 0.75rem;
  }
  .line-inputs button:focus,
  .line-inputs button:active {
    outline: none !important;
    box-shadow: none !important;
  }
  .line-inputs button:hover,
  .line-inputs button.btn-outline-secondary:hover {
    background-color: #fbfcfd;
    color: #56b5bc;
  }
  .line-inputs button.show,
  .line-inputs button.dropdown-toggle.show {
    color: #245a6c;
    background-color: #d6e2e6;
    border-color: inherit;
    outline: none;
  }
  .icon_only_btn_dropdown button {
    display: flex;
    align-items: center;
  }
  .icon_only_btn_dropdown button.show:before {
    color: #245a6c;
  }
  .icon_only_btn_dropdown button:hover:before {
    color: #245a6c;
  }
  .icon_only_btn_dropdown button:before {
    margin-right: 10px;
    color: #adb5bd;
    transition: all 0.3s ease-in-out;
  }
  .dropdown-menu {
    border-radius: 14px;
    border-color: #e9ecef;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  }
  .custom_dropdown_style .dropdown_iW {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin: 0;
  }
  .custom_dropdown_style .dropdown__title {
    flex: 0 0 auto;
    width: 100%;
    font-size: var(--h4);
    font-weight: 500;
    margin-bottom: 10px;
    padding: 0px 15px 5px;
    border-bottom: 1px solid #d6e2e6;
    color: #347084;
  }
  .custom_dropdown_style ul {
    flex: 0 0 auto;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .custom_dropdown_style ul li {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin: 0;
    border-bottom: 1px solid #f8f9fa;
  }
  .custom_dropdown_style ul li:last-child {
    border-bottom: none;
  }
  .custom_dropdown_style ul li a {
    font-size: var(--h5);
    padding-top: 5px;
    padding-bottom: 5px;
  }
  * {
    scrollbar-width: thin;
    scrollbar-color: #56b5bc rgba(0, 0, 0, 0);
  }
  *::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }
  *::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
  *::-webkit-scrollbar-thumb {
    background-color: #d6e2e6;
    border-radius: 5px;
  }
  .node {
    margin-top: 30px;
    width: 280px;
    border-radius: 20px;
    border-color: #e89f7a;
    border-width: 1px;
    border-style: solid;
    border-top-width: 4px;
    background-color: #fff;
  }
  .node__container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .node__iW {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin: 0;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
  }
  .node__iW.gap {
    padding-top: 15px;
    margin-bottom: 15px;
    border-top: 1px solid #e9ecef;
  }
  .node__top__left {
    flex: 0 0 auto;
    width: auto;
    position: relative;
    width: 60px;
  }
  .node__top__right {
    flex: 1 1 0;
    max-width: 100%;
  }
  .node__top__right ul {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    margin: 0;
    list-style-type: none;
    align-items: center;
    justify-content: flex-end;
  }
  .node__top__right ul li {
    flex: 0 0 auto;
    width: auto;
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
  }
  .node__top__right ul li a {
    font-size: var(--h2);
    display: inline-flex;
  }
  .node__nickname {
    flex: 0 0 auto;
    width: auto;
    font-size: var(--h4);
    font-weight: 500;
    color: #343a40;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #e9ecef;
  }
  .node__fullname {
    flex: 1 1 0;
    max-width: 100%;
    color: #6c757d;
    font-size: var(--h6);
  }
  .node__profile__icon {
    flex: 0 0 auto;
    width: auto;
    font-size: 20px;
    color: #f6d9ca;
    display: flex;
    margin-right: 5px;
  }
  .node__city {
    flex: 1 1 0;
    max-width: 100%;
    color: #347084;
    font-size: var(--h6);
    display: flex;
    align-items: center;
    background-color: #d6e2e6;
    border-radius: 50px;
    padding: 5px 6px;
    margin-right: 5px;
  }
  .node__city:before {
    font-size: 15px;
  }
  .node__location {
    flex: 1 1 0;
    max-width: 100%;
    color: #e89f7a;
    font-size: var(--h6);
    display: flex;
    align-items: center;
    background-color: #faece4;
    border-radius: 50px;
    padding: 5px 6px;
  }
  .node__location:before {
    font-size: 15px;
  }
  .node__thumb {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    top: -20px;
    transform: translateY(-50%);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .node__thumb img,
  .node__thumb svg {
    width: 100%;
    height: 100%;
    display: flex;
    place-items: center;
  }
  .node__thumb__chat {
    background-color: #aec6ce;
    display: inline-flex;
    position: absolute;
    left: 37px;
    top: -37px;
    padding: 8px 15px 8px 20px;
    border-radius: 0px 50px 50px 0px;
    color: #fff;
    border: 2px solid #fff;
  }
  /*# sourceMappingURL=node.b2c7c70ecac59804cdf8e3d20bbdd11e.css.map */
  