.sys__toolbar {
  //   min-height: 82px;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0px 12px 14px #b0c5e50f;

  .dashed-divider,
  .divider {
    width: 1px;
    height: 90%;
    display: flex;
    background-color: #e9eaed;
    margin: 0 var(--layout-spacing-16);
  }

  .dashed-divider {
    width: 8px;
    height: 3px;
    background-color: #245a6c;
    margin: 0 var(--layout-spacing-8);
  }

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--layout-spacing-20);
  }

  &__start,
  &__center,
  &__end {
    display: flex;
  }

  &__start {
    // padding: var(--layout-spacing-4) 0;
    overflow: hidden;
    flex: 1 0 0px;

    .action {
      margin-left: var(--layout-spacing-24);
      display: flex;
      align-items: center;
    }
  }

  &__center {
    flex: 1;
    justify-content: center;
  }

  &__end {
    flex: 1 0 0px;
    justify-content: flex-end;

    .actions {
      .inner {
        display: flex;
        align-items: center;

        .nav__button {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;

          &.chat__btn,
          &.faq__btn,
          &.btn__notify {
            .icon__button__element {
              color: var(--font-tint-04);
            }

            .button__icon {
              --icon-button-size: var(--layout-size-24);
              --icon-button-icon-size: var(--layout-size-16);
              --icon-button-icon-color: var(--font-tint-04);
            }
          }

          &.chat__btn {
            .button__icon {
              margin-top: 3px;
            }
          }

          &.btn__notify {
            position: relative;

            .dot {
              position: absolute;
              width: 8px;
              height: 8px;
              color: #fff;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #ff5e5e;
              border-radius: 999px;
              top: 8px;
              right: 8px;
            }
          }
        }

        .btn__create {
          margin-left: var(--layout-spacing-24);
          white-space: nowrap;
        }
      }
    }
  }

  &__breadcrumb {
    // margin-left: 82px;
    display: flex;
    align-items: center;
    padding: 0 var(--layout-spacing-20);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
    border-top: 1px solid #dce6ec;

    .toggle__menu {
      width: var(--layout-size-12);
      height: var(--layout-size-12);
      display: block;
      margin-right: var(--layout-size-36);
      color: var(--font-tint-04);

      &:hover {
        color: var(--font-tint-03);
      }

      .icon {
        --icon-size: var(--layout-size-12);
        --icon-font-size: var(--layout-size-12);
        --icon-font-color: inherit;
      }
    }

    .top__nav__breadcrumb {
      margin: 0;
      flex-grow: 1;
      padding: var(--layout-spacing-4) 0;
      border: none;
    }
  }
}
