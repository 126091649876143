.base__modal {
  display: flex;
  align-items: center;
  max-width: 730px;
  width: 730px;
  z-index: 1;
  margin: 24px auto;
  min-height: calc(100% - (24px * 2));

  &--xs {
    max-width: 430px;
  }

  &--sm {
    max-width: 540px;
    width: 540px;
  }

  &__content {
    background-color: #fff;
    border-radius: 10px;
    min-height: 200px;
    width: 100%;
    overflow: hidden;
  }

  &__header {
    padding: 32px 40px 24px 40px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .end,
    .start {
      display: block;
    }

    .title {
      font-size: var(--h1);
      color: #245a6c;
      font-weight: 600;
    }

    .subtitle {
      font-size: var(--h5);
      color: #347084;
    }

    .modal__close {
      width: 32px;
      height: 32px;
      border: none;
      outline: 0;
      background-color: rgba(0, 0, 0, 0);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 32px;
      padding: 0;

      .icon {
        width: 14px;
        height: 14px;
      }
    }
  }

  &__body {
    display: block;
  }

  &__contents {
    padding: 0 40px 24px;
  }

  &__footer {
    background-color: #ecf6f7;
    display: flex;
    justify-content: flex-end;
    padding: 24px 40px;
  }
}
