.inputcls {
  font-family: "Roboto", sans-serif;
  border: 0.5px solid #b0c5e5;
  border-radius: 5px;
  box-sizing: border-box;
  height: 35px;
  opacity: 1;
  outline: none;
  padding: 8px 10px 9px 10px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  /* margin: 10px; */
}

.inputcls:focus {
  border-color: #3e778a;
  box-shadow: 0 0 8px 0 #3e778a;
}
