.r__btn {
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
  transition: var(--animate-1);

  &:hover {
    background-color: #eee;
  }

  svg {
    width: 20px;
    max-width: 20px;
    fill: #347084;
  }

  &--pws {
    border-radius: 4px;
   
  }
}
