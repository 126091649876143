.web__button {
  &.icon-right {
    flex-direction: row-reverse;
    display: inline-flex;
    align-items: center;

    &::before {
      margin-right: 0;
      margin-left: 12px;
    }
  }
}
