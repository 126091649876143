.setting__search {
  --search-bg-clr: #f8fafe;
  padding: 20px 12px 16px;
  background-color: var(--search-bg-clr);

  input {
    &::placeholder {
        font-style: italic;
    }
  }
}
