.table__action {
  display: flex;
  align-items: center;
  gap: 12px;

  .action {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #aaaeb7;
    font-size: 16px;
    border: none;
    background-color: transparent;
    outline: 0;

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: #aaaeb7;
      }
    }
  }

  button.action {
    &:not(:disabled):hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    span.icon {
      color: #aaaeb7;
      display: inline-block;

      &.checked {
      }

      &.unchecked {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
