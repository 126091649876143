.notification {
  top: 40px;
  right: 74px;
  z-index: 9;
}

.empty__message {
  padding: 32px 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: var(--h4);
    color: var(--text-clr-1);
    font-weight: 600;
    margin: 0;
    text-align: center;
  }

  .sub__text {
    font-size: var(--h5);
    color: #adb5bd;
    font-weight: 400;
    margin-top: 4px;
  }
}

