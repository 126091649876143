.card__wlt {
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	transition: 0.3s;
	width: 100%;
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	border: 0.5px,0px,0.5px,0px dashed var(--unnamed-color-4a7f91);
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px dashed #4A7F91;
	border-radius: 9px;
    color: #56B5BC;
        font: 14px/100% arial, sans-serif;
         font-weight: bold;
	&:hover {
		box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	}
	.title {
		background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
		border: 0.5px dashed var(--unnamed-color-4a7f91);
		background: #FFFFFF 0% 0% no-repeat padding-box;
		border-bottom: 0.5px dashed #4A7F91;
		border-top-left-radius: 9px;
		border-top-right-radius: 9px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
		opacity: 1;
		padding-left: 10px;
		padding-top: 10px;
		height: 35px;
        color: #56B5BC;
        font: 14px/100% arial, sans-serif;
         font-weight: bold;
	}
    [class*='close-'] {
		
        cursor: pointer;
	}
    .close-edit {
        cursor: pointer;
		&:after {
			content: '';
		}
	}
    .container{
        margin-top: 5px;
    }
    li::marker{
        color: #E89F7A;
    }
	.action-btn {
		float: right;
		margin-top: -6px;
	}
}
