.brief__toolbar {
  display: flex;
  justify-items: center;
  align-content: space-between;
  margin-bottom: 16px;

  .start,
  .center,
  .end {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .start {
    .title {
      font-weight: 700;
      color: #2a5a6a;
      margin-bottom: 0px;
    }
  }

  .center {
    justify-content: center;
  }

  .end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
}

.brief__card {
  display: flex;
  flex-direction: column;
  border: 0.031rem solid #cedade;
  border-radius: 10px;
  background-color: #fcfaf9;
  overflow: hidden;

  &__top {
    padding: 24px;
    padding-bottom: 36px;

    .title {
      color: #394252;
      font-weight: 700;
      font-size: var(--h2);
    }

    .content {
      margin-top: 8px;
      color: #394252;
      font-size: var(--h5);
    }
  }

  &__info {
    background-color: #fff;
    .card__info {
      display: flex;
      padding: 16px 24px;

      &__start {
        padding-right: 16px;
        flex: 1 1 60%;
        display: flex;
        align-items: center;

        &__iw {
          display: flex;
          gap: 40px;
          align-self: center;

          .title {
            color: #245a6c;
            font-size: var(--h6);
            font-weight: 600;
            margin-bottom: 8px;
          }

          .left {
            flex: 1 1 40%;
            .contact__prs {
              display: flex;

              &__info {
                margin-left: 16px;

                .job__title {
                  color: #7f8694;
                  font-size: var(--small);
                }
                .name {
                  color: #404a5f;
                  font-size: var(--h5);
                  display: block;
                  padding-right: 30px;
                  position: relative;

                  a {
                    display: block;
                    font-size: 20px;
                    margin-left: 12px;
                    position: absolute;
                    right: 0;
                    top: -6px;
                    line-height: 1;
                  }
                }
              }
            }
          }

          .right {
            flex: 1 1 60%;

            .project__info {
              display: flex;

              .name {
                font-size: var(--h5);
                color: #404a5f;
                font-weight: 600;
                padding-right: 16px;
              }

              .desc {
                color: #7f8694;
                font-size: var(--h6);
                letter-spacing: 0;
              }
            }
          }
        }
      }

      &__center {
        padding-left: 16px;
        padding-right: 24px;
        border-left: 1px solid #cedade;
        flex: 1;
        flex-basis: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-bottom: 24px;
          }

          &__start {
            margin-right: 12px;

            .icon {
              font-size: 24px;
              color: #e89f7a;
              display: flex;
            }
          }

          &__end {
            .label {
              font-size: var(--small);
              color: #56b5bc;
            }
            .text {
              font-size: var(--h5);
              font-weight: 600;
              color: #404a5f;
            }
          }
        }
      }

      &__end {
        flex: 1;
        flex-basis: 25%;
        border-left: 1px solid #cedade;
        display: flex;
        padding-left: 20px;

        .container {
          position: relative;
          overflow: hidden;
          width: 100%;
          padding-top: 56.25%;
          border-radius: 8px;
          border: 6px solid #ede5db;
        }

        .responsive-iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__bottom {
    background-color: #347084;
    padding: 24px;
    display: flex;
    justify-content: space-between;

    .info__block {
      display: flex;
      flex: 1;

      &__start {
        margin-right: 16px;

        .icon {
          font-size: 20px;
          color: #e89f7a;
        }
      }

      &__end {
        h6 {
          font-size: var(--h6);
          color: #68bdc3;
          margin-bottom: 0;
        }

        p,
        a {
          color: #ffffff;
          font-weight: 600;
          margin: 0;
          font-size: var(--h5);
        }
      }
    }
  }
}

.app__status {
  text-align: center;
  border-radius: 10px;
  padding: 8px 16px;
  display: flex;
  align-content: center;
  align-items: center;

  .icon {
  }

  .text {
    font-weight: 600;
    font-size: var(--h6);
  }

  &__pending {
    background-color: #d7eefb;
    color: #64c7ff;
  }
}
