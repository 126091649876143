.table__list {
  --tb-header-text-color: #394252;

  display: block;

  .rows,
  .columns {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }

  .column {
    width: auto;

    &.sortable {
      cursor: pointer;
    }
  }

  &__header {
    display: block;
    border-bottom: 1px solid #d4d6db;

    .column {
      display: flex;
      align-items: center;
      padding: 8px;

      &.down,
      &.up {
        .sort__icon {
            color: var(--tb-header-text-color);
        }
      }

      &.up {
        .sort__icon {
            transform: rotate(-180deg);
        }
      }
    }

    .text {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: var(--h5);
      font-weight: 600;
      color: var(--tb-header-text-color);
    }

    .sort__icon {
      font-size: 9px;
      display: inline-block;
      vertical-align: middle;
      line-height: 12px;
      margin-left: 8px;
      color: #BFC2C9;
    }
  }

  &__body {
    display: block;

    .rows {
      align-items: flex-start;

      .column {
        flex-wrap: wrap;
        padding: 8px;
        font-size: var(--h5);
        color: var(--tb-header-text-color);
      }
    }

    .table__empty {
      padding: 8px 0;
      text-align: center;
      font-size: var(--h5);
      color: rgb(0 0 0 / 50%);
      margin: 4px 0;
    }
  }
}
