.contract_request_modal {
  max-width: 720px;
  width: 48vw;
  height: auto !important;

  .pop__bottom__iw {
    grid-gap: 16px !important;
  }

  .buttons__wr {
    button {
      margin-left: 0 !important;
    }
  }
}
