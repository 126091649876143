._wrapper
    background: #fff
    width: 546px
    max-width: 100%
    box-sizing: border-box
    padding-left: 3%
    padding-right: 3%
    height: 300px
    position: relative
    z-index: 1
    -webkit-box-shadow: 0px 11px 19px #b0c5e526
    -moz-box-shadow: 0px 11px 19px #b0c5e526
    -webkit-transform-origin: 50% 0%
    transform-origin: 50% 0%
    -webkit-transform: scale3d(1, 1, 1)
    transform: scale3d(1, 1, 1)
    -webkit-transition: none
    transition: none
    -webkit-animation: expand 0.8s 0.6s ease-out forwards
    animation: expand 0.8s 0.6s ease-out forwards
    opacity: 1
    background: #ffffff 0% 0% no-repeat padding-box
    box-shadow: 0px 11px 19px #b0c5e526
    border: 1px solid #b0c5e557
    border-radius: 15px
    margin-left: calc(100% / 2 - 546px / 2)
    margin-top: 5%

label
    font: normal normal medium 13px/18px Roboto

.p-logo
    z-index: 2
    position: fixed
    top: 2em
    width: 3.75em
    left: -6.2%
    background: transparentize(#fff, 1)

.title_container
    padding-bottom: 10px
    padding-top: 20px
.t1
    font: normal normal bold 26px/17px Roboto
    letter-spacing: -0.52px
    color: #245a6c
    opacity: 1
    margin-bottom: 0

    &-sub
        font: normal normal normal 13px/18px Roboto
        letter-spacing: 0px
        color: #245a6c
        margin-top: 1em
.desc
    letter-spacing: 1px
    font-size: 14px
    color: #394252
.create-new-btn
    font-weight: bold
    float: left
    border-radius: 0.5em
    background: #347084 0% 0% no-repeat padding-box
    height: 2.5em
    width: auto
    padding: 0 2em
    font: normal normal normal 13px/15px Roboto
    letter-spacing: 0px
    color: #ffffff
    cursor: pointer
    box-sizing: border-box
    margin-right: 3em
    margin-top: 0.65em
    float: right
    border-radius: 3px
    background: #347084 0% 0% no-repeat padding-box
    height: 45px
    width: 170px
    padding: 0 20px
    border: 1px solid #5e6367
    font: normal normal normal 13px/15px Roboto
    letter-spacing: 0.2px
    color: #ffffff

    line-height: 45px
    box-sizing: border-box
    margin-right: 5px
    margin-top: 10px

#container
    display: flex
    justify-content: space-between
    padding-top: 0em
    padding-left: 2em
.container-m
    padding-top: 1.5em
.container-r
    padding-top: 1.5em
#wv
    text-align: center
    margin-top: 6em

.watchdemo-button
    background-color: #DFE7FD
    border: none
    color: #8289A5
    padding: 12px 16px
    font-size: 12px
    cursor: pointer
    width: 17em
    border-radius: 32px
    &:hover
        background-color: #cad1e5
    i
        color: #A196FF
.point-cursor
    cursor: pointer
.select-pf
    background: #347084
