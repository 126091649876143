.top__nav {
  // position: fixed !important;
  // top: 0;
  // left: 0;
  // right: 0;
}

.nav__button {
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 18px;
  color: #6c757d;

  &:hover {
    color: #347084;
  }

  &--size-36 {
    width: 36px;
    height: 36px;
  }
}

.top__nav__noti__btn__area {
  ul {
    li {
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.btn__notify {
  .icon {
    position: relative;
  }

  span.dot {
    width: 6px;
    height: 6px;
    top: 6px;
    right: 0;
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: #FF5E5E;
  }
}
