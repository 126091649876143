.error__page {
  min-height: calc(100vh - var(--nav-height));
  display: flex;
  align-items: center;
  justify-content: center;

  &.is__sub__page {
    min-height: 100%;
  }

  .message {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title {
      color: #245a6c;
      font-weight: 600;
      font-size: var(--h2);
      margin-bottom: 12px;
    }

    .desc {
      color: rgba(36, 90, 108, 0.8);
      font-weight: 400;
      font-size: var(--h4);

      p {
        margin: 0;
      }
    }
  }
}
