.tab__base {
  &__header {
    display: block;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;

      li {
        font-size: var(--h5);

        &:not(.is-disabled) {
          cursor: pointer;
        }
      }
    }
  }

  &__content {
    display: block;
  }
}
