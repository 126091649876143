/* Dialog */
/* Close Button */
.tag-dialog {
	background: #fff;
	border: 2px solid #ccc;
	border-radius: 5px;
	float: left;
	height: 50px;
	margin: 20px;
	position: relative;
	width: 160px;
	background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
	border: 0.5px dashed var(--unnamed-color-4a7f91);
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 0.5px dashed #4A7F91;
	border-radius: 9px;
	opacity: 1;
	align-items: center;
	[class*='close-'] {
		color: #777;
		font: 14px/100% arial, sans-serif;
		position: absolute;
		right: 5px;
		text-decoration: none;
		text-shadow: 0 1px 0 #fff;
		top: 10px;
        cursor: pointer;
	}
	.close-edit {
		color: #777;
		font: 14px/100% arial, sans-serif;
		position: absolute;
		right: 35px;
		text-decoration: none;
		text-shadow: 0 1px 0 #fff;
		top: 10px;
        cursor: pointer;
		&:after {
			content: '';
		}
	}
	.content-span {
		color: #56B5BC;
		font: 14px/100% arial, sans-serif;
        font-weight: bold;
		position: absolute;
		float: left;
		text-decoration: none;
		text-shadow: 0 1px 0 #fff;
		top: 18px;
		left: 10px;
	}
	.close-classic {
		&:after {
			content: '';
		}
	}
}
