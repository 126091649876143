.e-documenteditor .e-close::before {
    content: "\e825";
    font-family: "e-icons";
    font-size: 14px;
  }
  .e-documenteditor .e-de-op-search-icon::before {
    content: "\e275";
    font-family: "e-icons";
    font-size: 12px;
  }
  .e-documenteditor .e-arrow-up::before {
    content: "\e834";
    font-family: "e-icons";
    font-size: 12px;
  }
  .e-documenteditor .e-arrow-down::before {
    content: "\e83d";
    font-family: "e-icons";
    font-size: 12px;
  }
  .e-documenteditor .e-de-op .e-de-op-close-icon {
    height: 22px;
  }
  .e-documenteditor .e-de-op-close-icon::before {
    content: "\e7a7";
  }
  .e-documenteditor .e-de-op-search-close-icon::before {
    content: "\e7a7";
    font-family: "e-icons";
    font-size: 10px;
  }
  .e-documenteditor .e-de-new-cmt::before {
    content: "\e7d5";
    font-family: "e-icons";
  }
  .e-documenteditor .e-de-menu-icon::before {
    content: "\e976";
    font-family: "e-icons";
    font-size: 12px;
  }
  .e-documenteditor .e-de-cmt-mark-icon::before {
    content: "\e817";
    font-family: "e-icons";
    font-size: 13px;
  }
  .e-documenteditor .e-de-cmt-post::before {
    content: "\e816";
    font-family: "e-icons";
  }
  .e-documenteditor .e-de-cmt-rply-icon::before {
    content: "\e815";
    font-family: "e-icons";
  }
  .e-documenteditor .e-de-cmt-cancel::before {
    content: "\e7a7";
    font-family: "e-icons";
  }
  .e-documenteditor .e-de-cmt-delete::before {
    content: "\e602";
    font-family: "e-icons";
  }
  .e-documenteditor .e-de-cmt-reopen::before {
    content: "\e818";
    font-family: "e-icons";
  }
  .e-documenteditor .e-de-nav-up::before {
    content: "\e651";
    font-family: "e-icons";
  }
  .e-documenteditor .e-de-nav-right-arrow::before {
    content: "\e830";
  }
  .e-documenteditor .e-de-nav-left-arrow::before {
    content: "\e829";
  }
  .e-documenteditor .e-de-save-icon::before {
    content: "\e614";
    font-family: "e-icons";
  }
  .e-documenteditor .e-de-cancel-icon::before {
    content: "\ebe8";
    font-family: "e-icons";
  }
  
  .e-de-ctn-title-print::before {
    content: "\ebf9";
    font-family: "e-icons";
  }
  
  .e-de-ctn-title-download::before {
    content: "\e603";
    font-family: "e-icons";
  }
  
  .e-de-acceptchange::before {
    content: "\e19f";
    font-family: "e-icons";
  }
  
  .e-de-rejectchange::before {
    content: "\e204";
    font-family: "e-icons";
  }
  
  .e-de-table-properties-alignment:hover {
    border-color: #0078d6;
  }
  
  .e-de-table-properties-alignment {
    border: 1px solid transparent;
  }
  
  .e-de-tablecell-alignment {
    border: 1px solid transparent;
  }
  
  .e-de-tablecell-alignment:hover {
    border-color: #0078d6;
  }
  
  .e-de-table-left-alignment::before {
    content: "\e517";
    font-size: 46px;
  }
  
  .e-de-table-center-alignment::before {
    content: "\e518";
    font-size: 46px;
  }
  
  .e-de-table-right-alignment::before {
    content: "\e515";
    font-size: 46px;
  }
  
  .e-de-tablecell-top-alignment::before {
    content: "\e527";
    font-size: 51px;
  }
  
  .e-de-tablecell-center-alignment::before {
    content: "\e526";
    font-size: 51px;
  }
  
  .e-de-tablecell-bottom-alignment::before {
    content: "\e525";
    font-size: 51px;
  }
  
  .e-de-table-border-setting {
    border: 1px solid #333;
    height: 40px;
    left: 5px;
    position: relative;
    top: 5px;
    width: 40px;
  }
  
  .e-de-table-border-setting-genral {
    border: 1px solid rgba(0, 0, 0, 0.26);
    height: 52px;
    width: 52px;
  }
  
  .e-de-table-border-preview-genral {
    border: 1px solid rgba(0, 0, 0, 0.26);
    height: 24px;
    width: 24px;
  }
  
  .e-de-table-border-inside-setting:hover {
    border: 1px solid #0078d6;
  }
  
  .e-de-table-border-preview {
    height: 24px;
    width: 24px;
  }
  
  .e-de-table-border-inside-preview:hover {
    border: 1px solid #0078d6;
  }
  
  .e-de-table-border-inside-setting-click {
    border: 1px solid #0078d6;
  }
  
  .e-de-table-border-inside-preview-click {
    border: 1px solid #0078d6;
  }
  
  .e-de-table-border-none-setting::before {
    content: "\e507";
    font-size: 38px;
    position: absolute;
  }
  
  .e-de-table-border-box-setting::before {
    content: "\e509";
    font-size: 34px;
    left: 2px;
    position: absolute;
    top: 2px;
  }
  
  .e-de-table-border-all-setting::before {
    content: "\e511";
    font-size: 34px;
    left: 2px;
    position: absolute;
    top: 2px;
  }
  
  .e-de-table-border-custom-setting::before {
    content: "\e516";
    font-size: 34px;
    left: 2px;
    position: absolute;
    top: 2px;
  }
  
  .e-de-para-border-none-setting::before {
    content: "\e93f";
    font-size: 38px;
    position: absolute;
  }
  
  .e-de-para-border-box-setting::before {
    content: "\e93b";
    font-size: 34px;
    left: 2px;
    position: absolute;
    top: 2px;
  }
  
  .e-de-para-border-shadow-setting::before {
    content: "\e93c";
    font-size: 34px;
    left: 2px;
    position: absolute;
    top: 2px;
  }
  
  .e-de-para-border-custom-setting::before {
    content: "\e939";
    font-size: 34px;
    left: 2px;
    position: absolute;
    top: 2px;
  }
  
  .e-de-table-border-toptop-alignment::before {
    content: "\e281";
    font-size: 16px;
    left: 5px;
    position: absolute;
    top: -2px;
  }
  
  .e-de-table-border-topcenter-alignment::before {
    content: "\e276";
    font-size: 16px;
    left: 5px;
    position: absolute;
    top: -2px;
  }
  
  .e-de-table-border-topbottom-alignment::before {
    content: "\e298";
    font-size: 16px;
    left: 5px;
    position: absolute;
    top: -2px;
  }
  
  .e-de-table-border-diagionalup-alignment::before {
    content: "\e262";
    font-size: 16px;
    left: 5px;
    position: absolute;
    top: -2px;
  }
  
  .e-de-table-border-diagionaldown-alignment::before {
    content: "\e265";
    font-size: 16px;
    left: 5px;
    position: absolute;
    top: -2px;
  }
  
  .e-de-table-border-bottomleft-alignment::before {
    content: "\e291";
    font-size: 16px;
    left: 5px;
    position: absolute;
    top: -2px;
  }
  
  .e-de-table-border-bottomcenter-alignment::before {
    content: "\e287";
    font-size: 16px;
    left: 5px;
    position: absolute;
    top: -2px;
  }
  
  .e-de-table-border-bottomright-alignment::before {
    content: "\e288";
    font-size: 16px;
    left: 5px;
    position: absolute;
    top: -2px;
  }
  
  .e-menu-item .e-de-cmt-add::before {
    content: "\e814";
    font-family: "e-icons";
  }
  
  .e-menu-item .e-de-cut::before {
    content: "\e279";
  }
  
  .e-menu-item .e-de-spellcheck::before {
    content: "\e689";
  }
  
  .e-menu-item .e-de-copy::before {
    content: "\e280";
  }
  
  .e-menu-item .e-de-paste::before {
    content: "\e501";
  }
  
  .e-menu-item .e-de-continue-numbering::before {
    content: "\e503";
  }
  
  .e-menu-item .e-de-restart-at::before {
    content: "\e277";
  }
  
  .e-menu-item .e-de-insertlink::before {
    content: "\e290";
  }
  
  .e-menu-item .e-de-open-hyperlink::before {
    content: "\e278";
  }
  
  .e-menu-item .e-de-copy-hyperlink::before {
    content: "\e295";
  }
  
  .e-menu-item .e-de-open-properties::before {
    content: "\e605";
  }
  
  .e-menu-item .e-de-edit-hyperlink::before {
    content: "\e289";
  }
  
  .e-menu-item .e-de-remove-hyperlink::before {
    content: "\e286";
  }
  
  .e-menu-item .e-de-fonts::before {
    content: "\e273";
  }
  
  .e-menu-item .e-de-paragraph::before {
    content: "\e75e";
  }
  
  .e-menu-item .e-de-table::before {
    content: "\e294";
  }
  
  .e-menu-item .e-de-insertabove::before {
    content: "\e506";
  }
  
  .e-menu-item .e-de-insertbelow::before {
    content: "\e505";
  }
  
  .e-menu-item .e-de-insertleft::before {
    content: "\e285";
  }
  
  .e-menu-item .e-de-insertright::before {
    content: "\e284";
  }
  
  .e-menu-item .e-de-delete-table::before {
    content: "\e292";
  }
  
  .e-menu-item .e-de-deleterow::before {
    content: "\e283";
  }
  
  .e-menu-item .e-de-deletecolumn::before {
    content: "\e282";
  }
  
  .e-de-bold::before {
    content: "\e339";
    font-family: "e-icons";
  }
  
  .e-de-italic::before {
    content: "\e35a";
    font-family: "e-icons";
  }
  
  .e-de-underline::before {
    content: "\e343";
    font-family: "e-icons";
  }
  
  .e-de-indent::before {
    content: "\e35d";
    font-family: "e-icons";
  }
  
  .e-de-outdent::before {
    content: "\e33f";
    font-family: "e-icons";
  }
  
  .e-de-align-left::before {
    content: "\e33a";
    font-family: "e-icons";
  }
  
  .e-de-align-center::before {
    content: "\e35e";
    font-family: "e-icons";
  }
  
  .e-de-align-right::before {
    content: "\e34d";
    font-family: "e-icons";
  }
  
  .e-de-justify::before {
    content: "\e334";
    font-family: "e-icons";
  }
  
  .e-de-single-spacing::before {
    content: "\e520";
    font-family: "e-icons";
  }
  
  .e-de-double-spacing::before {
    content: "\e521";
    font-family: "e-icons";
  }
  
  .e-de-one-point-five-spacing::before {
    content: "\e522";
    font-family: "e-icons";
  }
  
  .e-de-before-spacing::before {
    content: "\e523";
    font-family: "e-icons";
  }
  
  .e-de-after-spacing::before {
    content: "\e274";
    font-family: "e-icons";
  }
  
  .e-de-icon-bullet-list-dot::before {
    content: "\e270";
    font-family: "e-icons";
    font-size: 42px;
  }
  
  .e-de-icon-bullet-list-circle::before {
    content: "\e254";
    font-family: "e-icons";
    font-size: 42px;
  }
  
  .e-de-icon-bullet-list-square::before {
    content: "\e271";
    font-family: "e-icons";
    font-size: 42px;
  }
  
  .e-de-icon-bullet-list-tick::before {
    content: "\e259";
    font-family: "e-icons";
    font-size: 42px;
  }
  
  .e-de-icon-bullet-list-flower::before {
    content: "\e267";
    font-family: "e-icons";
    font-size: 42px;
  }
  
  .e-de-icon-bullet-list-arrow::before {
    content: "\e253";
    font-family: "e-icons";
    font-size: 42px;
  }
  
  .e-de-icon-bullet-list-none::before {
    content: "\e256";
    font-family: "e-icons";
    font-size: 42px;
  }
  
  .e-de-icon-autofit::before {
    content: "\eba8";
    font-family: "e-icons";
  }
  
  .e-de-icon-fixed-columnwidth::before {
    content: "\eba9";
    font-family: "e-icons";
  }
  
  .e-de-icon-auto-fitwindow::before {
    content: "\ebaa";
    font-family: "e-icons";
  }
  
  .e-item .e-de-paste-text::before {
    content: "\e687";
  }
  
  .e-item .e-de-paste-source::before {
    content: "\e686";
  }
  
  .e-item .e-de-paste-merge::before {
    content: "\e688";
  }
  
  .e-item .e-de-paste-column::before {
    content: "\e91b";
  }
  
  .e-item .e-de-paste-row::before {
    content: "\e91c";
  }
  
  .e-item .e-de-paste-overwrite-cells::before {
    content: "\e91d";
  }
  
  .e-item .e-de-paste-nested-table::before {
    content: "\e91e";
  }
  
  .e-item .e-de-paste-merge-table::before {
    content: "\e91f";
  }
  
  .e-btn-icon .e-de-paste::before,
  .e-icon-btn .e-de-paste::before {
    content: "\e68a";
  }
  
  .e-de-dlg-container {
    padding-bottom: 12px;
  }
  
  .e-de-dlg-row {
    display: -ms-flexbox;
    display: flex;
  }
  
  .e-de-container-row {
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 12px;
  }
  
  .e-de-subcontainer-left {
    padding-right: 6px;
    width: 50%;
  }
  
  .e-de-subcontainer-right {
    padding-left: 6px;
    width: 50%;
  }
  
  .e-de-dlg-tab-first-child {
    padding-top: 6px;
  }
  
  .e-de-dlg-heading {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  
  .e-rtl .e-de-subcontainer-left {
    padding-left: 6px;
    padding-right: 0;
  }
  .e-rtl .e-de-subcontainer-right {
    padding-left: 0;
    padding-right: 6px;
    width: 50%;
  }
  
  .e-bigger .e-de-dlg-container {
    padding-bottom: 16px;
  }
  .e-bigger .e-de-container-row {
    padding-bottom: 16px;
  }
  .e-bigger .e-de-subcontainer-left {
    padding-right: 8px;
    width: 50%;
  }
  .e-bigger .e-de-subcontainer-right {
    padding-left: 8px;
    width: 50%;
  }
  .e-bigger .e-de-dlg-tab-first-child {
    padding-top: 8px;
  }
  .e-bigger .e-rtl .e-de-subcontainer-left {
    padding-left: 8px;
    padding-right: 0;
  }
  .e-bigger .e-rtl .e-de-subcontainer-right {
    padding-left: 0;
    padding-right: 8px;
    width: 50%;
  }
  
  .e-de-blink-cursor {
    border-left: 1px solid #000;
    pointer-events: none;
    position: absolute;
    z-index: 3;
  }
  
  .e-de-cursor-animation {
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-name: FadeInFadeOut;
  }
  
  @keyframes FadeInFadeOut {
    from {
      opacity: 1;
    }
    13% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    63% {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  .e-de-text-target {
    border: 0;
    height: 1px;
    opacity: 0;
    outline-style: none;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: -10000px;
    width: 625px;
  }
  
  .e-de-txt-form .e-de-txt-field {
    display: block;
  }
  .e-de-txt-form .e-de-num-field {
    display: none;
  }
  .e-de-txt-form .e-de-date-field {
    display: none;
  }
  .e-de-txt-form .e-de-ddl-field {
    display: none;
  }
  
  .e-de-num-form .e-de-txt-field {
    display: none;
  }
  .e-de-num-form .e-de-num-field {
    display: block;
  }
  .e-de-num-form .e-de-date-field {
    display: none;
  }
  .e-de-num-form .e-de-ddl-field {
    display: none;
  }
  
  .e-de-date-form .e-de-txt-field {
    display: none;
  }
  .e-de-date-form .e-de-num-field {
    display: none;
  }
  .e-de-date-form .e-de-date-field {
    display: block;
  }
  .e-de-date-form .e-de-ddl-field {
    display: none;
  }
  
  .e-de-ddl-form .e-de-txt-field {
    display: none;
  }
  .e-de-ddl-form .e-de-num-field {
    display: none;
  }
  .e-de-ddl-form .e-de-date-field {
    display: none;
  }
  .e-de-ddl-form .e-de-ddl-field {
    display: block;
  }
  
  .e-documenteditor .e-de-op-close-button {
    left: 267px;
    position: absolute;
    top: 8px;
  }
  .e-documenteditor .e-de-op-close-button.e-de-rtl {
    left: 14px;
  }
  
  .e-de-background {
    background-color: #E6ECEE;
    
  }
  
  .e-de-ff-sub-header {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-top: 6px;
  }
  
  .e-de-check-exactnumbr-width {
    width: 110px !important;
  }
  
  .e-de-ff-dlg-heading {
    display: block;
    font-weight: 600;
    margin-bottom: 12px;
  }
  
  .e-de-ff-dlg-heading-small {
    display: block;
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .e-de-ff-dlg-drpdwn-heading {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .e-de-ff-dlg-check {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: -2px;
  }
  
  .e-de-div-seperate-dlg {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 15.5px;
    width: 100%;
  }
  
  .e-de-ff-radio-div {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 16px;
  }
  
  .e-de-ff-radio-div.e-de-rtl {
    margin-left: 16px;
    margin-right: 0;
  }
  
  .e-de-ff-radio-scnd-div {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-bottom: 18px;
    margin-right: 16px;
  }
  
  .e-de-ff-radio-scnd-div.e-de-rtl {
    margin-left: 16px;
    margin-right: 0;
  }
  
  .e-de-ff-dlg-lft-hlf {
    margin-right: 5%;
    width: 47.5%;
  }
  
  .e-de-ff-dlg-lft-hlf.e-de-rtl {
    margin-left: 5%;
    margin-right: 0;
  }
  
  .e-de-ff-chck-exact {
    margin-left: 15px;
    margin-top: -6px;
  }
  
  .e-de-ff-chck-exact.e-de-rtl {
    margin-left: 0;
    margin-right: 15px;
  }
  
  .e-de-ff-dlg-rght-hlf {
    width: 47.5%;
  }
  
  .e-de-ff-drpdwn-dlg-scndiv {
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 168px;
    margin-bottom: 16px;
    width: 100%;
  }
  
  .e-de-cmt-avatar {
    -ms-flex-align: center;
        align-items: center;
    display: -ms-flexbox;
    display: flex;
    width: 90%;
  }
  
  .e-de-ff-cmt-avatar {
    -ms-flex-align: center;
        align-items: center;
    border-radius: 50%;
    color: #fff;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    height: 24px;
    -ms-flex-pack: center;
        justify-content: center;
    letter-spacing: 0;
    line-height: 12px;
    opacity: 100%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 24px;
    z-index: 1;
  }
  
  .e-de-ff-drpdwn-listview {
    margin-right: 12px;
    width: 100%;
  }
  
  .e-de-ff-drpdwn-listview.e-de-rtl {
    margin-left: 12px;
    margin-right: 0;
  }
  
  .e-de-ff-drpdwn-mvup {
    margin-right: 8px;
  }
  
  .e-de-ff-drpdwn-mvup.e-de-rtl {
    margin-left: 8px;
    margin-right: 0;
  }
  
  .e-de-drp-dwn-frst-div {
    margin-bottom: 23.5px;
    width: 47.5%;
  }
  
  .e-de-result-list-block .e-de-search-result-hglt {
    background: transparent;
    border-bottom: 2px solid #b7e0ff;
    cursor: default;
    padding: 15px 1px 15px 5px;
  }
  
  .e-de-result-list-block .e-de-op-search-txt .e-de-op-search-word-text {
    color: #0081e5;
  }
  
  .e-de-search-result-item {
    cursor: default;
    padding: 15px 1px 15px 5px;
    word-break: break-word;
  }
  
  .e-de-search-result-item:hover {
    border-bottom: 1px solid #b7e0ff;
    cursor: default;
  }
  
  .e-de-search-result-item:focus {
    border-bottom: 2px solid #b7e0ff;
    cursor: default;
    padding: 15px 1px 15px 5px;
  }
  
  .e-de-search-tab-content .e-input-group .e-de-op-search-icon:focus {
    border: 1px solid #ddd;
  }
  
  .e-de-op-search-icon:hover {
    background: #ddd;
  }
  
  .e-de-search-tab-content .e-input-group .e-de-op-search-close-icon:focus {
    border: 1px solid #ddd;
    border-right-width: 0;
  }
  
  .e-de-op-search-close-icon:hover {
    background: #ddd;
  }
  
  .e-spin-down:focus {
    border: 1px solid #ddd;
    border-right-width: 0;
  }
  
  .e-spin-down:hover {
    background: #ddd;
  }
  
  .e-spin-up:focus {
    border: 1px solid #ddd;
    border-right-width: 0;
  }
  
  .e-spin-up:hover {
    background: #ddd;
  }
  
  .e-de-toc-dlg-heading {
    color: #333;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  
  .e-de-toc-dlg-main-heading {
    color: #333;
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .e-content-placeholder.e-documenteditor.e-placeholder-documenteditor {
    background-size: 100%;
  }
  
  .e-de-toc-reset-button {
    margin-top: 10px;
  }
  
  .e-de-toc-reset-button.e-de-rtl {
    margin-right: 0;
  }
  
  .e-de-toc-modify-button {
    margin-left: 150px;
    margin-top: 10px;
  }
  
  .e-de-toc-modify-button.e-de-rtl {
    margin-left: 0;
    margin-right: 150px;
  }
  
  .e-de-toc-dlg-container {
    height: 464px;
    width: 550px;
  }
  
  .e-de-toc-dlg-sub-container {
    margin-bottom: 10px;
  }
  
  .e-de-toc-list-view {
    border: 1px solid #c8c8c8;
    border-radius: 1px;
    font-size: 12px;
    height: 200px;
    overflow-y: scroll;
  }
  
  .e-de-toc-list-view.e-de-rtl {
    margin-left: 0;
  }
  
  .e-de-toc-dlg-sub-heading {
    color: #333;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin: 10px 15px 10px 15px;
  }
  
  .e-de-toc-dlg-style-label {
    margin-left: 38px;
    margin-top: 42px;
  }
  
  .e-de-toc-dlg-style-label .e-de-rtl {
    margin-left: 0;
    margin-right: 38px;
  }
  
  .e-de-pagesetup-dlg-container {
    height: 265px;
    width: 354px;
  }
  
  .e-de-page-setup-ppty-tab {
    border: 0;
  }
  
  .e-de-page-setup-dlg-sub-container {
    margin-bottom: 22px;
  }
  
  .e-de-page-setup-dlg-left-sub-container {
    float: left;
    position: relative;
    top: 17px;
  }
  
  .e-de-page-setup-dlg-left-sub-container.e-de-rtl {
    float: right;
  }
  
  .e-de-page-setup-dlg-right-sub-container {
    float: right;
    position: relative;
    top: 17px;
  }
  
  .e-de-page-setup-dlg-right-sub-container.e-de-rtl {
    float: left;
  }
  
  .e-de-page-setup-dlg-sub-header {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 4px;
    margin-top: 3px;
  }
  
  .e-de-page-setup-dlg-sub-title-header {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 4px;
    margin-top: 12px;
  }
  
  .e-de-page-setup-dlg-sub-container-port {
    height: 55px;
    margin-bottom: 22px;
  }
  
  .e-de-page-setup-dlg-sub-label {
    font-size: 13px;
    font-weight: 500;
  }
  
  .e-de-page-setup-dlg-orientation-prop {
    margin-top: 13px;
  }
  
  .e-de-page-setup-dlg-sub-size-container {
    height: 65px;
    margin-bottom: 25px;
  }
  
  .e-de-page-setup-dlg-layout-sub-container {
    height: 55px;
    margin-bottom: 25px;
    position: relative;
    top: 17px;
  }
  
  .e-de-page-setup-dlg-first-page-prop {
    margin-bottom: 8px;
  }
  
  .e-de-page-setup-dlg-first-page-prop .e-label,
  .e-de-page-setup-dlg-odd-or-even-prop .e-label {
    font-size: 12px;
  }
  
  .e-de-page-setup-dlg-first-page-prop .e-frame,
  .e-de-page-setup-dlg-odd-or-even-prop .e-frame {
    height: 20px;
    line-height: 16px;
    width: 20px;
  }
  
  .e-de-page-setup-dlg-left-layout-container {
    float: left;
    position: relative;
    top: 8px;
  }
  
  .e-de-page-setup-dlg-left-layout-container.e-de-rtl {
    float: right;
  }
  
  .e-de-page-setup-dlg-right-layout-container {
    float: right;
    position: relative;
    top: 8px;
  }
  
  .e-de-page-setup-dlg-right-layout-container.e-de-rtl {
    float: left;
  }
  
  .e-de-dlg-footer .e-btn {
    margin-left: 10px;
  }
  
  .e-de-hyperlink-dlg-title {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  
  .e-de-hyperlink .e-de-hyperlink-dlg-input {
    height: 32px;
    margin-bottom: 7px;
    width: 230px;
  }
  
  .e-de-font-dlg-header {
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
  }
  
  .e-de-font-dlg-header-effects,
  .e-de-font-dlg-header-font-color {
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
  }
  
  .e-de-font-dlg-main-header {
    color: #333;
    font-size: 14px;
    font-weight: normal;
    margin-right: 20px;
  }
  
  .e-de-font-dlg-cb-right {
    margin-left: 20px;
  }
  
  .e-de-font-dlg-cb-right.e-de-rtl {
    margin-left: 0;
    margin-right: 20px;
  }
  
  .e-de-font-dlg-cb-right-div {
    margin-left: 20px;
  }
  
  .e-de-dropdown {
    margin-right: 20px;
  }
  
  .e-de-restrict-pane {
    border-right: 1px solid #eaeaea;
    padding-left: 16px;
    padding-top: 20px;
    padding-right: 16px;
    position: relative;
    width: 300px;
  }
  
  .e-de-op {
    border-right: 1px solid #eaeaea;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    width: 300px;
  }
  
  .e-de-op.e-de-rtl {
    padding-left: 0;
    padding-right: 16px;
  }
  
  .e-de-op-header {
    color: #333;
    font-family: "Segoe UI";
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 12px;
    padding-top: 12px;
  }
  
  .e-de-op-header.e-de-rtl {
    direction: rtl;
    text-align: right;
  }
  
  .e-de-op-tab {
    border: 0;
    height: auto;
  }
  
  .e-de-op-icon {
    color: rgba(0, 0, 0, 0.54);
    height: 20px;
    width: 20px;
  }
  
  .e-de-op-close-icon {
    color: #333;
  }
  
  .e-de-op-nav-btn {
    height: 22px;
    width: 22px;
  }
  
  .e-de-op-search-txt {
    border-bottom: 1px solid #ddd;
    color: #333;
    font-size: 14px;
  }
  
  .e-de-op-search-txt .e-de-op-search-word {
    color: #0081e5;
  }
  
  .e-de-op-more-less {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-top: 14px;
  }
  
  .e-de-op-replacetabcontentdiv {
    margin-top: 14px;
  }
  
  label[for*=_wholeWord_e-de-ltr] {
    left: 35px;
  }
  
  label[for*=_wholeWord_e-de-rtl] {
    right: 35px;
  }
  
  .e-de-cell-dia-label-common {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 150px;
  }
  
  .e-de-cell-dia-options-label {
    font-weight: 600;
  }
  
  .e-de-table-border-heading {
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 20px;
  }
  
  .e-de-table-setting-heading {
    font-size: 14px;
    font-weight: normal;
    padding-bottom: 20px;
  }
  
  .e-de-layout-setting-heading {
    font-size: 14px;
    font-weight: normal;
    padding-bottom: 20px;
  }
  
  .e-de-table-setting-labels-heading {
    font-size: 12px;
    font-weight: normal;
    margin-left: 10px;
  }
  
  .e-de-table-element-subheading {
    font-size: 12px;
    font-weight: normal;
  }
  
  .e-de-border-dlg-preview-div {
    border: 1px solid rgba(0, 0, 0, 0.54);
    width: 80px;
    height: 80px;
  }
  
  .e-de-border-dlg-preview-inside-divs {
    opacity: 0.54;
  }
  
  .e-de-tablecell-dia-align-div {
    border: 1px solid #ddd;
    display: inline-block;
    height: 60px;
    margin-right: 10px;
    width: 60px;
  }
  
  .e-de-tablecell-dia-align-div.e-de-rtl {
    margin-left: 10px;
    margin-right: 0;
  }
  
  .e-de-table-dia-align-label {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    margin-left: 10px;
    margin-top: 8px;
  }
  
  .e-de-table-dialog-separator-line {
    background-color: #eaeaea;
    bottom: 62px;
    display: none;
    height: 1px;
    left: 1px;
    margin-top: 5px;
    position: absolute;
    width: 100%;
  }
  
  .e-de-table-alignment-active {
    border: 1px solid #0078d6;
  }
  
  .e-de-table-dialog-size-label {
    padding-top: 10px !important;
  }
  
  .e-de-list-ddl-header {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  
  .e-de-list-ddl-header-list-level {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 15px;
  }
  
  .e-de-tbl-dlg-footer {
    padding-top: 23px;
  }
  
  .e-de-row-ht-top,
  .e-de-cell-ht-top {
    padding: 0 20px;
    width: 160px;
  }
  
  .e-de-ht-wdth-type {
    margin-top: -24px;
    width: 120px;
  }
  
  .e-de-row-ht-top.e-de-rtl,
  .e-de-cell-ht-top.e-de-rtl {
    margin-left: 0;
    margin-right: 20px;
  }
  
  .e-de-cell-width-top {
    margin-left: 20px;
    margin-top: -6px;
  }
  
  .e-de-cell-width-top.e-de-rtl {
    margin-left: 0;
    margin-right: 20px;
  }
  
  .e-de-tbl-dlg-border-btn {
    float: right;
    margin-top: 20px;
  }
  
  .e-de-tbl-dlg-border-btn.e-de-rtl {
    float: left;
    margin-right: 0;
  }
  
  .e-de-table-border-setting.e-de-rtl {
    right: 5px;
  }
  
  .e-de-tbl-dlg-op-btn {
    left: 410px;
    position: absolute;
    top: 275px;
  }
  
  .e-de-insert-table-dlg-sub-header {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  
  .e-de-insert-footnote-dlg-sub-header,
  .e-de-insert-footnote-dlg-header {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  
  .e-de-insert-footnote-dlg-header {
    margin-bottom: 25px;
  }
  
  .e-de-insert-table-dlg-input {
    margin-bottom: 14px;
  }
  
  .e-de-list-ddl-subheader,
  .e-de-list-ddl-subheaderbottom {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .e-de-list-dlg-subdiv {
    float: right;
    margin-top: 43px;
    position: relative;
  }
  
  .e-de-list-dlg-subdiv.e-de-rtl {
    float: left;
    margin-top: -137px;
  }
  
  .e-de-list-dlg-div {
    float: right;
    margin-top: 41px;
    position: relative;
  }
  
  .e-de-list-dlg-div.e-de-rtl {
    float: left;
    margin-top: -139px;
  }
  
  .e-de-ok-button {
    margin-right: 8px;
  }
  
  .e-de-ok-button.e-de-rtl {
    margin-left: 8px;
  }
  
  .e-de-options-setter {
    left: 310px;
  }
  
  .e-de-op-close-icon:hover {
    color: #00457a;
  }
  
  .e-de-tooltip {
    background-color: #fff;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.26);
    color: #000;
    cursor: text;
    display: table;
    max-width: 200px;
    padding: 5px;
    word-wrap: break-word;
  }
  
  .e-de-form-popup {
    background-color: #fff;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.26);
    color: #000;
    cursor: text;
    max-width: 350px;
    min-width: 300px;
    padding: 16px;
    position: absolute;
    width: fit-content;
    word-wrap: break-word;
  }
  
  .e-de-save,
  .e-de-cancel {
    margin-left: 8px;
  }
  
  .e-btn.e-de-op-icon-btn {
    background-color: transparent;
    border-color: transparent;
  }
  
  .e-documenteditor .e-de-op-close-button {
    left: 250px;
    position: absolute;
    top: 8px;
  }
  
  .e-de-restrict-pane {
    color: #000;
  }
  
  .e-de-op.e-de-rtl .e-de-search-tab-content {
    margin-left: 12px;
    margin-right: 0;
  }
  
  .e-documenteditor .e-de-op-close-button.e-de-rtl {
    right: 255px;
  }
  
  .e-de-table-measure-lbl {
    font-size: 11px;
    font-weight: normal;
    display: block;
    margin-bottom: 8px;
  }
  
  .e-de-tbl-indent-lbl {
    font-weight: normal;
    font-size: 11px;
    position: relative;
    display: block;
    margin-bottom: 18px;
    top: 10px;
  }
  
  .e-btn.e-de-op-close-button:hover {
    background-color: transparent;
    border-color: transparent;
    color: #00457a;
  }
  
  .e-btn.e-de-op-close-button:focus {
    background-color: transparent;
    border-color: transparent;
    color: #00457a;
  }
  
  .e-btn.e-de-op-close-button:active {
    background-color: transparent;
    border-color: transparent;
    color: #eaeaea;
  }
  
  .e-documenteditor .e-input {
    font-size: 14px;
  }
  
  .e-de-dlg-target .e-footer-content .e-control.e-btn.e-flat:not(.e-icon-btn) {
    height: 32px;
  }
  
  .e-de-tbl-dlg-border-btn .e-control.e-btn.e-flat:not(.e-icon-btn) {
    height: auto;
  }
  
  .e-de-op-result-container {
    margin-top: 12px;
  }
  
  .e-de-restrict-pane,
  .e-de-op {
    background-color: #fff;
  }
  
  .e-de-restrict-pane .e-tab-header .e-toolbar-items,
  .e-de-op .e-tab-header .e-toolbar-items {
    margin-bottom: 28px;
    margin-top: 1px;
  }
  
  .e-de-font-dlg-color {
    border: 1px #c8c8c8;
    border-radius: 1px;
    font-size: 12px;
    height: 16px;
    margin-left: 15px;
    width: 25px;
  }
  
  .e-de-icon-table-row-above {
    top: 10px;
  }
  
  .e-de-icon-table-row-below {
    top: 49px;
  }
  
  .e-de-icon-table-column-left {
    top: 89px;
  }
  
  .e-de-icon-table-column-right {
    top: 127px;
  }
  
  .e-de-icon-table-delete {
    top: 10px;
  }
  
  .e-de-icon-table-row-delete {
    top: 49px;
  }
  
  .e-de-icon-table-column-delete {
    top: 89px;
  }
  
  .e-de-list-bullet-none {
    height: 40px;
    width: 40px;
  }
  
  .e-de-list-bullet-dot {
    height: 40px;
    width: 40px;
  }
  
  .e-de-list-bullet-circle {
    height: 40px;
    width: 40px;
  }
  
  .e-de-list-bullet-square {
    height: 40px;
    width: 40px;
  }
  
  .e-de-list-bullet-flower {
    height: 40px;
    width: 40px;
  }
  
  .e-de-list-bullet-arrow {
    height: 40px;
    width: 40px;
  }
  
  .e-de-list-bullet-tick {
    height: 40px;
    width: 40px;
  }
  
  .e-de-bullet:hover {
    background: rgba(0, 0, 0, 0.12);
  }
  
  .e-de-list-numbered-none {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-numbered-number-dot {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-numbered-number-brace {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-numbered-up-roman {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-numbered-up-letter {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-numbered-low-letter-brace {
    height: 80px;
    width: 80px;
  }
  
  .e-de-numbered-low-letter-dot {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-numbered-low-roman {
    height: 80px;
    width: 80px;
  }
  
  .e-de-numbered:hover {
    background: rgba(0, 0, 0, 0.12);
  }
  
  .e-de-list-multilevel-none {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-multilevel-list-normal {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-multilevel-list-multilevel {
    height: 80px;
    width: 80px;
  }
  
  .e-de-list-multilevel-list-bullets {
    height: 80px;
    width: 80px;
  }
  
  .e-de-multilevel-list:hover {
    background: rgba(0, 0, 0, 0.12);
  }
  
  .e-de-list-dialog-open:hover {
    background: rgba(0, 0, 0, 0.12);
  }
  
  .e-de-cell-options {
    left: 313px;
    top: 303px;
  }
  
  .e-de-cell-options.e-de-rtl {
    left: 127px;
  }
  
  .e-de-font-color-label {
    margin-bottom: 5px;
    margin-right: 10px;
    margin-top: 10px;
  }
  
  .e-de-font-content-label {
    width: 130px;
  }
  
  .e-de-font-color-margin {
    margin-right: 10px;
    margin-top: 8px;
  }
  
  .e-de-font-color-margin.e-de-rtl {
    margin-left: 10px;
    margin-right: 0;
  }
  
  .e-de-font-content-checkbox-label {
    margin-left: 46px;
  }
  
  .e-de-font-content-checkbox-label-rtl {
    margin-right: 55px;
  }
  
  .e-bigger .e-de-font-content-checkbox-label {
    margin-left: 48px;
  }
  .e-bigger .e-de-font-content-checkbox-label-rtl {
    margin-right: 48px;
  }
  .e-bigger .e-de-font-content-label {
    width: 130px;
  }
  
  .e-de-font-checkbox {
    margin-top: 10px;
  }
  
  .e-de-font-checkbox-transform {
    margin-left: 58px;
  }
  
  .e-de-font-checkbox-transform.e-de-rtl {
    margin-left: 0;
    margin-right: 58px;
  }
  
  .e-de-font-checkbox-transform-label {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  
  .e-de-font-checkbox.e-de-rtl {
    margin-left: 0;
    margin-right: 58px;
  }
  
  .e-de-font-checkbox.e-de-rtl {
    margin-left: 0;
    margin-right: 60px;
  }
  
  .e-de-font-clr-div {
    margin-top: 14px;
  }
  
  .e-de-font-dlg-padding {
    margin-top: 15px;
  }
  
  .e-de-table-container-div {
    margin-top: 15px;
  }
  
  .e-de-table-header-div {
    padding-top: 3px;
  }
  
  .e-de-table-subheader-div {
    float: right;
    margin-right: 135px;
    margin-top: -37px;
  }
  
  .e-de-table-subheader-div.e-de-rtl {
    float: left;
    margin-left: 135px;
    margin-right: 0;
  }
  
  .e-de-table-cell-header-div {
    padding-top: 3px;
  }
  
  .e-de-table-cell-subheader-div {
    top: 61px;
  }
  
  .e-de-cell-margin-header {
    left: -7px;
    top: 319px;
  }
  
  .e-de-font-dlg-display {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  
  .e-de-tbl-margin-sub-header {
    margin-top: 5px;
  }
  
  .e-de-tbl-btn-separator {
    width: 62%;
  }
  
  .e-de-op-msg {
    color: #000;
    top: 79px;
  }
  
  .e-de-save-dlg-file-name {
    height: 25px;
    margin-bottom: 8px;
  }
  
  .e-de-save-dlg-format-type {
    height: 25px;
    margin-bottom: 8px;
    padding-top: 1px;
  }
  
  .e-de-search-tab-content {
    margin-top: 12px;
    width: 269px;
  }
  
  .e-de-font-dlg {
    width: max-content;
  }
  
  .e-de-hyperlink {
    width: 250px;
  }
  
  .e-bigger .e-de-hyperlink {
    width: 300px;
  }
  
  .e-de-insert-table {
    height: 130px;
    width: 230px;
  }
  
  .e-de-insert-footnote {
    height: 139px;
    width: 247px;
  }
  
  .e-de-insert-spellchecker {
    height: 325px;
    width: 513px;
    font-weight: normal;
  }
  
  .e-bigger .e-de-insert-spellchecker {
    width: 600px;
  }
  
  .e-de-dlg-spellcheck-listview {
    border: 1px solid #e4e4e4;
    border-radius: 2px !important;
    height: 122px !important;
    margin-right: 20px;
    margin-top: 8px;
    position: relative;
    float: left;
    width: 100%;
  }
  
  .e-de-dlg-spellcheck-listview.e-de-rtl {
    float: right;
  }
  
  .e-de-spellcheck-error-container {
    height: 140px;
    margin-bottom: 25px;
    display: -ms-flexbox;
    display: flex;
  }
  
  .e-de-spellcheck-suggestion-container {
    height: 140px;
    margin-bottom: 25px;
    display: -ms-flexbox;
    display: flex;
  }
  
  .e-dlg-spellcheck-listitem {
    font-size: 15px !important;
  }
  
  .e-de-spellcheck-btncontainer {
    margin-top: 8px;
    position: relative;
    width: 100%;
    float: right;
  }
  
  .e-de-spellcheck-btncontainer.e-de-rtl {
    float: left;
  }
  
  .e-de-spellcheck-btn {
    margin-bottom: 8px;
    width: 100%;
  }
  
  .e-de-dlg-spellchecker-subheader {
    margin-top: 8px;
    font-size: 15px;
  }
  
  .e-de-dlg-spellchecker-subheaderbtm {
    font-size: 15px;
  }
  
  .e-de-list-dlg {
    height: 400px;
    width: 400px;
  }
  
  .e-de-save-dlg {
    height: 135px;
    width: 230px;
  }
  
  .e-de-table-properties-dlg {
    width: 406px;
  }
  
  .e-de-table-border-shading-dlg {
    width: 450px;
  }
  
  .e-de-table-cell-margin-dlg {
    height: 210px;
    width: 380px;
  }
  
  .e-de-table-options-dlg {
    height: 256px;
    width: 380px;
  }
  
  .e-de-table-border-none {
    position: absolute;
    top: 25px;
  }
  
  .e-de-table-border-box {
    position: absolute;
    top: 95px;
  }
  
  .e-de-table-border-all {
    position: absolute;
    top: 165px;
  }
  
  .e-de-table-border-custom {
    position: absolute;
    top: 235px;
  }
  
  .e-de-table-shading-preview {
    top: 385px;
  }
  
  .e-de-font-label span.e-label {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .e-de-font-content-label:hover .e-label,
  .e-css.e-de-font-content-label:hover .e-label {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .e-de-font-label:hover .e-label,
  .e-css.e-de-font-label:hover .e-label {
    color: rgba(0, 0, 0, 0.87);
  }
  
  .e-de-op-dlg-footer {
    margin-top: 20px;
  }
  
  .e-de-op-dlg-footer .e-btn {
    padding-left: 6px;
    padding-right: 6px;
  }
  
  .e-de-search-tab-content .e-input-group .e-de-search-input {
    width: 175px;
  }
  
  .e-de-op-replacewith {
    width: 97%;
  }
  
  .e-de-table-ppty-tab {
    border: 0;
  }
  
  .e-de-list-format-info {
    border-radius: 50% !important;
    cursor: default;
    font-size: 12px !important;
    height: 15px;
    line-height: 1px;
    padding: 3px 0 0 0 !important;
    text-transform: lowercase;
    width: 16px;
  }
  
  .e-button-custom {
    height: 32px;
    width: 100%;
  }
  
  .e-bigger .e-button-custom {
    height: 37px;
    width: 100%;
  }
  
  .e-styles-listview,
  .e-bookmark-listview {
    border: 1px solid #c8c8c8;
    border-radius: 0;
    height: 150px;
    overflow-y: scroll;
  }
  
  .e-bookmark-gotobutton,
  .e-bookmark-addbutton,
  .e-styles-addbutton,
  .e-bookmark-deletebutton {
    margin-bottom: 12px;
  }
  
  .e-bookmark-list {
    float: left;
    margin-right: 20px;
    width: 250px;
  }
  
  .e-bookmark-list.e-de-rtl {
    margin-left: 20px;
    margin-right: 0;
  }
  
  .e-bookmark-textboxdiv {
    margin-bottom: 6px;
  }
  
  .e-bookmark-listview .e-list-item {
    font-size: 13px;
    height: 30px;
    line-height: 27px;
  }
  
  .e-bookmark-common {
    display: -ms-flexbox;
    display: flex;
  }
  
  .e-bookmark-button {
    position: relative;
    top: 0;
  }
  
  .e-font {
    float: left;
  }
  
  .e-font-rtl {
    float: right;
  }
  
  .e-de-table-border-toptop-alignment,
  .e-de-table-border-topcenter-alignment,
  .e-de-table-border-topbottom-alignment,
  .e-de-table-border-diagionalup-alignment,
  .e-de-table-border-diagionaldown-alignment,
  .e-de-table-border-bottomleft-alignment,
  .e-de-table-border-bottomcenter-alignment,
  .e-de-table-border-bottomright-alignment {
    left: 48%;
    position: relative;
    top: 75%;
    transform: translate(-50%, -50%);
  }
  
  .e-de-style-properties,
  .e-de-style-formatting {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .e-de-style-formatting {
    margin-bottom: 15px;
  }
  
  .e-de-style-paragraph-indent-group-button .e-btn,
  .e-de-style-paragraph-group-button .e-btn,
  .e-de-style-font-group-button .e-btn {
    box-shadow: none;
  }
  
  .e-de-table-options-dlg-div {
    height: auto;
    position: relative;
    margin-bottom: 14px;
    width: 514px;
  }
  
  .e-de-style-paragraph-indent-group-button .e-btn.e-active,
  .e-de-style-paragraph-group-button .e-btn.e-active,
  .e-de-style-font-group-button .e-btn.e-active {
    background-color: #c8c8c8;
    border-color: #c8c8c8;
    box-shadow: none;
    color: #000;
  }
  
  .e-de-style-properties {
    margin-bottom: 15px;
  }
  
  .e-de-style-nametype-div {
    margin-bottom: 15px;
  }
  
  .e-de-style-based-para-div {
    margin-bottom: 15px;
  }
  
  .e-de-style-name,
  .e-de-style-styletype,
  .e-de-style-style-based-on,
  .e-de-style-style-paragraph {
    font-weight: normal;
    margin-bottom: 8px;
    font-size: 13px;
    width: 180px;
  }
  
  .e-de-style-left-div {
    margin-right: 20px;
  }
  
  .e-de-style-left-div.e-de-rtl {
    margin-left: 20px;
    margin-right: 0;
  }
  
  .e-de-style-font-color-picker,
  .e-de-style-icon-button-size,
  .e-de-style-icon-button-first-size,
  .e-de-style-icon-button-last-size {
    height: 31px;
  }
  
  .e-bigger .e-de-style-font-color-picker,
  .e-bigger .e-de-style-icon-button-size,
  .e-bigger .e-de-style-icon-button-first-size,
  .e-bigger .e-de-style-icon-button-last-size {
    height: 35px;
  }
  .e-bigger .e-de-style-bold-button-size {
    margin-left: 8px;
    margin-right: 8px;
    height: 35px;
  }
  
  .e-de-style-bold-button-size {
    margin-left: 8px;
    margin-right: 8px;
    height: 31px;
  }
  
  .e-de-style-format-dropdwn .e-btn-icon {
    margin-left: 8px;
  }
  
  .e-de-style-font-color-picker,
  .e-de-style-icon-button-size {
    margin-right: 8px;
  }
  
  .e-de-style-icon-button-first-size {
    margin-left: 8px;
    margin-right: 3px;
  }
  
  .e-de-style-icon-button-last-size {
    margin-right: 8px;
  }
  
  .e-de-style-font-color-picker {
    margin-left: 8px;
  }
  
  .e-style-font-fmaily-right {
    margin-right: 8px;
  }
  
  .e-style-font {
    margin-left: 3px;
    margin-right: 20px;
  }
  
  .e-de-style-left-div .e-de-style-dlg-name-input {
    height: 32px;
  }
  
  .e-style-list {
    margin-left: 10px;
  }
  
  .e-de-style-dialog .e-de-style-only-this-document {
    margin-top: 25px;
  }
  
  .e-de-style-format-dropdwn {
    width: 135px;
  }
  
  .e-de-style-options-div,
  .e-de-style-options-div > div:first-child {
    margin-bottom: 20px !important;
  }
  
  .e-de-style-paragraph-group-button {
    border-right: 2px solid #e0e0e0;
  }
  
  .e-de-style-font-group-button {
    border-left: 2px solid #e0e0e0;
    border-right: 2px solid #e0e0e0;
  }
  
  .e-de-op-replace-messagediv {
    color: #000;
    top: auto;
  }
  
  .e-de-font-content-label .e-label,
  .e-de-font-dlg-cb-right .e-label,
  .e-de-font-checkbox .e-label {
    font-size: 14px;
  }
  
  .e-de-font-content-label .e-frame,
  .e-de-font-dlg-cb-right .e-frame,
  .e-de-font-checkbox .e-frame,
  .e-de-font-content-label-caps .e-frame,
  .e-de-cntr-pane-padding .e-frame {
    height: 20px;
    line-height: 16px;
    width: 20px;
  }
  
  .e-de-op-input-group,
  .e-de-op-replacewith {
    height: 32px;
  }
  
  .e-bigger .e-de-op-input-group,
  .e-bigger .e-de-op-replacewith {
    height: 40px;
  }
  
  .e-de-hyperlink-bookmark-check {
    margin-top: 15px;
  }
  
  .e-de-table-container-div .e-checkbox-wrapper .e-frame,
  .e-de-table-header-div .e-checkbox-wrapper .e-frame,
  .e-de-table-ppty-options-break .e-checkbox-wrapper .e-frame,
  .e-de-table-ppty-options-header-row .e-checkbox-wrapper .e-frame,
  .e-de-table-cell-header-div .e-checkbox-wrapper .e-frame,
  .e-de-tbl-btn-separator .e-checkbox-wrapper .e-frame,
  .e-de-hyperlink-bookmark-check .e-checkbox-wrapper .e-frame,
  .e-de-tbl-margin-sub-header .e-frame {
    height: 20px;
    line-height: 16px;
    width: 20px;
  }
  
  .e-de-table-container-div .e-checkbox-wrapper .e-label,
  .e-de-table-header-div .e-checkbox-wrapper .e-label,
  .e-de-table-ppty-options-break .e-checkbox-wrapper .e-label,
  .e-de-table-ppty-options-header-row .e-checkbox-wrapper .e-label,
  .e-de-table-cell-header-div .e-checkbox-wrapper .e-label,
  .e-de-tbl-btn-separator .e-checkbox-wrapper .e-label,
  .e-de-hyperlink-bookmark-check .e-checkbox-wrapper .e-label,
  .e-de-tbl-margin-sub-header .e-label {
    font-size: 14px;
  }
  
  .e-de-table-container-div .e-checkbox-wrapper .e-label,
  .e-de-table-header-div .e-checkbox-wrapper .e-label,
  .e-de-table-ppty-options-break .e-checkbox-wrapper .e-label,
  .e-de-table-ppty-options-header-row .e-checkbox-wrapper .e-label,
  .e-de-table-cell-header-div .e-checkbox-wrapper .e-label,
  .e-de-tbl-btn-separator .e-checkbox-wrapper .e-label,
  .e-de-hyperlink-bookmark-check .e-checkbox-wrapper .e-label,
  .e-de-tbl-margin-sub-header .e-label {
    font-size: 14px;
  }
  
  .e-de-table-ppty-dlg-measure-div {
    float: right;
    margin-left: 20px;
    margin-top: -18px;
  }
  
  .e-de-table-ppty-dlg-measure-div.e-de-rtl {
    float: left;
    margin-left: 0;
    margin-right: 20px;
  }
  
  .e-de-table-ppty-dlg-measure-drop-down-div {
    float: right;
    margin-left: 20px;
    margin-top: 13px;
    width: 120px;
  }
  
  .e-de-table-ppty-dlg-measure-drop-down-div.e-de-rtl {
    margin-left: 0;
    margin-right: 20px;
  }
  
  .e-de-table-ppty-dlg-row-height-label {
    float: right;
    margin-right: 181px;
    margin-top: -62px;
  }
  
  .e-de-table-ppty-dlg-row-height-label.e-de-rtl {
    float: left;
    margin-left: 181px;
    margin-right: 0;
  }
  
  .e-de-table-ppty-dlg-preferred-width-div {
    float: right;
    margin-left: 20px;
    margin-top: 13px;
    width: 120px;
  }
  
  .e-de-table-ppty-dlg-preferred-width-div.e-de-rtl {
    margin-left: 0;
    margin-right: 20px;
    width: 120px;
  }
  
  .e-de-table-ppty-options-break {
    margin-bottom: 10px;
  }
  
  .e-de-table-cell-subheader-div {
    margin-right: 125px;
    margin-top: -37px;
  }
  
  .e-de-table-cell-subheader-div.e-de-rtl {
    margin-left: 125px;
    margin-right: 0;
  }
  
  .e-de-table-ppty-dlg-cell-tab-measure-label {
    float: right;
    margin-right: 192px;
    margin-top: -64px;
  }
  
  .e-tab .e-tab-header .e-toolbar-item .e-de-table-ppty-dlg-row-header {
    padding-left: 12.5px;
    padding-right: 12.5px;
  }
  
  .e-tab .e-tab-header .e-toolbar-item .e-de-table-ppty-dlg-cell-header {
    padding-left: 12.5px;
    padding-right: 12.5px;
  }
  
  .e-tab .e-tab-header .e-toolbar-item .e-de-page-setup-dlg-margin-tab-header {
    padding-left: 1px;
    padding-right: 12.5px;
  }
  
  .e-styles-list {
    float: left;
    margin-right: 20px;
    width: 250px;
  }
  
  .e-styles-textboxdiv {
    padding-bottom: 15px;
  }
  
  .e-styles-listview .e-list-item {
    font-size: 13px;
    height: 30px;
    line-height: 27px;
  }
  
  .e-styles-common {
    padding-top: 5px;
  }
  
  .e-styles-button {
    float: right;
  }
  
  .e-de-toc-dlg-right-sub-container.e-de-rtl {
    margin-left: 2px;
    margin-right: 2px;
  }
  
  .e-de-toc-dlg-styles {
    margin-bottom: 13px;
    margin-left: 35px;
    margin-top: 30px;
  }
  
  .e-de-toc-dlg-styles.e-de-rtl {
    margin-left: 0;
    margin-right: 35px;
  }
  
  .e-de-toc-dlg-style-input.e-de-rtl {
    margin-left: 0;
    margin-right: 36px;
  }
  
  .e-de-toc-table-div .e-de-toc-dlg-toc-level {
    height: 24px;
    margin-left: 34px;
    width: 44px;
  }
  
  .e-de-toc-styles-table-div {
    border: 1px solid #c8c8c8;
    border-radius: 1px;
    margin-top: 10px;
    width: 100%;
    height: 200px;
  }
  
  .e-de-toc-dlg-sub-level-heading {
    font-size: 12px;
  }
  
  .e-de-toc-table-div {
    height: 160px;
    overflow-y: scroll;
    width: 100%;
  }
  
  .e-de-toc-dlg-style-input {
    margin-bottom: 3px;
    margin-left: 36px;
    width: 210px;
  }
  
  .e-de-toc-dlg-outline-levels {
    margin-top: 15px;
    width: 148px;
  }
  
  .e-bookmark-textboxdiv .e-bookmark-textbox-input {
    height: 32px;
  }
  
  .e-styles-dlgfields {
    font-weight: normal;
    margin-bottom: 6px;
  }
  
  .e-tab .e-tab-header .e-toolbar-item .e-de-op-find-tab-header {
    padding-left: 1px;
    padding-right: 25px;
  }
  
  .e-tab .e-tab-header .e-toolbar-item .e-de-op-replace-tab-header {
    padding-left: 1px;
    padding-right: 25px;
  }
  
  .e-de-dlg-target .e-footer-content .e-list-dlg-font {
    margin-left: 1px;
  }
  
  .e-bookmark-dlgfields {
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 6px;
  }
  
  .e-de-ui-wfloating-menu.e-de-ui-bullets-menu .e-de-ui-wfloating-menuitem-md {
    height: 65px;
    padding: 10px;
    width: 70px;
  }
  
  .e-de-ui-wfloating-menu.e-de-ui-bullets-menu .e-de-ui-wfloating-bullet-menuitem-md {
    height: 45px;
    width: 45px;
  }
  
  .e-de-bullet-icon-size {
    height: 45px;
    width: 45px;
  }
  
  .e-de-ui-list-header-presetmenu {
    cursor: pointer;
    font-size: 11px;
    line-height: 14px;
    min-width: 50px;
    overflow: hidden;
    text-align: left;
    white-space: nowrap;
    width: 100%;
  }
  
  .e-de-number-bullet-dlg .e-tab .e-content .e-item {
    padding: 0px;
  }
  
  .e-de-number-bullet-dlg {
    width: 272px;
  }
  
  .e-de-style-numbered-list,
  .e-de-ui-bullet-list-header-presetmenu {
    height: 270px;
  }
  
  .e-de-ui-bullet {
    font-size: 42px;
  }
  
  .e-de-ui-list-header-presetmenu .e-de-ui-list-line {
    border-bottom: 1px solid #ccc;
    margin-left: 5px;
    width: 100%;
  }
  
  .e-de-ui-list-header-presetmenu div span {
    color: #aaa;
    display: inline-block;
    vertical-align: middle;
  }
  
  .e-de-ui-wfloating-menu .e-de-ui-wfloating-menuitem,
  .e-de-ui-wfloating-menu .e-de-ui-menuitem-none {
    border: 0;
    box-shadow: inset 0 0 0 1px #ebebeb;
    cursor: pointer;
    height: 70px;
    margin: 0 5px 5px 0;
    padding: 0;
    width: 70px;
  }
  
  .e-de-ui-wfloating-menu {
    padding: 10px 4px 5px 10px;
  }
  
  .e-de-list-thumbnail .e-de-list-items {
    float: left;
  }
  
  .e-de-list-thumbnail .e-de-list-items {
    background: #fff;
    border: 1px solid transparent;
    clear: initial;
    display: inline-block;
    height: auto;
    margin: 5px;
    text-align: center;
    width: auto;
  }
  
  .e-de-list-items {
    background: #fff;
    box-sizing: border-box;
    cursor: pointer;
    list-style: none;
    padding: 7px 10px;
    position: relative;
  }
  
  .e-de-list-item-size {
    font-size: 14px;
  }
  
  .e-de-ui-wfloating-menu {
    padding: 10px 4px 5px 10px;
  }
  
  .e-de-table-ppty-dlg-tabs {
    height: 341px;
    position: relative;
  }
  
  .e-de-ui-bullet-list-header-presetmenu .e-de-list-thumbnail .e-de-list-active,
  .e-de-style-numbered-list .e-de-list-thumbnail .e-de-list-active {
    border-color: #0078d6;
  }
  
  .e-de-rp-sub-content-div {
    line-height: none;
    font-size: 12px;
    margin-bottom: 8px;
  }
  
  .e-de-bullet-icons {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  
  .e-de-header-footer-list {
    color: #0081e5;
  }
  
  .e-de-ltr-btn-div {
    font-size: 12px;
    width: 200px;
  }
  
  .e-de-tbl-rtl-btn-div {
    font-size: 12px;
    margin-right: 14px;
  }
  
  .e-de-tbl-rtl-btn-div.e-de-rtl {
    margin-left: 14px;
    margin-right: 0;
  }
  
  .e-de-tbl-ltr-btn-div {
    font-size: 12px;
  }
  
  .e-de-disabledbutton {
    opacity: 0.4;
    pointer-events: none;
  }
  
  .e-bigger .e-de-insert-table {
    height: auto;
    width: auto;
  }
  .e-bigger .e-de-insert-footnote {
    height: auto;
    width: auto;
  }
  .e-bigger .e-de-dlg-target .e-footer-content .e-control.e-btn.e-flat:not(.e-icon-btn) {
    height: auto;
  }
  .e-bigger .e-de-font-dlg {
    width: max-content;
  }
  .e-bigger .e-para-dlg-sub-height {
    height: 170px;
  }
  .e-bigger .e-de-toc-table-div .e-de-toc-dlg-toc-level.e-de-rtl {
    margin-right: 34px;
  }
  .e-bigger .e-de-font-content-label-width {
    width: 82px;
  }
  .e-bigger .e-de-toc-label {
    margin-left: 0px;
  }
  .e-bigger .e-de-toc-label-rtl {
    margin-left: 0px;
    margin-right: 0px;
  }
  .e-bigger .e-de-outline-rtl {
    width: 173px;
  }
  .e-bigger .e-de-rp-header {
    font-size: 16px;
    width: 75%;
  }
  
  .e-de-restrict-format {
    margin-top: 16px;
  }
  
  .e-de-rp-format {
    font-size: 13px;
    margin-bottom: 12px;
    opacity: 0.65;
    font-weight: normal;
  }
  
  .e-de-rp-checkbox {
    font-size: 12px;
  }
  
  .e-de-rp-border {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  
  .e-de-rp-header {
    font-size: 15px;
    font-weight: normal;
    opacity: 0.87;
    color: #333;
    width: 75%;
  }
  
  .e-de-rp-user .e-checkbox-wrapper {
    width: auto;
  }
  
  .e-de-rp-nav-btn,
  .e-de-rp-btn-enforce {
    background: #fafafa;
    font-size: 13px;
    opacity: 0.87;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  
  .e-bigger .e-de-rp-btn-enforce {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .e-de-rp-nav-btn {
    width: auto;
  }
  
  .e-de-rp-btn-stop-enforce {
    background: #fafafa;
    font-size: 13px;
    opacity: 0.87;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  }
  
  .e-de-rp-sub-div {
    border-bottom: 1px solid #e4e4e4;
    padding: 12px;
  }
  
  .e-de-restrict-pane {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
  }
  
  .e-de-rp-whole-header {
    padding: 12px;
  }
  
  .e-de-rp-user {
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    font-size: 12px;
    height: 110px;
  }
  
  .e-de-rp-enforce {
    padding: 12px;
  }
  
  .e-de-rp-enforce-nav {
    margin: 0 12px 12px 12px;
  }
  
  .e-de-enforce-dlg-title {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 8px;
  }
  
  .e-de-enforce .e-de-enforce-dlg-input {
    height: 32px;
    width: 300px;
  }
  
  .e-de-user-add-btn {
    background: #e0e0e0;
    border-radius: 2px;
    width: 74px;
  }
  
  .e-de-user-dlg .e-de-user-dlg-textbox-input {
    margin-right: 16px;
    width: 304px;
  }
  
  .e-de-user-dlg-list.e-de-rtl .e-de-user-dlg-textbox-input {
    margin-right: 0;
    margin-left: 16px;
  }
  
  .e-de-user-dlg-list {
    margin-bottom: 15px;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
  
  .e-de-user-listview {
    border: 1px solid #e4e4e4;
    border-radius: 2px;
    height: 106px;
  }
  
  .e-de-user-dlg-user {
    margin-bottom: 12px;
  }
  
  .e-user-delete {
    float: left;
  }
  
  .e-de-unprotect-dlg-title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .e-de-rp-stop-div1 {
    opacity: 0.87;
    padding: 12px 12px 6px 12px;
    font-weight: 500;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .e-de-rp-stop-div2 {
    padding: 12px 12px 24px;
  }
  
  .e-de-rp-close-icon {
    float: right;
    position: relative;
    top: -7px;
    right: -8px;
  }
  
  .e-de-restrict-pane {
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    width: 268px;
  }
  
  .e-de-rp-nav-lbl {
    font-size: 13px;
    margin: 0 12px;
  }
  
  .e-documenteditor-optionspane {
    height: 100%;
  }
  
  .e-de-cmt-pane {
    background-color: inherit;
    color: #333;
  }
  
  .e-de-cp-whole-header {
    padding: 12px;
  }
  
  .e-de-cp-header {
    font-size: 13px;
    font-weight: 500;
    opacity: 0.87;
    width: 75%;
  }
  
  .e-de-cmt-container {
    height: auto;
    padding: 0 8px;
    overflow: auto;
  }
  
  .e-de-cmt-sub-container {
    background-color: #fff;
    border: 1px solid #dadada;
    border-radius: 2px;
    margin: 8px 0;
    padding: 8px;
  }
  
  .e-de-cmt-view {
    cursor: default;
  }
  
  .e-de-cmt-sub-container.e-de-cmt-selection {
    border-left: 3px solid #0078d6;
    padding-left: 6px;
  }
  
  .e-de-tc-outer {
    border-bottom: 1px solid #d8d8d8;
  }
  
  .e-de-tc-pane {
    color: #333;
  }
  
  .e-de-trckchanges-inner {
    cursor: default;
    margin: 8px 0 8px 7.5px;
    padding-left: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
  }
  
  .e-de-trckchanges-inner:hover {
    border-left: 2px solid #0078d6;
    cursor: default;
    margin: 8px 0 8px 7.5px;
    padding-left: 6px;
    padding-right: 16px;
    padding-bottom: 8px;
  }
  
  .e-de-trckchanges-inner.e-de-trckchanges-inner-select {
    border-left: 3px solid #0078d6;
    cursor: default;
    margin: 8px 0 8px 7.5px;
    padding-left: 5px;
    padding-right: 16px;
    padding-bottom: 8px;
  }
  
  .e-de-tc-no-chng {
    color: #000;
    font-size: 14px;
    opacity: 65%;
    margin: 60px 0;
    text-align: center;
  }
  
  .e-tc-btn-bg-clr.e-btn:not(:hover):not(.e-active) {
    background-color: inherit;
    border-color: transparent;
    color: inherit;
  }
  
  .e-tc-nvgte.e-icon-btn {
    text-align: center;
    vertical-align: middle;
  }
  
  .e-de-track-span-user {
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: hidden;
    width: 110px;
  }
  
  .e-de-track-span-view {
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: hidden;
    width: 50px;
  }
  
  .e-btn.e-outline.e-de-track-accept-button {
    min-width: 66px;
    padding: 5px 6px;
    text-transform: initial;
  }
  
  .e-btn.e-outline.e-de-track-accept-button:hover {
    min-width: 66px;
    padding: 5px 6px;
    text-transform: initial;
  }
  
  .e-btn.e-outline.e-de-track-reject-button {
    min-width: 64px;
    padding: 5px 6px;
    margin-left: 8px;
    text-transform: initial;
  }
  
  .e-btn.e-outline.e-de-track-reject-button:hover {
    min-width: 64px;
    padding: 5px 6px;
    margin-left: 8px;
    text-transform: initial;
  }
  
  .e-de-track-chngs-count {
    font-size: 12px;
    margin-left: 6px;
    opacity: 0.87;
    padding-top: 7px;
    white-space: nowrap;
  }
  
  .e-de-track-insert {
    color: #4d841e;
    font-size: 13px;
    opacity: 100%;
  }
  
  .e-de-track-delete {
    color: #d74113;
    font-size: 13px;
    opacity: 100%;
  }
  
  .e-de-track-toolbar {
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    padding-bottom: 1px;
  }
  
  .e-de-track-pane-drop-btn {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 5px;
    font-size: 13px;
    font-weight: normal;
  }
  
  .e-toolbar-item.e-de-track-toolbar-overlay.e-template.e-overlay {
    opacity: 1;
    font-weight: normal;
    font-size: 13px;
  }
  
  .e-de-track-date {
    font-size: 13px;
    margin-top: 4px;
    opacity: 0.67;
  }
  
  .e-de-track-usernme-div {
    -ms-flex-pack: justify;
        justify-content: space-between;
    padding-top: 8px;
  }
  
  .e-de-track-user-nme {
    font-size: 13px;
    font-weight: 500;
    padding-left: 8px;
  }
  
  .e-de-track-chngs-text {
    line-height: 19.5px;
    overflow: hidden;
    word-wrap: break-word;
    text-overflow: ellipsis;
    font-size: 13px;
    min-height: 20px;
    padding-top: 8px;
    margin-bottom: 16px;
  }
  
  .e-de-track-chng-table {
    border-collapse: collapse;
    border-spacing: 0px;
    opacity: 70%;
    width: 100%;
  }
  
  .e-de-tc-tble-cell {
    border: 1px solid;
    height: 20px;
  }
  
  .e-de-tc-shrink-img {
    height: 30px;
    margin: 0 4px;
    vertical-align: middle;
    width: 50px;
  }
  
  .e-de-tc-field {
    background-color: rgb(206, 205, 205);
    margin: 0 2px;
  }
  
  .e-de-tc-pmark {
    font-size: 14px;
    font-family: Roboto-Regular;
    font-weight: 600;
    color: rgb(1, 22, 119);
    margin: 0 2px;
  }
  
  .e-de-cmt-sub-container:not(.e-de-cmt-selection):not(.e-de-cmt-reply):hover {
    border-left: 2px solid #0078d6;
    padding-left: 7px;
  }
  
  .e-de-cmt-author {
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 8px;
  }
  
  .e-de-cmt-author-name {
    font-size: 13px;
    font-weight: normal;
    padding-left: 8px;
    width: 90%;
  }
  
  .e-de-cp-option.e-btn.e-icon-btn {
    background-color: inherit;
    border: none;
    color: inherit;
    float: right;
    height: auto;
    padding: 0px;
    width: auto;
  }
  
  .e-de-cp-option.e-btn.e-icon-btn span {
    margin-top: 0px;
  }
  
  .e-de-cmt-view:hover .e-de-cp-option {
    display: block;
  }
  
  .e-de-cmt-readonly {
    font-size: 13px;
    padding-top: 4px;
    word-break: break-word;
  }
  
  .e-de-cmt-date {
    font-size: 13px;
    margin-top: 12px;
    opacity: 0.67;
  }
  
  .e-de-cmt-sub-container.e-de-cmt-reply {
    border: none;
    border-top: 1px solid #dadada;
    border-radius: 0px;
    margin: 11.5px 8px 0 8px;
    padding: 0px;
  }
  .e-de-cmt-sub-container.e-de-cmt-reply .e-de-cmt-view {
    margin-top: 11.5px;
  }
  
  .e-de-cmt-textarea.e-input {
    color: #333;
    font-size: 13px;
    line-height: normal;
    min-height: 30px;
    overflow: hidden;
    padding-top: 5px;
    resize: none;
  }
  
  .e-bigger .e-de-cmt-textarea.e-input {
    padding-top: 3px;
  }
  
  .e-de-cmt-action-button {
    text-align: right;
    margin-top: 8px;
  }
  
  .e-de-cmt-post-btn.e-btn.e-flat,
  .e-de-cmt-cancel-btn.e-btn.e-flat {
    height: 32px;
    margin-left: 8px;
    width: 32px;
  }
  
  .e-de-cmt-resolved .e-de-cmt-author-name,
  .e-de-cmt-resolved .e-de-cmt-readonly,
  .e-de-cmt-resolved e-de-cmt-date {
    opacity: 0.67;
  }
  .e-de-cmt-resolved .e-de-cmt-rply-view {
    display: none;
  }
  .e-de-cmt-resolved .e-de-cmt-resolve-btn {
    display: block;
    margin-top: 8px;
    text-align: right;
  }
  
  .e-de-cmt-sub-container.e-de-cmt-resolved.e-de-cmt-selection {
    border-left: 3px solid rgba(0, 120, 214, 0.5);
  }
  
  .e-de-cmt-sub-container.e-de-cmt-resolved:not(.e-de-cmt-selection):not(.e-de-cmt-reply):hover {
    border-left: 2px solid rgba(0, 120, 214, 0.5);
  }
  
  .e-de-lock-mark {
    cursor: default;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    height: 13px;
    pointer-events: all;
    width: 13px;
  }
  
  .e-de-cmt-mark {
    cursor: default;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;
    height: 13px;
    pointer-events: all;
    width: 13px;
  }
  .e-de-cmt-mark :hover {
    color: #0078d6;
  }
  
  .e-de-cmt-mark.e-de-cmt-mark-selected,
  .e-de-cmt-mark.e-de-cmt-mark-hover {
    color: #0078d6;
  }
  
  .e-de-cmt-no-cmt {
    margin-left: 6px;
    margin-top: 24px;
    font-size: 13px;
  }
  
  .e-de-cmt-drawer-cnt {
    font-size: 13px;
    height: 20px;
    margin-top: 8px;
  }
  
  .e-de-cmt-drawer {
    cursor: pointer;
    float: right;
    opacity: 0.54;
    margin-right: 4px;
    margin-top: 4px;
  }
  
  .e-de-cmt-rply-view {
    margin-top: 12px;
  }
  
  .e-de-cmt-resolve-btn {
    display: none;
  }
  
  .e-rtl .e-de-cmt-pane {
    border-left: none;
    border-right: 1px solid #eaeaea;
  }
  .e-rtl .e-de-rp-close-icon {
    float: left;
    right: 8px;
  }
  .e-rtl .e-de-cp-option.e-btn.e-icon-btn {
    float: left;
  }
  .e-rtl .e-de-cmt-sub-container.e-de-cmt-selection {
    border-left: 1px solid #dadada;
    border-right: 3px solid #0078d6;
    padding-left: 8px;
    padding-right: 6px;
  }
  .e-rtl .e-de-cmt-sub-container:not(.e-de-cmt-selection):not(.e-de-cmt-reply):hover {
    border-left: 1px solid #dadada;
    border-right: 2px solid #0078d6;
    padding-left: 8px;
    padding-right: 7px;
  }
  .e-rtl .e-de-cmt-sub-container.e-de-cmt-resolved.e-de-cmt-selection {
    border-right: 3px solid rgba(0, 120, 214, 0.5);
  }
  .e-rtl .e-de-cmt-sub-container.e-de-cmt-resolved:not(.e-de-cmt-selection):not(.e-de-cmt-reply):hover {
    border-right: 2px solid rgba(0, 120, 214, 0.5);
  }
  .e-rtl .e-de-cmt-action-button {
    text-align: left;
  }
  .e-rtl .e-de-cmt-no-cmt {
    margin-left: auto;
    margin-right: 6px;
  }
  .e-rtl .e-de-cmt-drawer {
    float: left;
    margin-left: 8px;
    margin-right: auto;
  }
  .e-rtl .e-de-cmt-resolve-btn {
    text-align: left;
  }
  
  .e-bigger .e-de-cmt-post-btn.e-btn.e-flat,
  .e-bigger .e-de-cmt-cancel-btn.e-btn.e-flat {
    height: 40px;
    margin-left: 12px;
    width: 40px;
  }
  .e-bigger .e-de-cp-whole-header {
    height: 56px;
    padding: 16px;
    padding-right: 8px;
  }
  .e-bigger .e-de-rp-close-icon {
    float: right;
    position: relative;
    top: -9px;
    right: -11px;
  }
  .e-bigger .e-rtl .e-de-cmt-post-btn.e-btn.e-flat,
  .e-bigger .e-rtl .e-de-cmt-cancel-btn.e-btn.e-flat {
    margin-right: 12px;
  }
  
  .e-de-close-icon.e-btn {
    background-color: transparent !important;
    border-color: transparent !important;
    border-radius: 50%;
    color: #333;
    border: none;
  }
  .e-de-close-icon.e-btn:active {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: none;
  }
  .e-de-close-icon.e-btn:hover {
    background-color: transparent !important;
    box-shadow: none;
    color: #0078d6;
    cursor: default;
    border: none;
  }
  
  .e-btn.e-de-close-icon:hover span {
    cursor: pointer;
    color: #0078d6;
  }
  
  .e-de-para-dlg-right-sub-container-blazor {
    top: 0;
  }
  
  .e-de-rp-mu-btn {
    margin-top: 3px;
  }
  
  .e-de-table-dialog-row-height {
    padding-top: 28px;
  }
  
  .e-de-tablecell-dialog-alignment-icon {
    width: 54px;
    height: 54px;
    margin: 2px;
  }
  
  .e-de-table-dia-indent-from-left {
    right: 45px;
  }
  
  .e-de-table-dia-align-div {
    border: 1px solid #ddd;
    display: inline-block;
    height: 52px;
    margin-right: 10px;
    width: 52px;
  }
  
  .e-de-table-dialog-alignment-icon {
    margin: 2px;
    height: 46px;
  }
  
  .e-de-table-border-setting-genral {
    margin-right: 10px;
  }
  
  .e-de-table-border-clr-left-container {
    padding-right: 19px;
  }
  
  .e-de-table-border-clr-heading {
    font-size: 14px;
    font-weight: normal;
    padding-bottom: 7px;
  }
  
  .e-de-table-border-icon-container {
    margin-top: 4px;
    margin-right: 4px;
  }
  
  .e-de-table-border-preview-container {
    padding-left: 66px;
  }
  
  .e-de-table-dlg-alignment-heading {
    color: #333;
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .e-rtl .e-de-cmt-author-name {
    padding-left: 0%;
    padding-right: 8px;
  }
  .e-rtl .e-de-ok-button {
    margin-right: 0;
    margin-left: 8px;
  }
  .e-rtl .e-de-table-dia-align-div.e-de-rtl {
    margin-left: 10px;
    margin-right: 0;
  }
  .e-rtl .e-de-table-border-clr-left-container {
    padding-right: 0;
    padding-left: 19px;
  }
  .e-rtl .e-de-table-border-preview-container {
    padding-right: 66px;
    padding-left: 0;
  }
  .e-rtl .e-de-table-border-setting-genral {
    margin-right: 0;
    margin-left: 10px;
  }
  .e-rtl .e-de-table-border-icon-container {
    margin-right: 0;
    margin-left: 4px;
  }
  .e-rtl .e-de-table-border-preview {
    width: 23px;
  }
  .e-rtl .e-de-table-setting-labels-heading {
    margin-left: 0;
    margin-right: 10px;
  }
  
  .e-bigger .e-de-cmt-author-name {
    padding-left: 8px;
  }
  .e-bigger .e-de-cmt-rply-view {
    margin-top: 12px;
  }
  .e-bigger .e-de-cmt-sub-container {
    padding: 8px;
  }
  .e-bigger .e-de-cmt-sub-container.e-de-cmt-selection {
    padding-left: 6px;
  }
  .e-bigger .e-de-cmt-sub-container:not(.e-de-cmt-selection):not(.e-de-cmt-reply):hover {
    padding-left: 7px;
  }
  .e-bigger .e-de-cmt-sub-container.e-de-cmt-reply {
    padding: 0%;
  }
  .e-bigger .e-de-rp-nav-btn {
    width: auto;
  }
  .e-bigger .e-de-rp-whole-header {
    padding: 12px;
  }
  .e-bigger .e-de-rp-sub-div {
    border-bottom: 1px solid #e4e4e4;
    padding: 12px;
  }
  .e-bigger .e-de-rp-enforce {
    padding: 12px;
  }
  .e-bigger .e-de-enforce .e-de-enforce-dlg-input:not(.e-de-enforce .e-de-enforce-dlg-input:last-child) {
    width: 300px;
  }
  .e-bigger .e-rtl .e-de-cmt-author-name {
    padding-left: 0%;
    padding-right: 8px;
  }
  .e-bigger .e-rtl .e-de-cmt-sub-container.e-de-cmt-selection {
    padding-left: 8px;
    padding-right: 6px;
  }
  .e-bigger .e-rtl .e-de-cmt-sub-container:not(.e-de-cmt-selection):not(.e-de-cmt-reply):hover {
    padding-left: 8px;
    padding-right: 7px;
  }
  .e-bigger .e-rtl .e-de-rp-close-icon {
    float: left;
    right: 11px;
  }
  
  .e-bigger .e-de-para-dlg-container .e-checkbox-wrapper .e-label,
  .e-bigger .e-de-table-options-dlg .e-checkbox-wrapper .e-label {
    font-size: 14px;
  }
  .e-bigger .e-de-para-dlg-container .e-input-group {
    width: 192px !important;
  }
  .e-bigger .e-de-para-dlg-heading {
    color: #333;
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .e-bigger .e-de-para-dlg-container .e-checkbox-wrapper .e-label,
  .e-bigger .e-de-table-options-dlg .e-checkbox-wrapper .e-label {
    font-size: 12px;
  }
  .e-bigger .e-de-para-dlg-container {
    height: auto;
    width: 405px;
  }
  .e-bigger .e-de-para-dlg-cs-check-box {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .e-bigger .e-de-para-dlg-spacing-div {
    margin-left: 20px;
  }
  .e-bigger .e-de-para-dlg-spacing-div.e-de-rtl {
    margin-left: 0;
    margin-right: 20px;
  }
  .e-bigger .e-de-para-dlg-sub-container .e-input-group {
    margin-bottom: 8px;
  }
  .e-bigger .e-de-para-dlg-sub-container {
    margin-bottom: 15px;
  }
  .e-bigger .e-de-para-dlg-right-sub-container {
    top: 0;
  }
  .e-bigger .e-de-dlg-sub-header {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
  }
  .e-bigger .e-de-rtl-btn-div {
    font-size: 12px;
    margin-right: 24px;
    width: 200px;
  }
  .e-bigger .e-de-rtl-btn-div.e-de-rtl {
    margin-left: 12px;
    margin-right: 0;
  }
  
  .e-de-para-dlg-heading {
    color: #333;
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
    opacity: 87%;
  }
  
  .e-de-para-dlg-container .e-checkbox-wrapper .e-label,
  .e-de-table-options-dlg .e-checkbox-wrapper .e-label {
    font-size: 12px;
  }
  
  .e-de-para-dlg-container {
    height: auto;
    width: 405px;
  }
  
  .e-de-para-dlg-cs-check-box {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  
  .e-de-para-dlg-spacing-div {
    margin-left: 40px;
  }
  
  .e-de-para-dlg-spacing-div.e-de-rtl {
    margin-left: 0;
    margin-right: 40px;
  }
  
  .e-de-para-dlg-sub-container .e-input-group {
    margin-bottom: 8px;
  }
  
  .e-de-para-dlg-sub-container {
    margin-bottom: 15px;
  }
  
  .e-de-para-dlg-right-sub-container {
    top: 0;
  }
  
  .e-de-dlg-sub-header {
    display: block;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
  }
  
  .e-de-rtl-btn-div {
    font-size: 12px;
    margin-right: 14px;
    width: 200px;
  }
  
  .e-de-rtl-btn-div.e-de-rtl {
    margin-left: 14px;
    margin-right: 0;
  }
  
  .e-para-dlg-sub-height {
    height: 145px;
  }
  
  .e-de-ctnr-linespacing::before {
    content: "\eb8a";
  }
  
  .e-de-ctnr-lock::before {
    content: "\eb7d";
  }
  
  .e-de-ctnr-italic::before {
    content: "\eb99";
  }
  
  .e-de-ctnr-justify::before {
    content: "\eb67";
  }
  
  .e-de-ctnr-tableofcontent::before {
    content: "\eb98";
  }
  
  .e-de-cnt-track::before {
    content: "\e20a";
    font-family: "e-icons";
  }
  
  .e-de-selected-spellcheck-item::before {
    content: "\e935";
    font-family: "e-icons";
    font-size: 10px;
  }
  
  .e-de-selected-underline-item::before {
    content: "\e935";
    font-family: "e-icons";
    font-size: 10px;
  }
  
  .e-de-ctnr-pagenumber::before {
    content: "\eb74";
  }
  
  .e-de-ctnr-highlight::before {
    content: "\eb6e";
  }
  
  .e-de-ctnr-bold::before {
    content: "\eb77";
  }
  
  .e-de-ctnr-subscript::before {
    content: "\eb96";
  }
  
  .e-de-ctnr-aligncenter::before {
    content: "\eb9e";
  }
  
  .e-de-ctnr-fontcolor::before {
    content: "\eb87";
  }
  
  .e-de-ctnr-change-case::before {
    content: "\e88c";
  }
  
  .e-de-ctnr-pagesetup::before {
    content: "\eb6d";
  }
  
  .e-de-ctnr-strokestyle::before {
    content: "\eb62";
  }
  
  .e-de-ctnr-strikethrough::before {
    content: "\eb7f";
  }
  
  .e-de-ctnr-image::before {
    content: "\eb64";
  }
  
  .e-de-ctnr-bookmark::before {
    content: "\eb63";
  }
  
  .e-de-ctnr-increaseindent::before {
    content: "\eb76";
  }
  
  .e-de-ctnr-header::before {
    content: "\eb7e";
  }
  
  .e-de-ctnr-superscript::before {
    content: "\eb6f";
  }
  
  .e-de-ctnr-numbering::before {
    content: "\eb9c";
  }
  
  .e-de-ctnr-bullets::before {
    content: "\eb92";
  }
  
  .e-de-ctnr-borders::before {
    content: "\e93d";
  }
  
  .e-de-ctnr-decreaseindent::before {
    content: "\eb69";
  }
  
  .e-de-ctnr-showhide::before {
    content: "\eb8b";
    font-size: 16px;
  }
  
  .e-de-ctnr-alignright::before {
    content: "\eb82";
  }
  
  .e-de-ctnr-footer::before {
    content: "\eb79";
  }
  
  .e-de-ctnr-clearall::before {
    content: "\eb80";
  }
  
  .e-de-ctnr-outsideborder::before {
    content: "\eb66";
  }
  
  .e-de-ctnr-allborders::before {
    content: "\eb95";
  }
  
  .e-de-ctnr-insideborders::before {
    content: "\eb88";
  }
  
  .e-de-ctnr-highlightcolor::before {
    content: "\eb6e";
  }
  
  .e-de-ctnr-mergecell::before {
    content: "\eb93";
  }
  
  .e-de-ctnr-bullet-none::before {
    content: "\e256";
  }
  
  .e-de-ctnr-bullet-dot::before {
    content: "\e270";
  }
  
  .e-de-ctnr-bullet-circle::before {
    content: "\e254";
  }
  
  .e-de-ctnr-bullet-square::before {
    content: "\e271";
  }
  
  .e-de-ctnr-bullet-flower::before {
    content: "\e267";
  }
  
  .e-de-ctnr-bullet-arrow::before {
    content: "\e253";
  }
  
  .e-de-ctnr-bullet-tick::before {
    content: "\e259";
  }
  
  .e-de-selected-item::before {
    content: "\e935";
  }
  
  .e-de-ctnr-break::before {
    content: "\e58d";
  }
  
  .e-de-ctnr-page-break::before {
    content: "\e590";
  }
  
  .e-de-ctnr-section-break::before {
    content: "\e58e";
  }
  
  .e-de-ctnr-upload::before {
    content: "\e60f";
  }
  
  .e-de-ctnr-leftborders::before {
    content: "\e291";
  }
  
  .e-de-ctnr-topborder::before {
    content: "\e281";
  }
  
  .e-de-ctnr-rightborder::before {
    content: "\e288";
  }
  
  .e-de-ctnr-insertleft::before {
    content: "\e285";
  }
  
  .e-de-ctnr-insertright::before {
    content: "\e284";
  }
  
  .e-de-ctnr-insertabove::before {
    content: "\e506";
  }
  
  .e-de-ctnr-insertbelow::before {
    content: "\e505";
  }
  
  .e-de-ctnr-deleterows::before {
    content: "\e283";
  }
  
  .e-de-ctnr-deletecolumns::before {
    content: "\e282";
  }
  
  .e-de-ctnr-undo::before {
    content: "\ebed";
  }
  
  .e-de-ctnr-bottomborder::before {
    content: "\e298";
  }
  
  .e-de-ctnr-strokesize::before {
    content: "\ebfe";
  }
  
  .e-de-ctnr-download::before {
    content: "\e603";
  }
  
  .e-de-ctnr-find::before {
    content: "\e275";
  }
  
  .e-de-ctnr-new::before {
    content: "\e7d5";
  }
  
  .e-de-ctnr-paste::before {
    content: "\e601";
  }
  
  .e-de-ctnr-redo::before {
    content: "\ebfa";
  }
  
  .e-de-ctnr-open::before {
    content: "\ebdd";
  }
  
  .e-de-ctnr-underline::before {
    content: "\ebf0";
  }
  
  .e-de-ctnr-insideverticalborder::before {
    content: "\e287";
  }
  
  .e-de-ctnr-insidehorizondalborder::before {
    content: "\e276";
  }
  
  .e-de-ctnr-aligncenter-table::before {
    content: "\ea94";
  }
  
  .e-de-ctnr-alignleft::before {
    content: "\ebeb";
  }
  
  .e-de-ctnr-close::before {
    content: "\ea7f";
  }
  
  .e-de-ctnr-link::before {
    content: "\e290";
  }
  
  .e-de-ctnr-table::before {
    content: "\e294";
  }
  
  .e-de-ctnr-backgroundcolor::before {
    content: "\ebf2";
  }
  
  .e-de-ctnr-print::before {
    content: "\ebf9";
  }
  
  .e-de-ctnr-aligntop::before {
    content: "\ea98";
  }
  
  .e-de-ctnr-alignbottom::before {
    content: "\ea91";
  }
  
  .e-de-ctnr-cellbg-clr-picker::before {
    content: "\ebf2";
  }
  
  .e-de-flip {
    transform: scaleX(-1);
  }
  
  .e-de-cnt-cmt-add::before {
    content: "\e814";
    font-family: "e-icons";
  }
  
  .e-de-printlayout::before {
    content: "\e257";
    font-family: "e-icons";
  }
  
  .e-de-weblayout::before {
    content: "\e529";
    font-family: "e-icons";
  }
  
  .e-de-textform::before {
    content: "\e198";
    font-family: "e-icons";
  }
  
  .e-de-formproperties::before {
    content: "\e199";
    font-family: "e-icons";
  }
  
  .e-de-clearform::before {
    content: "\e19a";
    font-family: "e-icons";
  }
  
  .e-de-dropdownform::before {
    content: "\e19b";
    font-family: "e-icons";
  }
  
  .e-de-formfield::before {
    content: "\e19c";
    font-family: "e-icons";
  }
  
  .e-de-checkbox-form::before {
    content: "\e19d";
    font-family: "e-icons";
  }
  
  .e-de-arrow-up::before {
    content: "\e834";
    font-family: "e-icons";
  }
  
  .e-de-arrow-down::before {
    content: "\e83d";
    font-family: "e-icons";
  }
  
  .e-de-update-field::before {
    content: "\e19e";
    font-family: "e-icons";
  }
  
  .e-de-footnote::before {
    content: "\e435";
    font-family: "e-icons";
  }
  
  .e-de-endnote::before {
    content: "\e436";
    font-family: "e-icons";
  }
  
  .e-de-toolbar {
    height: 100%;
  }
  
  .e-de-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text .e-de-text-wrap {
    height: 0;
    line-height: 0;
  }
  
  .e-de-ctnr-file-picker {
    left: -110em;
    position: fixed;
  }
  
  .e-de-ctnr-rtl {
    direction: rtl;
  }
  
  .e-de-ctnr-hglt-btn {
    border: 0.5px solid transparent;
    display: inline-block;
    height: 25px;
    margin: 3px;
    width: 25px;
  }
  
  .e-color-selected,
  .e-de-ctnr-hglt-btn:hover {
    border-color: #fff;
    outline: #000 0.5px solid;
  }
  
  .e-hglt-no-color {
    height: 30px;
    padding-top: 1px;
    width: 157px;
  }
  .e-hglt-no-color:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
  
  .e-de-ctnr-hglt-no-color {
    font-size: 12px;
    font-weight: 400;
    left: 40px;
    padding-top: 11px;
    position: absolute;
    top: 100px;
  }
  
  .e-de-scrollbar-hide::-webkit-scrollbar {
    width: 0;
  }
  
  .e-de-scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .e-de-toolbar {
    height: 100%;
  }
  
  .e-de-statusbar-pageweb {
    background-color: #fff;
    border: 0;
    box-shadow: none;
    float: right;
    height: 33px;
    width: 33px;
  }
  .e-de-statusbar-pageweb:hover {
    box-shadow: none;
  }
  
  .e-de-statusbar-pageweb .e-de-printlayout,
  .e-de-statusbar-pageweb .e-de-weblayout {
    font-size: 16px;
  }
  
  .e-de-statusbar-pageweb .e-de-printlayout:hover,
  .e-de-statusbar-pageweb .e-de-weblayout:hover {
    font-size: 16px;
  }
  
  .e-bigger .e-btn.e-de-statusbar-pageweb {
    padding: 0;
  }
  .e-bigger .e-de-statusbar-pageweb .e-de-printlayout,
  .e-bigger .e-de-statusbar-pageweb .e-de-weblayout {
    font-size: 16px;
  }
  
  .e-de-linespacing {
    margin-top: 12px;
  }
  
  .e-de-statusbar-zoom {
    background-color: #fff;
    border: 0;
    color: #333;
    float: right;
    font-weight: 400;
    height: 33px;
  }
  
  .e-de-pagenumber-text {
    border: none !important;
  }
  
  .e-de-pagenumber-input {
    background: #fff;
    border: 1px solid #dadada;
    border-radius: 0;
    color: #333;
    padding: 0px;
    text-align: center;
    width: 22px;
  }
  
  .e-btn-pageweb-toggle {
    background-color: #c8c8c8;
    box-shadow: none;
    outline: #fafafa 0 solid;
    outline-offset: 0;
  }
  
  .e-btn-pageweb-spellcheck {
    border: 0;
    box-shadow: none;
    float: right;
    margin-left: calc(100% - 405px);
  }
  
  .e-bigger .e-btn-pageweb-spellcheck {
    margin-left: calc(100% - 435px);
  }
  
  .e-de-ctn-title {
    background-color: #0078d6;
    border-bottom: none !important;
    color: #fff;
  }
  
  .e-de-tool-ctnr-properties-pane {
    display: -ms-flexbox;
    display: flex;
    height: calc(100% - 121px);
    width: 100%;
  }
  
  .e-de-ctnr-properties-pane {
    display: -ms-flexbox;
    display: flex;
    height: calc(100% - 40px);
    width: 100%;
  }
  
  .e-de-statusbar-separator {
    border-left: 1px solid #dadada;
    height: 16px;
    margin-left: 15.5;
    margin-top: 10px;
  }
  
  .e-de-statusbar-spellcheck {
    border-radius: 2px;
    font-weight: 400;
    margin-left: 7.5px;
  }
  
  .e-de-ctn {
    background-color: #fff;
    border: 1px solid #dadada;
    height: 100%;
    position: relative;
    width: 100%;
  }
  
  .e-bigger .e-de-statusbar-spellcheck {
    border-radius: 2px;
    margin-left: 7.5px;
  }
  
  .e-de-ctnr-toolbar {
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    width: 100%;
  }
  
  .e-de-tlbr-wrapper .e-de-toolbar.e-toolbar,
  .e-de-tlbr-wrapper .e-de-ctnr-properties-pane-btn {
    border: 0;
  }
  
  .e-de-pane {
    border-left: 1px solid #dadada;
  }
  
  .e-de-pane-rtl {
    border-right: 1px solid #dadada;
  }
  
  .e-de-tool-ctnr-properties-pane,
  .e-de-ctnr-properties-pane {
    background-color: #fff;
    border-bottom: 1px solid #dadada;
    border-top: 1px solid #dadada;
  }
  
  .e-de-ctnr-segment {
    margin-bottom: 12px;
  }
  
  .e-de-ctnr-segment > div:first-child:not(.e-rtl),
  .e-de-ctnr-segment > button:first-child:not(.e-rtl) {
    margin-right: 12px;
  }
  
  .e-de-ctnr-segment.e-de-ctnr-segment-rtl > div:first-child,
  .e-de-ctnr-segment.e-de-ctnr-segment-rtl > button:first-child {
    margin-left: 12px;
    margin-right: 0;
  }
  
  .e-de-tlbr-wrapper {
    background-color: #ffffff;
    height: 60px;
    width: calc(100% - 75px);
    text-align: center;
  }
  
  .e-de-ctnr-prop-label {
    color: #333;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.05px;
    margin-bottom: 12px;
    opacity: 0.87;
  }
  
  .e-de-table-prop-label {
    margin-left: 12px;
  }
  
  .e-de-table-prop-label.e-de-rtl {
    margin-left: 0;
    margin-right: 12px;
  }
  
  .e-de-toolbar.e-toolbar {
    border-radius: 0;
  }
  
  .e-de-ctnr-toolbar .e-toolbar-item.e-de-toolbar-btn-first {
    margin-left: 0;
    margin-right: 6px;
  }
  
  .e-de-ctnr-toolbar.e-de-ctnr-rtl .e-toolbar-item.e-de-toolbar-btn-first {
    margin-left: 6px;
    margin-right: 0;
  }
  
  .e-bigger .e-de-ctnr-toolbar .e-toolbar-item.e-de-toolbar-btn-first {
    margin-left: 0;
    margin-right: 8px;
  }
  
  .e-bigger .e-de-ctnr-toolbar.e-de-ctnr-rtl .e-toolbar-item.e-de-toolbar-btn-first {
    margin-left: 8px;
    margin-right: 0;
  }
  
  .e-de-ctnr-toolbar .e-toolbar-item.e-de-toolbar-btn-last {
    margin-left: 6px;
    margin-right: 0;
  }
  
  .e-de-ctnr-toolbar.e-de-ctnr-rtl .e-toolbar-item.e-de-toolbar-btn-last {
    margin-left: 0;
    margin-right: 6px;
  }
  
  .e-bigger .e-de-ctnr-toolbar .e-toolbar-item.e-de-toolbar-btn-last {
    margin-left: 8px;
    margin-right: 0;
  }
  
  .e-bigger .e-de-ctnr-toolbar.e-de-ctnr-rtl .e-toolbar-item.e-de-toolbar-btn-last {
    margin-left: 0;
    margin-right: 8px;
  }
  
  .e-de-toolbar.e-toolbar .e-toolbar-items {
    height: 60px;
  }
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    border: 1px solid #dadada;
    border-width: 0 1px 0 0;
    height: 59px;
    margin: 0 5.5px;
  }
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-start {
    margin-left: 12px;
    margin-right: 6px;
  }
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-middle {
    margin-left: 6px;
    margin-right: 6px;
  }
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-end {
    margin-left: 6px;
    margin-right: 12px;
  }
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-image-focus :focus {
    background-color: #c8c8c8;
  }
  
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
    padding: 0;
    padding-bottom: 6px;
  }
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus {
    padding: 0;
    padding-bottom: 6px;
  }
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active {
    padding: 0;
    padding-bottom: 6px;
  }
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover {
    padding: 0;
    padding-bottom: 6px;
  }
  
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
    padding: 0;
  }
  
  .e-de-overlay {
    height: 100%;
    opacity: 0.5;
    pointer-events: none;
    -ms-touch-action: none;
        touch-action: none;
    width: 100%;
  }
  
  .e-de-ctnr-properties-pane-btn {
    width: 75px;
  }
  
  .e-de-pane-enable-clr.e-de-ctnr-properties-pane-btn .e-btn {
    color: #0081e5;
  }
  
  .e-de-pane-disable-clr.e-de-ctnr-properties-pane-btn .e-btn {
    color: #333;
  }
  
  .e-de-ctnr-properties-pane-btn .e-btn {
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
    color: #0081e5;
    min-height: 100%;
    min-width: 100%;
  }
  .e-de-ctnr-properties-pane-btn .e-btn:focus {
    box-shadow: none;
  }
  .e-de-ctnr-properties-pane-btn .e-btn:active {
    box-shadow: none;
  }
  .e-de-ctnr-properties-pane-btn .e-btn:hover {
    box-shadow: none;
  }
  
  .e-de-showhide-btn {
    border: 0;
    height: 82px;
  }
  
  .e-de-showhide-btn-rtl {
    border: 0;
    height: 82px;
  }
  
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
    padding: 0;
  }
  
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    display: table;
    font-size: 12px;
    margin: 0 6.5px;
    padding: 0;
    white-space: normal;
  }
  
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
  .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
    padding: 0 !important;
  }
  
  .e-de-hdr-ftr-frst-div {
    margin-bottom: 10px;
  }
  
  .e-de-hdr-ftr-top-div {
    margin-bottom: 14px;
  }
  
  .e-de-cntr-pane-padding {
    padding: 12px;
  }
  
  .e-de-prop-pane {
    height: 100%;
    min-height: 200px;
    overflow: auto;
    width: 260px;
  }
  
  .e-de-review-pane {
    border-left: 1px solid #eaeaea;
    height: 100%;
    min-height: 200px;
    overflow: auto;
    width: 400px;
  }
  
  .e-bigger .e-de-cntr-pane-padding {
    padding: 16px;
  }
  .e-bigger .e-de-prop-pane {
    height: 100%;
    min-height: 200px;
    overflow: auto;
    width: 272px;
  }
  
  .e-de-ctnr-group-btn.e-btn-group:not(.e-outline) {
    box-shadow: none;
    height: 32px;
  }
  .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):focus {
    box-shadow: none;
  }
  .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):active {
    box-shadow: none;
  }
  .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):hover {
    box-shadow: none;
  }
  
  .e-de-ctnr-group-btn button {
    box-shadow: none;
    height: 32px;
  }
  .e-de-ctnr-group-btn button:focus {
    box-shadow: none;
  }
  .e-de-ctnr-group-btn button:active {
    box-shadow: none;
  }
  .e-de-ctnr-group-btn button:hover {
    box-shadow: none;
  }
  
  .e-de-property-div-padding {
    border-bottom: 0.5px solid #e0e0e0;
    padding: 12.5px;
  }
  
  .e-de-ctnr-dropdown-ftr {
    border-top: 1px solid #e0e0e0;
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 12px;
    line-height: 40px;
    text-indent: 1.2em;
  }
  
  .e-de-char-fmt-btn-left > button,
  .e-de-insert-del-cell button {
    width: 37.25px;
  }
  
  .e-de-char-fmt-btn-right > button {
    width: 37.5px;
  }
  
  .e-de-panel-left-width {
    width: 149px;
  }
  
  .e-bigger .e-de-panel-left-width {
    width: 149px;
  }
  .e-bigger .e-de-char-fmt-btn-left > button,
  .e-bigger .e-de-insert-del-cell button {
    width: 37.25px;
  }
  
  .e-de-panel-right-width {
    width: 75px;
  }
  
  .e-de-cntr-highlight-pane {
    border: 1px solid #dadada;
  }
  
  .e-de-btn-hghlclr > button:first-child {
    padding: 1px !important;
  }
  
  .e-de-ctnr-hglt-color {
    font-size: 12px;
    font-weight: 400;
    height: 18px !important;
    width: 18px !important;
  }
  
  .e-de-font-clr-picker > div div button,
  .e-de-font-clr-picker > div button,
  .e-de-font-clr-picker > button {
    width: 30.8px;
  }
  
  .e-de-floating-menu.e-de-bullets-menu .e-de-floating-menuitem-md {
    height: 55px;
    padding: 4px !important;
    width: 60px;
  }
  
  .e-de-floating-menu.e-de-bullets-menu .e-de-floating-menuitem-md:hover {
    border: 3px solid #f4f4f4;
    padding: 2px !important;
  }
  
  .e-de-floating-menu.e-de-bullets-menu .e-de-floating-bullet-menuitem-md {
    height: 38px;
    padding: 4px !important;
    width: 38px;
  }
  
  .e-de-floating-menu.e-de-bullets-menu .e-de-floating-bullet-menuitem-md:hover {
    border: 3px solid #f4f4f4;
    padding: 2px !important;
  }
  
  .e-de-list-header-presetmenu {
    cursor: pointer;
    font-size: 11px;
    line-height: 14px;
    overflow: hidden;
    text-align: left;
    min-width: 50px;
    white-space: nowrap;
    width: 100%;
  }
  
  .e-de-bullet-list-header-presetmenu {
    cursor: pointer;
    font-size: 14px;
    left: -11px;
    line-height: 26px;
    min-width: 50px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    width: 100%;
  }
  
  .e-rtl .e-de-bullet-list-header-presetmenu {
    cursor: pointer;
    font-size: 14px;
    left: 10px;
    line-height: 26px;
    min-width: 50px;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    width: 100%;
  }
  
  .e-de-bullet {
    font-size: 42px;
  }
  
  .e-de-list-header-presetmenu .e-de-list-line {
    border-bottom: 1px solid #ccc;
    margin-left: 5px;
    width: 100%;
  }
  
  .e-de-toc-optionsdiv {
    margin-bottom: 11.5px;
    margin-left: 5.5px;
    margin-top: 15.5px;
  }
  
  .e-de-toc-optionsdiv.e-de-rtl {
    margin-right: 5.5px;
    margin-left: 0;
  }
  
  .e-de-list-header-presetmenu div span {
    display: inline-block;
    vertical-align: middle;
  }
  
  .e-de-floating-menu .e-de-floating-menuitem,
  .e-de-floating-menu .e-de-menuitem-none {
    cursor: pointer;
    height: 70px;
    padding: 0 !important;
    margin: 0 5px 5px 0 !important;
    width: 70px;
  }
  
  .e-de-list-thumbnail .e-de-list-items {
    float: left;
  }
  
  .e-de-list-thumbnail .e-de-list-items {
    border: 1px solid #e4e4e4;
    clear: initial;
    display: inline-block;
    height: auto;
    margin: 5px;
    padding: 2px;
    text-align: center;
    width: auto;
  }
  
  .e-de-list-items {
    cursor: pointer;
    background: #fff;
    box-sizing: border-box;
    list-style: none;
    padding: 7px 10px 7px 10px;
    position: relative;
  }
  
  .e-de-list-item-size {
    font-size: 14px;
  }
  
  .e-de-floating-menuitem.e-de-floating-menuitem-md.e-de-list-items.e-de-list-item-size.de-list-item-selected,
  .e-de-floating-menuitem.e-de-floating-bullet-menuitem-md.e-de-list-items.e-de-list-item-size.de-list-item-selected {
    border: 3px solid #0078d6;
    padding: 2px !important;
  }
  
  .e-de-floating-menu {
    padding: 10px 4px 5px 10px !important;
  }
  
  .e-de-list-container {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    display: inline-block;
    line-height: normal;
    margin: 0;
    outline: 0;
    padding: 5px 0;
    position: absolute;
    width: auto;
    z-index: 10020;
  }
  
  .e-de-ctnr-list {
    font-size: 29px;
    vertical-align: top;
  }
  
  .e-de-image-property {
    padding-left: 32px;
  }
  
  .e-de-img-prty-span {
    color: #333;
    left: 8px;
    position: absolute;
    top: 7px;
  }
  
  .e-btn-toggle {
    background-color: #c8c8c8 !important;
    border-color: #c8c8c8 !important;
    outline: #eaeaea 0 solid;
    outline-offset: 0;
    box-shadow: none !important;
  }
  .e-btn-toggle:hover {
    background-color: #c8c8c8 !important;
    border-color: #c8c8c8 !important;
    outline: #eaeaea 0 solid;
    outline-offset: 0;
    box-shadow: none !important;
  }
  
  .e-de-ctnr-group-btn-top > button:first-child {
    border-radius: 0;
    border-top-left-radius: 4px;
  }
  
  .e-de-ctnr-group-btn-top.e-de-rtl > button:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
  }
  
  .e-de-ctnr-group-btn-top > button:last-child {
    border-radius: 0;
    border-top-right-radius: 4px;
  }
  
  .e-de-ctnr-group-btn-top.e-de-rtl > button:last-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
  }
  
  .e-de-ctnr-group-btn-middle > button {
    border-radius: 0;
    border-top: 0;
    border-bottom: 0;
  }
  
  .e-de-ctnr-group-btn-bottom > button:first-child {
    border-radius: 0;
    border-bottom-left-radius: 4px;
  }
  
  .e-de-ctnr-group-btn-bottom.e-de-rtl > button:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
  }
  
  .e-de-ctnr-group-btn-bottom > button:last-child {
    border-radius: 0;
    border-bottom-right-radius: 4px;
  }
  
  .e-de-ctnr-group-btn-bottom.e-de-rtl > button:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 4px;
  }
  
  .e-de-toc-template1 {
    background: #fff;
    border: 1px solid #dadada;
    color: #333;
    height: 129px;
    margin-left: 78px;
    width: 94px;
  }
  
  .e-de-toc-template1.e-de-rtl {
    margin-left: 0;
    margin-right: 78px;
  }
  
  .e-de-toc-template1-content1 {
    font-size: 10px;
    height: 11px;
    margin-left: 5.4px;
    margin-top: 6.7px;
    width: 82px;
  }
  
  .e-de-toc-template1-content2 {
    font-size: 8px;
    height: 9px;
    margin-left: 20.4px;
    margin-top: 5.7px;
    width: 63px;
  }
  
  .e-de-toc-template1-content3 {
    font-size: 7px;
    height: 8px;
    margin-left: 28.4px;
    margin-top: 6.7px;
    width: 56px;
  }
  
  .e-de-prop-sub-label {
    color: #333;
    font-size: 13px;
    margin-bottom: 8px;
    font-weight: normal;
  }
  
  .e-de-toc-checkbox1 {
    height: 14px;
    margin-top: 14px;
  }
  
  .e-de-toc-checkbox2 {
    height: 14px;
    margin-top: 14px;
  }
  
  .e-de-toc-checkbox3 {
    height: 14px;
    margin-top: 14px;
  }
  
  .e-de-status-bar {
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    padding-top: 2.8px;
    width: 100%;
  }
  
  .e-de-ctnr-pg-no {
    color: #333;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 13px;
    height: 100%;
    padding-top: 6px;
  }
  .e-de-ctnr-pg-no span {
    border: 1px solid transparent;
  }
  
  .e-de-ctnr-pg-no-spellout {
    color: #333;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 13px;
    height: 100%;
    padding-top: 6px;
    width: calc(100% - 160px);
  }
  .e-de-ctnr-pg-no-spellout span {
    border: 1px solid transparent;
  }
  
  .e-bigger .e-de-ctnr-pg-no-spellout {
    color: #333;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 13px;
    height: 100%;
    padding-top: 6px;
    width: calc(100% - 180px);
  }
  
  .e-de-statusbar-zoom-spell {
    background-color: #fff;
    border: 0;
    color: #333;
    float: right;
    height: 34px;
    margin-left: calc(100% - 405px);
  }
  
  .e-bigger .e-de-statusbar-zoom-spell {
    background-color: #fff;
    border: 0;
    color: #333;
    float: right;
    height: 34px;
    margin-left: calc(100% - 435px);
  }
  
  .e-de-btn-cancel {
    margin-left: 10px;
  }
  
  .e-de-btn-cancel-rtl {
    margin-left: 0;
    margin-right: 10px;
  }
  
  .e-de-prop-header-label {
    color: #333;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.05px;
    opacity: 0.87;
  }
  
  .e-de-prop-separator-line {
    border-bottom: 1px solid #dadada;
  }
  
  .e-de-status-bar > div label {
    font-weight: normal;
  }
  
  .e-de-stylediv {
    padding-left: 12px;
  }
  
  .e-de-stylediv-rtl {
    padding-left: 0;
    padding-right: 12px;
  }
  
  .e-de-border-size-button {
    height: 28px;
    margin-top: 14px;
    width: 100px;
  }
  
  .e-de-color-picker {
    height: 28px;
    width: 100px;
  }
  
  .e-de-cell-text-box {
    margin-right: 12px;
  }
  
  .e-de-pane-rtl .e-de-cell-text-box {
    margin-left: 12px;
    margin-right: 0;
  }
  
  .e-de-prop-fill-label {
    margin-right: 8px;
  }
  
  .e-de-prop-fill-label.e-de-rtl {
    margin-left: 8px;
    margin-right: 0;
  }
  
  .e-de-grp-btn-ctnr .e-de-ctnr-group-btn {
    height: 38px !important;
  }
  
  .e-bigger .e-de-grp-btn-ctnr .e-de-ctnr-group-btn {
    height: 42px !important;
  }
  
  .e-de-grp-btn-ctnr .e-de-ctnr-group-btn > button {
    height: 38px;
    width: 38px;
  }
  
  .e-bigger .e-de-grp-btn-ctnr .e-de-ctnr-group-btn > button {
    height: 42px;
    width: 42px;
  }
  
  .e-de-border-clr-picker .e-split-btn-wrapper > button:first-child {
    width: 70px;
  }
  
  .e-bigger .de-split-button > div:first-child {
    margin-right: 0;
  }
  .e-bigger .e-de-border-clr-picker .e-split-btn-wrapper > button:first-child {
    width: 66px;
  }
  .e-bigger .e-de-prop-fill-label {
    margin-left: 0;
    margin-right: 9.8px;
  }
  .e-bigger .e-de-prop-fill-label.e-de-rtl {
    margin-left: 9.8px;
    margin-right: 0px;
  }
  .e-bigger .e-rtl .e-de-cell-text-box {
    margin-left: 16px;
    margin-right: 0;
  }
  .e-bigger .e-de-color-picker {
    height: 38px;
    width: 96px;
  }
  .e-bigger .e-de-border-size-button {
    height: 38px;
    margin-top: 14px;
    width: 96px;
  }
  .e-bigger .e-de-stylediv {
    padding-left: 16px;
  }
  .e-bigger .e-de-stylediv-rtl {
    padding-right: 16px;
  }
  .e-bigger .e-de-tool-ctnr-properties-pane {
    display: -ms-flexbox;
    display: flex;
    height: calc(100% - 122px);
    min-height: 200px;
    width: 100%;
  }
  .e-bigger .e-de-ctnr-properties-pane {
    display: -ms-flexbox;
    display: flex;
    height: calc(100% - 40px);
    width: 100%;
  }
  .e-bigger .e-de-ctn {
    background-color: #fff;
    border: 1px solid #dadada;
    height: 100%;
    position: relative;
    width: 100%;
  }
  .e-bigger .e-de-ctnr-toolbar {
    display: -ms-flexbox;
    display: flex;
    height: 82px;
    width: 100%;
  }
  .e-bigger .e-de-tlbr-wrapper .e-de-toolbar.e-toolbar,
  .e-bigger .e-de-tlbr-wrapper .e-de-ctnr-properties-pane-btn {
    border: 0;
  }
  .e-bigger .e-de-pane {
    border-left: 1px solid #dadada;
  }
  .e-bigger .e-de-pane-rtl {
    border-right: 1px solid #dadada;
  }
  .e-bigger .e-de-ctnr-segment {
    margin-bottom: 16px;
  }
  .e-bigger .e-de-ctnr-segment > div:first-child:not(.e-rtl),
  .e-bigger .e-de-ctnr-segment > button:first-child:not(.e-rtl) {
    margin-right: 16px;
  }
  .e-bigger .e-de-ctnr-segment.e-de-ctnr-segment-rtl > div:first-child,
  .e-bigger .e-de-ctnr-segment.e-de-ctnr-segment-rtl > button:first-child {
    margin-left: 16px;
    margin-right: 0;
  }
  .e-bigger .e-de-tlbr-wrapper {
    background-color: #fff;
    height: 82px;
    width: calc(100% - 75px);
  }
  .e-bigger .e-de-ctnr-prop-label {
    color: #333;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.05px;
    margin-bottom: 16px;
    opacity: 0.87;
  }
  .e-bigger .e-de-table-prop-label {
    margin-left: 14.5px;
  }
  .e-bigger .e-de-table-prop-label.e-de-rtl {
    margin-left: 0;
    margin-right: 14.5px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items {
    height: 82px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-separator {
    height: 25px;
    margin: 0 7.5px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-start {
    margin-left: 16px;
    margin-right: 8px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-middle {
    margin-left: 8px;
    margin-right: 8px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-toolbar-btn-end {
    margin-left: 8px;
    margin-right: 16px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item.e-de-image-focus :focus {
    background-color: #c8c8c8;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control {
    padding: 0;
    padding-bottom: 6px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus,
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus {
    padding: 0;
    padding-bottom: 6px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active,
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:active {
    padding: 0;
    padding-bottom: 6px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover,
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover {
    padding: 0;
    padding-bottom: 6px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
    padding: 0 !important;
  }
  .e-bigger .e-de-overlay {
    height: 100%;
    opacity: 0.5;
    pointer-events: none;
    -ms-touch-action: none;
        touch-action: none;
  }
  .e-bigger .e-de-ctnr-properties-pane-btn {
    width: 75px;
  }
  .e-bigger .e-de-ctnr-properties-pane-btn .e-btn {
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
    min-height: 100%;
    min-width: 100%;
  }
  .e-bigger .e-de-ctnr-properties-pane-btn .e-btn:focus {
    box-shadow: none;
  }
  .e-bigger .e-de-ctnr-properties-pane-btn .e-btn:active {
    box-shadow: none;
  }
  .e-bigger .e-de-ctnr-properties-pane-btn .e-btn:hover {
    box-shadow: none;
  }
  .e-bigger .e-de-showhide-btn {
    border: 0;
    height: 82px;
  }
  .e-bigger .e-de-showhide-btn-rtl {
    border: 0;
    height: 82px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
    padding: 0;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    display: table;
    font-size: 12px;
    margin: 0 6px;
    padding: 0;
    white-space: normal;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
    padding: 0;
  }
  .e-bigger .e-de-ctnr-group-btn.e-btn-group:not(.e-outline) {
    box-shadow: none;
    height: 38px;
  }
  .e-bigger .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):focus {
    box-shadow: none;
  }
  .e-bigger .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):active {
    box-shadow: none;
  }
  .e-bigger .e-de-ctnr-group-btn.e-btn-group:not(.e-outline):hover {
    box-shadow: none;
  }
  .e-bigger .e-de-ctnr-group-btn button {
    box-shadow: none;
    height: 38px;
  }
  .e-bigger .e-de-ctnr-group-btn button:focus {
    box-shadow: none;
  }
  .e-bigger .e-de-ctnr-group-btn button:active {
    box-shadow: none;
  }
  .e-bigger .e-de-ctnr-group-btn button:hover {
    box-shadow: none;
  }
  .e-bigger .e-de-property-div-padding {
    border-bottom: 0.5px solid #e0e0e0;
    padding: 16px;
  }
  .e-bigger .e-de-font-clr-picker > div button,
  .e-bigger .e-de-font-clr-picker > button {
    width: auto;
  }
  .e-bigger .e-de-ctnr-dropdown-ftr {
    border-top: 1px solid #e0e0e0;
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 12px;
    line-height: 40px;
    text-indent: 1.2em;
  }
  .e-bigger .e-de-char-fmt-btn > button {
    width: 38.5px;
  }
  .e-bigger .e-de-btn-hghlclr > button:first-child {
    padding: 0 6px !important;
  }
  .e-bigger .e-de-ctnr-hglt-color {
    font-size: 12px;
    font-weight: 400;
    height: 25px !important;
    width: 25px !important;
  }
  .e-bigger .e-de-ctnr-list {
    font-size: 29px;
    vertical-align: top;
  }
  .e-bigger .e-de-image-property {
    padding-left: 32px;
  }
  .e-bigger .e-de-img-prty-span {
    color: #333;
    left: 10px;
    position: absolute;
    top: 10px;
  }
  .e-bigger .e-btn-toggle {
    background-color: #c8c8c8 !important;
    box-shadow: none !important;
    border-color: #c8c8c8 !important;
    outline: #eaeaea 0 solid;
    outline-offset: 0;
  }
  .e-bigger .e-btn-toggle:hover {
    background-color: #c8c8c8 !important;
    border-color: #c8c8c8 !important;
    outline: #eaeaea 0 solid;
    outline-offset: 0;
    box-shadow: none !important;
  }
  .e-bigger .e-de-toc-template1 {
    background: #fff;
    border: 1px solid #dadada;
    color: #333;
    height: 129px;
    margin-left: 78px;
    width: 94px;
  }
  .e-bigger .e-de-toc-template1-content1 {
    font-size: 10px;
    height: 11px;
    margin-left: 5.4px;
    margin-top: 6.7px;
    width: 82px;
  }
  .e-bigger .e-de-toc-template1-content2 {
    font-size: 8px;
    height: 9px;
    margin-left: 20.4px;
    margin-top: 5.7px;
    width: 63px;
  }
  .e-bigger .e-de-toc-template1-content3 {
    font-size: 7px;
    height: 8px;
    margin-left: 28.4px;
    margin-top: 6.7px;
    width: 56px;
  }
  .e-bigger .e-de-toc-optionsdiv {
    margin-bottom: 11.5px;
    margin-left: 5.5px;
    margin-top: 15.5px;
  }
  .e-bigger .e-de-toc-optionsdiv.e-de-rtl {
    margin-right: 5.5px;
    margin-left: 0;
  }
  .e-bigger .e-de-prop-sub-label {
    font-size: 13px;
    margin-bottom: 8.5px;
  }
  .e-bigger .e-de-btn-cancel {
    margin-left: 10px;
  }
  .e-bigger .e-de-status-bar {
    background-color: #fff;
    display: -ms-flexbox;
    display: flex;
    padding-top: 3px;
    width: 100%;
  }
  .e-bigger .e-de-statusbar-zoom {
    background-color: #fff;
    border: 0;
    color: #333;
    float: right;
    height: 34px;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
    line-height: 25px;
    padding: 0 5px !important;
  }
  .e-bigger .e-de-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text .e-de-text-wrap {
    height: 0;
    line-height: 0;
  }
  
  .e-de-ctn .e-de-bzr-button {
    font-size: 12px;
    font-weight: 400;
    background: #fff;
    height: 100%;
    box-shadow: none;
    border: none;
  }
  
  .e-de-ctn .e-de-bzr-button:focus {
    outline: none;
  }
  
  .e-de-ctn .e-de-bzr-button:hover {
    background: #f4f4f4;
    border: 1px solid #eaeaea;
    border-radius: 0;
    color: #000;
  }
  
  .e-de-ctn .e-de-bzr-button:active {
    box-shadow: none;
    border-radius: 0;
    background-color: #d1ebff;
    border-color: #d1ebff;
    color: #333;
  }
  
  .e-de-ctn .e-de-bzr-button {
    font-size: 12px;
    font-weight: 400;
    background: #fff;
    height: 100%;
  }
  
  .e-de-ctn .e-de-bzr-button:focus {
    outline: none;
  }
  
  .e-de-ctn .e-de-bzr-button:hover {
    background: #f4f4f4;
    border: 1px solid #eaeaea;
    border-radius: 0;
    color: #000;
  }
  
  .e-de-ctn .e-de-bzr-button:active {
    box-shadow: none;
    border-radius: 0;
    background-color: #d1ebff;
    border-color: #d1ebff;
    color: #333;
  }
  
  .e-de-ctn .e-de-ctnr-toolbar .e-de-bzr-button .e-btn-icon {
    font-size: 16px;
  }
  .e-bigger .e-de-ctn .e-de-ctnr-toolbar .e-de-bzr-button .e-btn-icon {
    font-size: 16px;
  }