.view-tile{
    //margin: 8px;
    padding: 7px;
    border: 0.5px dashed #4A7F91;
    border-radius: 9px;

    label{
        color: #68BDC3;
        margin-left: 5px;
    }

    i{
        float: right;
        padding-right: 7px;
        padding-top: 4px;
        color: rgb(206, 212, 218);
        cursor: pointer;
    }
}
