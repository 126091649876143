.chip__wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.chip {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px dashed #4a7f91;
  padding: 8px 12px;

  &__items {
    display: flex;
    align-items: center;

    &__node {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      display: block;

      .label {
        margin-bottom: 2px;
        color: #aaaeb7;
        font-size: var(--small);
      }

      .text {
        color: #56b5bc;
        font-size: var(--h5);
        font-weight: 600;
      }
    }
  }
}

.chip__actions {
  display: flex;
  align-items: center;
  margin-left: 32px;

  .action__button {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

