
.action__button {
    border: none;
    outline: none;
    background-color: transparent;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    &:not(:last-child) {
      margin-right: 8px;
    }

    img {
      filter: opacity(0.3) drop-shadow(0 0 0 #bbcfd6);
      width: 16px;
      max-width: 100%;
      transition: var(--animate-1);

      &:hover {
        filter: opacity(0.5) drop-shadow(0 0 0 #bbcfd6);
      }
    }

    &--edit {
    }
    
    &--delete {
    }
  }