.setting__menu__item {
  --setting--menu-title-clr: #404a5f;
  padding: 20px 0;
  border-bottom: 1px solid var(--border-clr);

  &:last-child {
    border-bottom: transparent;;
  }

  .menu__header {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    padding: 0 20px;

    &--icon {
      width: 16px;
      height: 16px;
      margin-right: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: var(--setting--menu-title-clr);
      font-size: var(--h3);
    }

    &--title {
      font-size: var(--h3);
      color: var(--setting--menu-title-clr);
      font-weight: 600;
    }
  }

  .menu__contents {
    display: block;

    ul {
      margin: 0;
      padding: 0;
    }
  }
}
