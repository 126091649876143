.tp__builder__all__section__wrapper {
  width: 100%;
}

.all__tp__section__title {
  background-color: #fff;
  padding-right: 30px;
}

.tp__builder__all__tp__section__title__iW .all__tp__section__md__line {
  margin-left: 0 !important;
}
