:root {
  --page-title-lg: 25px;
  --h1: 20.25px;
  --h2: 18px;
  --h3: 16px;
  --h4: 14.22px;
  --h5: 12.64px;
  --h6: 11.24px;
  --small: 9.99px;
  --letter-spacing: -0.03rem;

  // Colors

  // Primary
  --primary-default: hsl(195, 50%, 28%);
  --primary-10: hsl(195, 19%, 92%);
  --primary-20: hsl(195, 20%, 84%);
  --primary-30: hsl(195, 20%, 76%);
  --primary-40: hsl(195, 20%, 68%);
  --primary-50: hsl(195, 20%, 60%);
  --primary-60: hsl(195, 20%, 52%);
  --primary-70: hsl(195, 25%, 44%);
  --primary-80: hsl(195, 35%, 36%);

  // Primary Light
  --primary-light-default: hsl(184, 43%, 54%);
  --primary-light-10: hsl(185, 41%, 95%);
  --primary-light-20: hsl(185, 43%, 90%);
  --primary-light-30: hsl(184, 42%, 84%);
  --primary-light-40: hsl(184, 43%, 79%);
  --primary-light-50: hsl(184, 42%, 74%);
  --primary-light-60: hsl(184, 43%, 69%);
  --primary-light-70: hsl(184, 43%, 64%);
  --primary-light-80: hsl(184, 43%, 59%);

  // Primary Dark
  --primary-dark-default: hsl(195, 43%, 36%);
  --primary-dark-10: hsl(193, 24%, 93%);
  --primary-dark-20: hsl(193, 24%, 85%);
  --primary-dark-30: hsl(196, 25%, 79%);
  --primary-dark-40: hsl(195, 25%, 71%);
  --primary-dark-50: hsl(195, 24%, 64%);
  --primary-dark-60: hsl(196, 25%, 57%);
  --primary-dark-70: hsl(195, 24%, 50%);
  --primary-dark-80: hsl(195, 32%, 43%);

  // Secondary
  --secondary-default: hsl(20, 71%, 69%);
  --secondary-10: hsl(20, 67%, 96%);
  --secondary-20: hsl(20, 67%, 93%);
  --secondary-30: hsl(21, 70%, 90%);
  --secondary-40: hsl(21, 69%, 86%);
  --secondary-50: hsl(20, 70%, 83%);
  --secondary-60: hsl(21, 70%, 79%);
  --secondary-70: hsl(20, 70%, 76%);
  --secondary-80: hsl(20, 70%, 73%);

  // Gray dark(Black)
  --color-1-default: hsl(221, 19%, 31%);
  --color-1-10: hsl(225, 10%, 92%);
  --color-1-20: hsl(223, 9%, 85%);
  --color-1-30: hsl(222, 8%, 77%);
  --color-1-40: hsl(222, 8%, 69%);
  --color-1-50: hsl(220, 9%, 62%);
  --color-1-60: hsl(220, 9%, 54%);
  --color-1-70: hsl(220, 10%, 46%);
  --color-1-80: hsl(220, 14%, 39%);

  // Beige
  --color-2-default: hsl(32, 33%, 84%);
  --color-2-10: hsl(20, 33%, 98%);
  --color-2-20: hsl(20, 33%, 97%);
  --color-2-30: hsl(30, 33%, 96%);
  --color-2-40: hsl(33, 33%, 95%);
  --color-2-50: hsl(30, 33%, 93%);
  --color-2-60: hsl(32, 33%, 91%);
  --color-2-70: hsl(33, 33%, 89%);
  --color-2-80: hsl(31, 33%, 88%);
  --color-2-90: hsl(33, 33%, 86%);

  // Action/Blue/60
  --action-color-1-default: hsl(202, 100%, 70%);
  --action-color-1-10: hsl(202, 100%, 92%);
  --action-color-1-20: hsl(202, 100%, 85%);
  --action-color-1-30: hsl(202, 100%, 73%);
  --action-color-1-40: hsl(202, 100%, 61%);
  --action-color-1-50: hsl(202, 100%, 52%);
  --action-color-1-60: hsl(202, 100%, 44%);
  --action-color-1-70: hsl(202, 100%, 44%);
  --action-color-1-80: hsl(202, 100%, 44%);
  --action-color-1-90: hsl(202, 100%, 44%);

  // Action/Yellow/Base
  --action-color-2-default: hsl(39, 100%, 71%);
  --action-color-2-10: hsl(39, 100%, 93%);
  --action-color-2-20: hsl(39, 100%, 85%);
  --action-color-2-30: hsl(39, 100%, 62%);
  --action-color-2-40: hsl(39, 100%, 62%);
  --action-color-2-50: hsl(39, 100%, 53%);
  --action-color-2-60: hsl(38, 100%, 44%);
  --action-color-2-70: hsl(38, 100%, 44%);
  --action-color-2-80: hsl(38, 100%, 44%);
  --action-color-2-90: hsl(38, 100%, 44%);

  // Action/Light Yellow/Base
  --action-color-3-default: hsl(42, 97%, 77%);
  --action-color-3-10: hsl(41, 100%, 94%);
  --action-color-3-20: hsl(42, 97%, 88%);
  --action-color-3-30: hsl(42, 98%, 83%);
  --action-color-3-40: hsl(42, 96%, 57%);
  --action-color-3-50: hsl(42, 97%, 38%);
  --action-color-3-60: hsl(42, 97%, 38%);
  --action-color-3-70: hsl(42, 97%, 38%);
  --action-color-3-80: hsl(42, 97%, 38%);
  --action-color-3-90: hsl(42, 97%, 38%);

  // Action/Red/Base
  --action-color-4-default: hsl(0, 78%, 65%);
  --action-color-4-10: hsl(0, 77%, 91%);
  --action-color-4-20: hsl(0, 78%, 83%);
  --action-color-4-30: hsl(0, 77%, 74%);
  --action-color-4-40: hsl(0, 78%, 49%);
  --action-color-4-50: hsl(0, 78%, 41%);
  --action-color-4-60: hsl(0, 78%, 25%);
  --action-color-4-70: hsl(0, 78%, 25%);
  --action-color-4-80: hsl(0, 78%, 25%);
  --action-color-4-90: hsl(0, 78%, 25%);

  // Background colors
  --bg-1: hsl(204, 100%, 99%);
  --bg-2: hsl(20, 33%, 98%);

  // spacing
  --spacing-px0: 0px;
  --spacing-px1: 1px;
  --spacing-px2: 2px;
  --spacing-0: 0;
  --spacing-1: 0.25rem;
  --spacing-1-5: 0.375rem;
  --spacing-2: 0.5rem;
  --spacing-2-5: 0.625rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-7: 1.75rem;
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem;
  --spacing-12: 3rem;
  --spacing-13: 3.25rem;
  --spacing-14: 3.5rem;
  --spacing-15: 3.75rem;
  // --spacing-16: 4rem;
  --spacing-17: 4.25rem;
  --spacing-18: 4.5rem;
  --spacing-19: 4.75;
  --spacing-20: 5rem;

  --spacing-4-minus: -1rem;
  --spacing-6-minus: -1.5rem;
  --spacing-13-minus: -3.25rem;

  // sizes
  --size-px0: 0px;
  --size-px1: 1px;
  --size-px2: 2px;
  --size-0: 0;
  --size-1: 0.25rem;
  --size-1-5: 0.375rem;
  --size-2: 0.5rem;
  --size-2-5: 0.625rem;
  --size-3: 0.75rem;
  --size-4: 1rem;
  --size-5: 1.25rem;
  --size-6: 1.5rem;
  --size-7: 1.75rem;
  --size-8: 2rem;
  --size-9: 2.25rem;
  --size-10: 2.5rem;
  --size-11: 2.75rem;
  --size-12: 3rem;
  --size-13: 3.25rem;
  --size-14: 3.5rem;
  --size-15: 3.75rem;
  --size-16: 4rem;
  --size-17: 4.25rem;
  --size-18: 4.5rem;
  --size-19: 4.75;
  --size-20: 5rem;

  // rounded
  --rounded-xs: 0.125rem;
  --rounded-sm: 0.25rem;
  --rounded-md: 0.5rem;
  --rounded-lg: 0.75rem;
  --rounded-xl: 1rem;
  --rounded-2xl: 1.25rem;
  --rounded-3xl: 1.5rem;
  --rounded-4xl: 1.75rem;
  --rounded-full: 999px;

  // Old one
  --clr-light-green: #56b5bc;
  --clr-gray-opt-40: #aaaeb7;

  --text-clr-1: #031d44;
  --text-clr-gray-dark: #404a5f;
  --text-clr-gray-500: #adb5bd;
  --gray-500: #adb5bd;
  --error-clr: red;

  --input-icon-color: #b0c5e5;

  --nav-width: 64px;

  --zindex-dropdown: 1500;
  --zindex-sticky: 1520;
  --zindex-fixed: 1530;
  --zindex-modal-backdrop: 1540;
  --zindex-offcanvas: 1550;
  --zindex-modal: 1560;
  --zindex-popover-overlayer: 1570;
  --zindex-popover: 1571;
  --zindex-tooltip: 1580;

  outline: none;

  // neutral button styles
  --button-border-neutral-active-width: 1px;
  --button-border-neutral-active-color: var(--color-neutral-50);
  --button-border-neutral-active-style: solid;
  --button-neutral-active: var(--color-neutral-50);
  --button-neutral-hover: var(--color-neutral-60);
  --button-border-neutral-hover-color: var(--color-neutral-60);
  --button-border-neutral-hover-style: solid;
  --button-border-neutral-hover-width: 1px;
}
