.order-summary-container {
    text-align: center;
    font-family: 'Roboto';
    padding: 40px 20px;
    background-color: #eeeee4;
    height: 100vh; 
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
  .success-message {
    margin-bottom: 10px;
  }
  
  .logo-icon {
    margin-bottom: 15px;
  }
  
  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  p {
    color: #9b9b9b; /* Lighter text */
    font-size: 14px;
  }

.summary-box {
  margin: 10px auto; /* Center the box horizontally */
  padding: 20px;
  background-color: #fafafa;
  border-radius: 12px;
  width: fit-content; /* Adjusts width based on content */
  min-width: 350px; /* Minimum width for the box */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center align text within the box */
}
  
  h3 {
    font-size: 18px;
    margin-bottom: 16px;
    font-weight: bold;
    text-align: left;
  }
  
  .summary-item {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  hr {
    border: none;
    height: 1px;
    background-color: #eaeaea;
    margin: 16px 0;
  }
  
  .total {
    font-weight: bold;
  }
  
  .return-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #000;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .return-button:hover {
    background-color: #333;
  }
  