.onboard__completion__modal {
  max-width: 570px;

  .contents {
    padding: 0;
  }
  // Contents area
  .profile__content {
    display: flex;
  }

  .form__content {
    display: block;
    padding: 24px 40px;

    .textarea-h--xs {
      min-height: 100px;
    }
  }

  // Actions buttons
  .actions__wr {
    display: flex;
    align-content: flex-end;

    button {
      margin-left: 12px;
    }
  }
}
