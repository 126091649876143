.pop__top__content {
  position: relative;

  .warning {
    display: none;
  }

  &.has-waring {
    padding-left: 76px !important;

    .warning {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 40px;

      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.profile__update__action {
  padding: 0 40px 20px;
  margin-top: -8px;
  display: flex;
  justify-content: flex-end;
}

.profile__warning__message {
  padding: 12px 40px;
  font-size: var(--h5);
  color: red;
  background-color: rgba(244, 212, 195, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .start {
    flex: 1;
  }

  p {
    margin: 0;
  }

  .link {
    color: var(--text-clr-1);
    font-weight: 600;
    cursor: pointer;
    transition: var(--animate-1);

    &:hover {
      text-decoration: underline;
    }
  }
}

.pop__center.with-tabs {
  padding: 0 !important;

  .modal__tab {
    .tab__base__header {
      ul {
        li {
          padding: 8px 40px;
          text-align: center;
          background-color: #fcfaf9;
          font-size: var(--h5);
          color: #394252;
          transition: var(--animate-1);

          &:hover {
            background-color: rgba(246, 242, 237, 0.6);
          }

          &.is--active {
            background-color: #f6f2ed;
            font-weight: 600;
          }
        }
      }
    }

    .tab__base__wrapper {
      .tab__base__panel {
        padding: 16px 40px 0 40px;

        &.form__tab {
          padding-top: 24px;
          padding-bottom: 24px;
        }
      }
    }
  }
}

.pop__onb__hr__container {
  .pop__bottom {
    padding: 40px;
  }

  .pop__bottom__iw {
    grid-template-columns: 1fr !important;

    .buttons__wr {
      grid-template-columns: repeat(3, auto) !important;
      width: 100%;
      justify-content: flex-end;
      gap: 0 !important;

      button {
        margin-left: 12px;
      }
    }
  }
}

.instruction {
  display: block;

  &__inner {
    padding: 20px 40px;
    background-color: rgba(86, 181, 188,0.5);

    .title {
      font-size: var(--h5);
      margin-bottom: 8px;
      color: #245a6c;
      font-weight: 600;
    }

    .details {
      font-size: var(--h5);
      padding: 0;
      color: #245a6c;
     

      p {
        padding: 0;
        margin: 0;

        &:not(:last-child) {
          // margin-bottom: 4px;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        margin-bottom: 8px;
      }
    }
  }
}
