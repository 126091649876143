.project__selector {
  display: block;

  .project__dropdown {
    max-width: 240px;

    &__button {
      background-color: transparent;
      border: none;
      outline: 0;
      padding: 0;
      min-width: 80px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      font-size: var(--h5-bold-font-size);
      color: #e89f7a;
      font-weight: 400;
      max-width: 100%;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        max-width: 100%;
      }

      &::after {
        margin-left: 12px;
        font-size: 16px;
        color: #7f8694;
      }
    }
  }
}

.dropdown__menu {
  max-width: 230px;
  max-height: 400px;
  overflow-y: auto;
  width: 100%;

  .dropdown-item {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}
