.top__nav__breadcrumb-container ul {
  li {
    padding: 0 !important;

    &:last-child {
      .crumb__item {
        color: var(--font-color-shade-primary);
        font-weight: var(--font-weight-medium);
      }
    }

    .crumb__item,
    a {
      font-size: var(--x-small-regular-font-size);
      font-weight: 400;
      color: var(--font-tint-04);

      &.icon-lp-arrow-right-line {
        &::before {
          font-size: 8px;
          color: var(--font-tint-04);
          margin: 0 10px;
        }
      }

      &::before {
        margin-right: 4px;
      }
    }
  }
}
