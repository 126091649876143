.menu__item__link {
  --setting--menu-link-clr: #404a5f;

  .link {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 20px 8px 0;
    cursor: pointer;
    transition: var(--animate-1);
    padding-left: 48px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }

    &.active {
      &::after {
        content: '';
        position: absolute;
        right: 0;
        width: 3px;
        height: 70%;
        background-color: var(--clr-light-green);
      }
    }
  }

  .link__text {
    font-size: var(--h4);
    font-weight: 400;
    color: var(--setting--menu-link-clr);
    padding-right: 8px;
  }

  .link__dashed {
    flex: 1 0 0;
    height: 3px;
    background-image: linear-gradient(
      to right,
      #8e919c 50%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 7px 1px;
    background-repeat: repeat-x;
  }

  .icon-arrow {
    width: 16px;
    height: 16px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
}
