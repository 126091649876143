.roles {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;

  &__item {
    padding: 2px 8px;
    background-color: rgb(0 0 0 / 7%);
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
  }
}
