.form-check {
  margin-bottom: 0;

  .form-check-input {
    width: 16px;
    height: 16px;

    & + .form-check-label {
      margin-left: 8px;
    }
  }
}
