.btn {
  font-family: "Roboto", sans-serif;
  position: relative;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1;
  height: 35px;
  transition: all 200ms linear;
  border-radius: 4px;
  min-width: 20px;
  letter-spacing: 1px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
  border: none;
  cursor: pointer;
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
  outline: 0;
  text-transform: capitalize;
}
