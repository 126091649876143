.lp-org-btn::before {
  margin-right: 0px !important;
}
fieldset {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  border: 1px solid silver !important;
  padding: 8px !important;
  border-radius: 4px !important;
  width: 100% !important;
}

legend {
  padding: 2px !important;
}


/* Forms styles */

.textarea-control {
  min-height: 136px !important;
}