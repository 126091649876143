.avatar {
  display: block;

  &--shadow {
    .avatar__inner {
      box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.129);
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 2px solid #fff;
    position: relative;
    border-radius: 999px;
    background-color: #fff;

    img {
      width: 100%;
      max-width: 100%;
      object-fit: cover;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
    }

    .ph__letter {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-accent-400, #3c7f84);
      background: var(--color-accent-75, #bae1e4);
      text-align: center;
      position: absolute;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  &--size-26 {
    .avatar__inner {
      width: 26px;
      height: 26px;
      max-width: 26px;
      max-height: 26px;
      min-width: 26px;
      min-height: 26px;
    }
  }

  &--size-28 {
    .avatar__inner {
      width: var(--layout-size-28);
      height: var(--layout-size-28);
      max-width: var(--layout-size-28);
      max-height: var(--layout-size-28);
      min-width: var(--layout-size-28);
      min-height: var(--layout-size-28);
    }
  }

  &--size-36 {
    .avatar__inner {
      width: 36px;
      height: 36px;
      max-width: 36px;
      max-height: 36px;
      min-width: 36px;
      min-height: 36px;
    }
  }

  &--size-44 {
    .avatar__inner {
      width: 44px;
      height: 44px;
      max-width: 44px;
      max-height: 44px;
      min-width: 44px;
      min-height: 44px;
    }
  }

  &--size-60 {
    .avatar__inner {
      width: 60px;
      height: 60px;
      max-width: 60px;
      max-height: 60px;
      min-width: 60px;
      min-height: 60px;
    }
  }
}
