// @mixin fonts() {
//   font-size: var(--#{type}-font-size);
//   font-weight: var(--#{type}-font-weight);
//   font-family: var(--#{type}-font-family);
//   line-height: var(--#{type}-line-height);
// }

$textSizes: "x-small", "label", "base", "large", "small", "h6", "h5", "h4", "h3",
  "h2", "h1";

$textWeight: (
  "regular": 400,
  "medium": 500,
  "semibold": 600,
  "bold": 700,
);

@each $type in $textSizes {
  .web-text-#{$type} {
    font-size: var(--#{$type}-regular-font-size);
  }
}

@each $type, $val in $textWeight {
  .font-#{$val} {
    font-weight: var(--#{$type}-font-weight);
    font-weight: #{$val};
  }
}
