.loader__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;

  .loader {
    font-size: var(--h3);
    color: #245a6c;
    font-weight: 600;
  }
}
