.form__group {
  &--input {
    margin-bottom: 12px;
  }

  &--button {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 8px;

    &.button-inline-left {
      .form__group__element {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &.button-inline-right {
      .form__group__element {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
      }
    }
  }

  &__label {
    color: var(--text-clr-1);
    font-size: var(--h6);
    margin-bottom: 8px;
  }

  &__element {
    display: block;
    position: relative;
  }

  &__error {
    margin-top: 4px;
    line-height: 1;

    .text__error {
      color: var(--error-clr);
      font-size: var(--h6);
    }
  }

  input[type="email"],
  input[type="number"],
  input[type="text"],
  input[type="password"] {
    height: 38px !important;
  }

  &.input-h-auto {
    input[type="email"],
    input[type="number"],
    input[type="text"],
    input[type="password"] {
      height: auto !important;
    }
  }
}
