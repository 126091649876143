.line__input__group.custom {
  display: block;
  border-bottom: none;

  &:focus-within {
    .inner {
      border-bottom-color: #245a6c;
    }
  }

  .inner {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #adb5bd;
    width: 100%;
  }
}
