.ui__card {
  border: 0.5px solid #b0c5e5;
  background-color: #fff;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.031);
  border-radius: 5px;
  padding: 8px;

  &__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      margin-right: 0 !important;
      margin-left: 8px;
    }
  }

  &__header {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ui__card__img {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .img__wrapper {
        width: 40px;

        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .title {
      margin-bottom: 8px;
      color: #3b4263;
      font-size: var(--h3);
      font-weight: 700;
    }

    .sub-title {
      color: #3b4263;
      font-size: var(--h5);
      font-weight: 600;
    }
  }

  &__body {
    color: #3b4263;
    font-size: var(--h5);
    font-weight: 400;
    text-align: center;
  }
}

.ui__card_wrapper {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
}