.settings__container {
  display: block;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .start {
      display: flex;
      align-items: center;

      .icon {
        width: 26px;
        margin-right: 16px;

        img {
          width: 100%;
          max-width: 100%;
        }
      }

      .title {
        font-size: var(--page-title-lg);
        font-weight: 600;
        color: var(--text-clr-gray-dark);
        line-height: 1.2;
      }
    }
  }

  &__body {
    display: flex;
    width: 100%;

    .sidebar__wrapper {
      width: auto;
    }

    .content {
      flex: 1 1 0;
      padding-left: 20px;
    }
  }

  .divided {
    width: 1px;
    height: 20px;
    margin: 0 16px;
    background-color: var(--clr-gray-opt-40);
  }
}
