.main__layout__wrapper {
  display: grid;
  grid-template: "sidebar mainLayout";
  background-color: #e6ecee;
  transition: var(--animate-1);
  grid-auto-columns: var(--nav-width) 1fr;
  margin-left: calc(var(--nav-width) * -1);

  &.opened-side-menu {
    margin-left: 0;

    .main__layout {
      border-top-left-radius: var(--layout-border-radius-16);
      border-bottom-left-radius: var(--layout-border-radius-16);
    }
  }
}

.main__layout {
  min-height: inherit;
  padding-top: var(--nav-height, 102px);
  overflow: hidden;
  grid-area: mainLayout;
  position: relative;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: var(--white);
  box-shadow: -2px 5px 13px 0 #cedade;

  &__contents {
    // margin-left: var(--nav-width);
    min-height: calc(100vh - var(--nav-height));
  }
}

.main__sidebar {
  grid-area: sidebar;
}
