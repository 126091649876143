/**
 * Do not edit directly
 * Generated on Mon, 26 Jun 2023 06:12:28 GMT
 */

:root {
  --button-primary-active: #56b5bc;
  --button-primary-hover: #3c7f84;
  --button-primary-hover-alt: #eef8f8;
  --button-primary-click: #bae1e4;
  --button-secondary-active: #ef8b6d;
  --button-secondary-hover: #a7614c;
  --button-secondary-hover-alt: #fdf3f0;
  --button-secondary-click: #f8cfc3;
  --button-disabled: #c1c4ce;
  --button-border-radius-sm: 0.286rem;
  --button-border-radius-xxxl: 1.714rem;
  --button-border-width-sm: 1px;
  --button-border-primary-active-color: #56b5bc;
  --button-border-primary-active-width: 1;
  --button-border-primary-active-style: solid;
  --button-border-primary-hover-color: #3c7f84;
  --button-border-primary-hover-width: 1;
  --button-border-primary-hover-style: solid;
  --button-border-secondary-active-color: #ef8b6d;
  --button-border-secondary-active-width: 1;
  --button-border-secondary-active-style: solid;
  --button-border-secondary-hover-color: #a7614c;
  --button-border-secondary-hover-width: 1;
  --button-border-secondary-hover-style: solid;
  --button-border-success-active-color: #79c131;
  --button-border-success-active-width: 1;
  --button-border-success-active-style: solid;
  --button-border-success-hover-color: #558722;
  --button-border-success-hover-width: 1;
  --button-border-success-hover-style: solid;
  --button-border-danger-active-color: #ed3030;
  --button-border-danger-active-width: 1;
  --button-border-danger-active-style: solid;
  --button-border-danger-hover-color: #a62222;
  --button-border-danger-hover-width: 1;
  --button-border-danger-hover-style: solid;
  --button-text-white: #fff;
  --button-success-active: #79c131;
  --button-success-hover: #558722;
  --button-success-hover-alt: #f2f9ea;
  --button-success-click: #c8e6ab;
  --button-danger-active: #ed3030;
  --button-danger-hover: #a62222;
  --button-danger-hover-alt: #fdeaea;
  --button-danger-click: #f8aaaa;
  --button-info-active: #1eaaf9;
  --button-info-hover: #1577ae;
  --button-info-hover-alt: #e9f7fe;
  --button-info-click: #a3dcfd;
  --btn-spacing-4: 0.286rem;
  --btn-spacing-8: 0.571rem;
  --btn-spacing-12: 0.857rem;
  --btn-spacing-16: 1.143rem;
  --btn-spacing-20: 1.429rem;
  --btn-spacing-24: 1.714rem;
  --h1-regular-font-family: Roboto;
  --h1-regular-font-weight: Regular;
  --h1-regular-line-height: 140%;
  --h1-regular-font-size: 2.571rem;
  --h1-medium-font-family: Roboto;
  --h1-medium-font-weight: Medium;
  --h1-medium-line-height: 140%;
  --h1-medium-font-size: 2.571rem;
  --h1-semi-bold-font-family: Roboto;
  --h1-semi-bold-font-weight: SemiBold;
  --h1-semi-bold-line-height: 140%;
  --h1-semi-bold-font-size: 2.571rem;
  --h1-bold-font-family: Roboto;
  --h1-bold-font-weight: Bold;
  --h1-bold-line-height: 140%;
  --h1-bold-font-size: 2.571rem;
  --h2-regular-font-family: Roboto;
  --h2-regular-font-weight: Regular;
  --h2-regular-line-height: 140%;
  --h2-regular-font-size: 2.286rem;
  --h2-medium-font-family: Roboto;
  --h2-medium-font-weight: Medium;
  --h2-medium-line-height: 140%;
  --h2-medium-font-size: 2.286rem;
  --h2-semi-bold-font-family: Roboto;
  --h2-semi-bold-font-weight: SemiBold;
  --h2-semi-bold-line-height: 140%;
  --h2-semi-bold-font-size: 2.286rem;
  --h2-bold-font-family: Roboto;
  --h2-bold-font-weight: Bold;
  --h2-bold-line-height: 140%;
  --h2-bold-font-size: 2.286rem;
  --h3-regular-font-family: Roboto;
  --h3-regular-font-weight: Regular;
  --h3-regular-line-height: 140%;
  --h3-regular-font-size: 2.071rem;
  --h3-medium-font-family: Roboto;
  --h3-medium-font-weight: Medium;
  --h3-medium-line-height: 140%;
  --h3-medium-font-size: 2.071rem;
  --h3-semi-bold-font-family: Roboto;
  --h3-semi-bold-font-weight: SemiBold;
  --h3-semi-bold-line-height: 140%;
  --h3-semi-bold-font-size: 2.071rem;
  --h3-bold-font-family: Roboto;
  --h3-bold-font-weight: Bold;
  --h3-bold-line-height: 140%;
  --h3-bold-font-size: 2.071rem;
  --h4-regular-font-family: Roboto;
  --h4-regular-font-weight: Regular;
  --h4-regular-line-height: 140%;
  --h4-regular-font-size: 1.857rem;
  --h4-medium-font-family: Roboto;
  --h4-medium-font-weight: Medium;
  --h4-medium-line-height: 140%;
  --h4-medium-font-size: 1.857rem;
  --h4-semi-bold-font-family: Roboto;
  --h4-semi-bold-font-weight: SemiBold;
  --h4-semi-bold-line-height: 140%;
  --h4-semi-bold-font-size: 1.857rem;
  --h4-bold-font-family: Roboto;
  --h4-bold-font-weight: Bold;
  --h4-bold-line-height: 140%;
  --h4-bold-font-size: 1.857rem;
  --h5-regular-font-family: Roboto;
  --h5-regular-font-weight: Regular;
  --h5-regular-line-height: 140%;
  --h5-regular-font-size: 1.643rem;
  --h5-medium-font-family: Roboto;
  --h5-medium-font-weight: Medium;
  --h5-medium-line-height: 140%;
  --h5-medium-font-size: 1.643rem;
  --h5-semi-bold-font-family: Roboto;
  --h5-semi-bold-font-weight: SemiBold;
  --h5-semi-bold-line-height: 140%;
  --h5-semi-bold-font-size: 1.643rem;
  --h5-bold-font-family: Roboto;
  --h5-bold-font-weight: Bold;
  --h5-bold-line-height: 140%;
  --h5-bold-font-size: 1.643rem;
  --h6-regular-font-family: Roboto;
  --h6-regular-font-weight: Regular;
  --h6-regular-line-height: 140%;
  --h6-regular-font-size: 1.429rem;
  --h6-medium-font-family: Roboto;
  --h6-medium-font-weight: Medium;
  --h6-medium-line-height: 140%;
  --h6-medium-font-size: 1.429rem;
  --h6-semi-bold-font-family: Roboto;
  --h6-semi-bold-font-weight: SemiBold;
  --h6-semi-bold-line-height: 140%;
  --h6-semi-bold-font-size: 1.429rem;
  --h6-bold-font-family: Roboto;
  --h6-bold-font-weight: Bold;
  --h6-bold-line-height: 140%;
  --h6-bold-font-size: 1.429rem;
  --large-regular-font-family: Roboto;
  --large-regular-font-weight: Regular;
  --large-regular-line-height: 150%;
  --large-regular-font-size: 1.286rem;
  --large-medium-font-family: Roboto;
  --large-medium-font-weight: Medium;
  --large-medium-line-height: 150%;
  --large-medium-font-size: 1.286rem;
  --large-semi-bold-font-family: Roboto;
  --large-semi-bold-font-weight: SemiBold;
  --large-semi-bold-line-height: 150%;
  --large-semi-bold-font-size: 1.286rem;
  --large-bold-font-family: Roboto;
  --large-bold-font-weight: Bold;
  --large-bold-line-height: 150%;
  --large-bold-font-size: 1.286rem;
  --base-regular-font-family: Roboto;
  --base-regular-font-weight: Regular;
  --base-regular-line-height: 150%;
  --base-regular-font-size: 1.143rem;
  --base-medium-font-family: Roboto;
  --base-medium-font-weight: Medium;
  --base-medium-line-height: 150%;
  --base-medium-font-size: 1.143rem;
  --base-semi-bold-font-family: Roboto;
  --base-semi-bold-font-weight: SemiBold;
  --base-semi-bold-line-height: 150%;
  --base-semi-bold-font-size: 1.143rem;
  --base-bold-font-family: Roboto;
  --base-bold-font-weight: Bold;
  --base-bold-line-height: 150%;
  --base-bold-font-size: 1.143rem;
  --small-regular-font-family: Roboto;
  --small-regular-font-weight: Regular;
  --small-regular-line-height: 150%;
  --small-regular-font-size: 1.000rem;
  --small-medium-font-family: Roboto;
  --small-medium-font-weight: Medium;
  --small-medium-line-height: 150%;
  --small-medium-font-size: 1.000rem;
  --small-semi-bold-font-family: Roboto;
  --small-semi-bold-font-weight: SemiBold;
  --small-semi-bold-line-height: 150%;
  --small-semi-bold-font-size: 1.000rem;
  --small-bold-font-family: Roboto;
  --small-bold-font-weight: Bold;
  --small-bold-line-height: 150%;
  --small-bold-font-size: 1.000rem;
  --small-courier-regular-font-family: Courier Prime;
  --small-courier-regular-font-weight: Regular;
  --small-courier-regular-line-height: 140%;
  --small-courier-regular-font-size: 1.000rem;
  --small-courier-regular-letter-spacing: 0%;
  --small-courier-regularitalic-font-family: Courier Prime;
  --small-courier-regularitalic-font-weight: Italic;
  --small-courier-regularitalic-line-height: 140%;
  --small-courier-regularitalic-font-size: 1.000rem;
  --small-courier-regularitalic-letter-spacing: 0%;
  --small-courier-bold-font-family: Courier Prime;
  --small-courier-bold-font-weight: Bold;
  --small-courier-bold-line-height: 150%;
  --small-courier-bold-font-size: 1.000rem;
  --small-courier-bold-letter-spacing: 0%;
  --label-regular-font-family: Roboto;
  --label-regular-font-weight: Regular;
  --label-regular-line-height: 140%;
  --label-regular-font-size: 0.929rem;
  --label-medium-font-family: Roboto;
  --label-medium-font-weight: Medium;
  --label-medium-line-height: 140%;
  --label-medium-font-size: 0.929rem;
  --label-semi-bold-font-family: Roboto;
  --label-semi-bold-font-weight: SemiBold;
  --label-semi-bold-line-height: 140%;
  --label-semi-bold-font-size: 0.929rem;
  --label-bold-font-family: Roboto;
  --label-bold-font-weight: Bold;
  --label-bold-line-height: 140%;
  --label-bold-font-size: 0.929rem;
  --x-small-regular-font-family: Roboto;
  --x-small-regular-font-weight: Regular;
  --x-small-regular-line-height: 140%;
  --x-small-regular-font-size: 0.786rem;
  --x-small-medium-font-family: Roboto;
  --x-small-medium-font-weight: Medium;
  --x-small-medium-line-height: 140%;
  --x-small-medium-font-size: 0.786rem;
  --x-small-semi-bold-font-family: Roboto;
  --x-small-semi-bold-font-weight: SemiBold;
  --x-small-semi-bold-line-height: 140%;
  --x-small-semi-bold-font-size: 0.786rem;
  --x-small-bold-font-family: Roboto;
  --x-small-bold-font-weight: Bold;
  --x-small-bold-line-height: 140%;
  --x-small-bold-font-size: 0.786rem;
  --font-default-color: #061216;
  --font-default-white: #fff;
  --font-default-black: #000000;
  --font-tint-01: #c1c4ce;
  --font-tint-02: #b2b5c2;
  --font-tint-03: #a5a9b8;
  --font-tint-04: #969bac;
  --font-color-shade-primary: #012732;
  --font-color-shade-secondary: #a7614c;
  --font-color-shade-accent: #3c7f84;
  --font-color-shade-success: #558722;
  --font-color-shade-danger: #a62222;
  --font-color-shade-warning: #b38720;
  --font-color-shade-info: #1577ae;
  --font-underline: underline;
  --font-uppercase: uppercase;
  --layout-spacing-2: 0.143rem;
  --layout-spacing-4: 0.286rem;
  --layout-spacing-8: 0.571rem;
  --layout-spacing-12: 0.857rem;
  --layout-spacing-16: 1.143rem;
  --layout-spacing-20: 1.429rem;
  --layout-spacing-24: 1.714rem;
  --layout-spacing-28: 2.000rem;
  --layout-spacing-32: 2.286rem;
  --layout-spacing-36: 2.571rem;
  --layout-spacing-40: 2.857rem;
  --layout-spacing-44: 3.143rem;
  --layout-spacing-48: 3.429rem;
  --layout-spacing-52: 3.714rem;
  --layout-spacing-56: 4.000rem;
  --layout-spacing-60: 4.286rem;
  --layout-spacing-minus-2: -0.143rem;
  --layout-spacing-minus-4: -0.286rem;
  --layout-spacing-minus-8: -0.571rem;
  --layout-spacing-minus-12: -0.857rem;
  --layout-spacing-minus-16: -1.143rem;
  --layout-spacing-minus-20: -1.429rem;
  --layout-spacing-minus-24: -1.714rem;
  --layout-spacing-minus-28: -2.000rem;
  --layout-spacing-minus-32: -2.286rem;
  --layout-spacing-minus-36: -2.571rem;
  --layout-spacing-minus-40: -2.857rem;
  --layout-spacing-minus-44: -3.143rem;
  --layout-spacing-minus-48: -3.429rem;
  --layout-spacing-minus-52: -3.714rem;
  --layout-spacing-minus-56: -4.000rem;
  --layout-spacing-minus-60: -4.286rem;
  --layout-border-radius-4: 0.286rem;
  --layout-border-radius-8: 0.571rem;
  --layout-border-radius-12: 0.857rem;
  --layout-border-radius-16: 1.143rem;
  --layout-border-radius-20: 1.429rem;
  --layout-border-radius-24: 1.714rem;
  --layout-border-radius-28: 2.000rem;
  --layout-border-radius-32: 2.286rem;
  --layout-background-n10: #fafafb;
  --layout-background-n20: #f5f5f7;
  --layout-background-n30: #ebecef;
  --layout-background-n40: #dfe0e5;
  --layout-background-n50: #c1c4ce;
  --layout-background-primary: #e6ebed;
  --layout-background-secondary: #fdf3f0;
  --layout-background-accent: #eef8f8;
  --layout-background-white: #fff;
  --layout-size-4: 0.286rem;
  --layout-size-8: 0.571rem;
  --layout-size-12: 0.857rem;
  --layout-size-16: 1.143rem;
  --layout-size-20: 1.429rem;
  --layout-size-24: 1.714rem;
  --layout-size-28: 2.000rem;
  --layout-size-32: 2.286rem;
  --layout-size-36: 2.571rem;
  --layout-size-40: 2.857rem;
  --layout-size-44: 3.143rem;
  --layout-size-48: 3.429rem;
  --layout-size-52: 3.714rem;
  --layout-size-56: 4.000rem;
  --layout-size-60: 4.286rem;
  --layout-size-100: 7.143rem;
  --layout-size-200: 14.286rem;
  --layout-border-width-01: 1px;
  --layout-border-width-02: 2px;
  --layout-border-width-03: 3px;
  --layout-border-width-04: 4px;
  --layout-box-shadow-accent-x: 0;
  --layout-box-shadow-accent-y: 8;
  --layout-box-shadow-accent-blur: 18;
  --layout-box-shadow-accent-spread: 50;
  --layout-box-shadow-accent-color: #9dd4d833;
  --layout-box-shadow-accent-type: dropShadow;
  --layout-box-shadow-secondary-x: 0;
  --layout-box-shadow-secondary-y: 8;
  --layout-box-shadow-secondary-blur: 18;
  --layout-box-shadow-secondary-spread: 50;
  --layout-box-shadow-secondary-color: #f6bcaa33;
  --layout-box-shadow-secondary-type: dropShadow;
  --layout-box-shadow-warning-x: 0;
  --layout-box-shadow-warning-y: 8;
  --layout-box-shadow-warning-blur: 18;
  --layout-box-shadow-warning-spread: 50;
  --layout-box-shadow-warning-color: #ffdb8633;
  --layout-box-shadow-warning-type: dropShadow;
  --layout-box-shadow-info-x: 0;
  --layout-box-shadow-info-y: 8;
  --layout-box-shadow-info-blur: 18;
  --layout-box-shadow-info-spread: 50;
  --layout-box-shadow-info-color: #7dcefc33;
  --layout-box-shadow-info-type: dropShadow;
  --layout-box-shadow-danger-x: 0;
  --layout-box-shadow-danger-y: 8;
  --layout-box-shadow-danger-blur: 18;
  --layout-box-shadow-danger-spread: 50;
  --layout-box-shadow-danger-color: #f5878733;
  --layout-box-shadow-danger-type: dropShadow;
  --layout-box-shadow-success-10-x: 0;
  --layout-box-shadow-success-10-y: 4;
  --layout-box-shadow-success-10-blur: 18;
  --layout-box-shadow-success-10-spread: 2;
  --layout-box-shadow-success-10-color: #b1db8833;
  --layout-box-shadow-success-10-type: dropShadow;
  --layout-box-shadow-success-20-x: 0;
  --layout-box-shadow-success-20-y: 8;
  --layout-box-shadow-success-20-blur: 18;
  --layout-box-shadow-success-20-spread: 50;
  --layout-box-shadow-success-20-color: #b1db8833;
  --layout-box-shadow-success-20-type: dropShadow;
  --layout-box-shadow-primary-5-x: 0;
  --layout-box-shadow-primary-5-y: 8;
  --layout-box-shadow-primary-5-blur: 18;
  --layout-box-shadow-primary-5-spread: 10;
  --layout-box-shadow-primary-5-color: #2d59660d;
  --layout-box-shadow-primary-5-type: dropShadow;
  --layout-box-shadow-primary-10-x: -3;
  --layout-box-shadow-primary-10-y: 8;
  --layout-box-shadow-primary-10-blur: 15;
  --layout-box-shadow-primary-10-spread: 0;
  --layout-box-shadow-primary-10-color: #2d596614;
  --layout-box-shadow-primary-10-type: dropShadow;
  --layout-box-shadow-primary-20-x: 0;
  --layout-box-shadow-primary-20-y: 8;
  --layout-box-shadow-primary-20-blur: 18;
  --layout-box-shadow-primary-20-spread: 50;
  --layout-box-shadow-primary-20-color: #6c8b9433;
  --layout-box-shadow-primary-20-type: dropShadow;
  --layout-opacity-10: 10%;
  --layout-opacity-20: 20%;
  --layout-opacity-30: 30%;
  --layout-opacity-40: 40%;
  --layout-opacity-50: 50%;
  --layout-opacity-60: 60%;
  --layout-opacity-70: 70%;
  --layout-opacity-80: 80%;
  --layout-opacity-90: 90%;
  --layout-opacity-100: 100%;
  --layout-dashed-primary-01-color: #97adb4;
  --layout-dashed-primary-01-width: 1;
  --layout-dashed-primary-01-style: dashed;
  --layout-dashed-accent-01-color: #bae1e4;
  --layout-dashed-accent-01-width: 1;
  --layout-dashed-accent-01-style: dashed;
  --layout-solid-primary-01-color: #97adb4;
  --layout-solid-primary-01-width: 1;
  --layout-solid-primary-01-style: solid;
  --layout-solid-accent-01-color: #bae1e4;
  --layout-solid-accent-01-width: 1;
  --layout-solid-accent-01-style: solid;
  --color-neutral-10: #fafafb;
  --color-neutral-20: #f5f5f7;
  --color-neutral-30: #ebecef;
  --color-neutral-40: #dfe0e5;
  --color-neutral-50: #c1c4ce;
  --color-neutral-60: #b2b5c2;
  --color-neutral-70: #a5a9b8;
  --color-neutral-80: #969bac;
  --color-neutral-90: #878da0;
  --color-neutral-100: #787e95;
  --color-neutral-200: #697089;
  --color-neutral-300: #5a627d;
  --color-neutral-400: #4e5673;
  --color-neutral-500: #3f4867;
  --color-neutral-600: #323c5d;
  --color-neutral-700: #212b50;
  --color-neutral-800: #121d44;
  --color-neutral-900: #05113a;
  --color-primary-50: #e6ebed;
  --color-primary-75: #97adb4;
  --color-primary-100: #6c8b94;
  --color-primary-200: #2d5966;
  --color-primary-300: #023747;
  --color-primary-400: #012732;
  --color-primary-500: #01222b;
  --color-secondary-50: #fdf3f0;
  --color-secondary-75: #f8cfc3;
  --color-secondary-100: #f6bcaa;
  --color-secondary-200: #f29f86;
  --color-secondary-300: #ef8b6d;
  --color-secondary-400: #a7614c;
  --color-secondary-500: #925542;
  --color-accent-50: #eef8f8;
  --color-accent-75: #bae1e4;
  --color-accent-100: #9dd4d8;
  --color-accent-200: #73c2c7;
  --color-accent-300: #56b5bc;
  --color-accent-400: #3c7f84;
  --color-accent-500: #346e73;
  --color-success-50: #f2f9ea;
  --color-success-75: #c8e6ab;
  --color-success-100: #b1db88;
  --color-success-200: #90cc54;
  --color-success-300: #79c131;
  --color-success-400: #558722;
  --color-success-500: #4a761e;
  --color-warning-50: #fff9ea;
  --color-warning-75: #ffe6a9;
  --color-warning-100: #ffdb86;
  --color-warning-200: #ffcc52;
  --color-warning-300: #ffc12e;
  --color-warning-400: #b38720;
  --color-warning-500: #9c761c;
  --color-danger-50: #fdeaea;
  --color-danger-75: #f8aaaa;
  --color-danger-100: #f58787;
  --color-danger-200: #f05353;
  --color-danger-300: #ed3030;
  --color-danger-400: #a62222;
  --color-danger-500: #911d1d;
  --color-info-50: #e9f7fe;
  --color-info-75: #a3dcfd;
  --color-info-100: #7dcefc;
  --color-info-200: #44b8fa;
  --color-info-300: #1eaaf9;
  --color-info-400: #1577ae;
  --color-info-500: #126898;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-g-primary-45: #000000;
  --color-g-primary-90: #000000;
  --color-g-primary-180: #000000;
  --color-g-primary-270: #000000;
  --color-g-normal-180: #000000;
  --color-g-secondary-45: #000000;
  --color-g-secondary-90: #000000;
  --color-g-secondary-180: #000000;
  --color-g-secondary-270: #000000;
  --border-radius-4: 0.286rem;
  --border-radius-8: 0.571rem;
  --border-radius-12: 0.857rem;
  --border-radius-16: 1.143rem;
  --border-radius-20: 1.429rem;
  --border-radius-24: 1.714rem;
  --border-radius-28: 2.000rem;
  --border-radius-32: 2.286rem;
  --ref-spacing-2: 0.143rem;
  --ref-spacing-4: 0.286rem;
  --ref-spacing-8: 0.571rem;
  --ref-spacing-12: 0.857rem;
  --ref-spacing-16: 1.143rem;
  --ref-spacing-20: 1.429rem;
  --ref-spacing-24: 1.714rem;
  --ref-spacing-28: 2.000rem;
  --ref-spacing-32: 2.286rem;
  --ref-spacing-36: 2.571rem;
  --ref-spacing-40: 2.857rem;
  --ref-spacing-44: 3.143rem;
  --ref-spacing-48: 3.429rem;
  --ref-spacing-52: 3.714rem;
  --ref-spacing-56: 4.000rem;
  --ref-spacing-60: 4.286rem;
  --ref-spacing-64: 4.571rem;
  --ref-spacing-68: 4.857rem;
  --ref-spacing-72: 5.143rem;
  --ref-spacing-76: 5.429rem;
  --ref-spacing-80: 5.714rem;
  --ref-size-4: 0.286rem;
  --ref-size-8: 0.571rem;
  --ref-size-12: 0.857rem;
  --ref-size-16: 1.143rem;
  --ref-size-20: 1.429rem;
  --ref-size-24: 1.714rem;
  --ref-size-28: 2.000rem;
  --ref-size-32: 2.286rem;
  --ref-size-36: 2.571rem;
  --ref-size-40: 2.857rem;
  --ref-size-44: 3.143rem;
  --ref-size-48: 3.429rem;
  --ref-size-52: 3.714rem;
  --ref-size-56: 4.000rem;
  --ref-size-60: 4.286rem;
  --ref-size-64: 4.571rem;
  --ref-size-68: 4.857rem;
  --ref-size-72: 5.143rem;
  --ref-size-76: 5.429rem;
  --ref-size-80: 5.714rem;
  --ref-size-84: 6.000rem;
  --ref-size-88: 6.286rem;
  --ref-size-92: 6.571rem;
  --ref-size-96: 6.857rem;
  --ref-size-100: 7.143rem;
  --ref-size-200: 14.286rem;
  --ref-size-300: 21.429rem;
  --ref-size-400: 28.571rem;
  --ref-size-500: 35.714rem;
  --ref-size-600: 42.857rem;
  --ref-size-700: 50.000rem;
  --ref-size-800: 57.143rem;
  --ref-size-900: 64.286rem;
  --ref-size-1000: 71.429rem;
  --font-weight-bold: Bold;
  --font-weight-regular: Regular;
  --font-weight-semi-bold: SemiBold;
  --font-weight-medium: Medium;
  --font-weight-italic: Italic;
  --border-width-01: 1px;
  --border-width-02: 2px;
  --border-width-03: 3px;
  --border-width-04: 4px;
  --opacity-10: 10%;
  --opacity-20: 20%;
  --opacity-30: 30%;
  --opacity-40: 40%;
  --opacity-50: 50%;
  --opacity-60: 60%;
  --opacity-70: 70%;
  --opacity-80: 80%;
  --opacity-90: 90%;
  --opacity-100: 100%;
  --text-case-uppercase: uppercase;
  --text-case-capitalize: capitalize;
  --text-decoration-underline: underline;
  --text-decoration-line-through: line-through;
  --body: #061216;
  --white: #fff;
  --black: #000000;
  --neutral-n0: #ffffff;
  --neutral-n10: #fafafb;
  --neutral-n20: #f5f5f7;
  --neutral-n30: #ebecef;
  --neutral-n40: #dfe0e5;
  --neutral-n50: #c1c4ce;
  --neutral-n60: #b2b5c2;
  --neutral-n70: #a5a9b8;
  --neutral-n80: #969bac;
  --neutral-n90: #878da0;
  --neutral-n100: #787e95;
  --neutral-n200: #697089;
  --neutral-n300: #5a627d;
  --neutral-n400: #4e5673;
  --neutral-n500: #3f4867;
  --neutral-n600: #323c5d;
  --neutral-n700: #212b50;
  --neutral-n800: #121d44;
  --neutral-n900: #05113a;
  --primary-5-x: 0;
  --primary-5-y: 8;
  --primary-5-blur: 18;
  --primary-5-spread: 10;
  --primary-5-color: #2d59660d;
  --primary-5-type: dropShadow;
  --primary-20-x: 0;
  --primary-20-y: 8;
  --primary-20-blur: 18;
  --primary-20-spread: 50;
  --primary-20-color: #6c8b9433;
  --primary-20-type: dropShadow;
  --primary-10-left-x: -3;
  --primary-10-left-y: 8;
  --primary-10-left-blur: 15;
  --primary-10-left-spread: 0;
  --primary-10-left-color: #2d596614;
  --primary-10-left-type: dropShadow;
  --primary-p50: #e6ebed;
  --primary-p75: #97adb4;
  --primary-p100: #6c8b94;
  --primary-p200: #2d5966;
  --primary-p300: #023747;
  --primary-p400: #012732;
  --primary-p500: #01222b;
  --secondary-20-x: 0;
  --secondary-20-y: 8;
  --secondary-20-blur: 18;
  --secondary-20-spread: 50;
  --secondary-20-color: #f6bcaa33;
  --secondary-20-type: dropShadow;
  --secondary-s50: #fdf3f0;
  --secondary-s75: #f8cfc3;
  --secondary-s100: #f6bcaa;
  --secondary-s200: #f29f86;
  --secondary-s300: #ef8b6d;
  --secondary-s400: #a7614c;
  --secondary-s500: #925542;
  --accent-20-x: 0;
  --accent-20-y: 8;
  --accent-20-blur: 18;
  --accent-20-spread: 50;
  --accent-20-color: #9dd4d833;
  --accent-20-type: dropShadow;
  --accent-a50: #eef8f8;
  --accent-a75: #bae1e4;
  --accent-a100: #9dd4d8;
  --accent-a200: #73c2c7;
  --accent-a300: #56b5bc;
  --accent-a400: #3c7f84;
  --accent-a500: #346e73;
  --danger-20-x: 0;
  --danger-20-y: 8;
  --danger-20-blur: 18;
  --danger-20-spread: 50;
  --danger-20-color: #f5878733;
  --danger-20-type: dropShadow;
  --danger-d50: #fdeaea;
  --danger-d75: #f8aaaa;
  --danger-d100: #f58787;
  --danger-d200: #f05353;
  --danger-d300: #ed3030;
  --danger-d400: #a62222;
  --danger-d500: #911d1d;
  --warning-20-x: 0;
  --warning-20-y: 8;
  --warning-20-blur: 18;
  --warning-20-spread: 50;
  --warning-20-color: #ffdb8633;
  --warning-20-type: dropShadow;
  --warning-w50: #fff9ea;
  --warning-w75: #ffe6a9;
  --warning-w100: #ffdb86;
  --warning-w200: #ffcc52;
  --warning-w300: #ffc12e;
  --warning-w400: #b38720;
  --warning-w500: #9c761c;
  --info-20-x: 0;
  --info-20-y: 8;
  --info-20-blur: 18;
  --info-20-spread: 50;
  --info-20-color: #7dcefc33;
  --info-20-type: dropShadow;
  --success-10-x: 0;
  --success-10-y: 4;
  --success-10-blur: 18;
  --success-10-spread: 2;
  --success-10-color: #b1db8833;
  --success-10-type: dropShadow;
  --success-20-x: 0;
  --success-20-y: 8;
  --success-20-blur: 18;
  --success-20-spread: 50;
  --success-20-color: #b1db8833;
  --success-20-type: dropShadow;
  --success-s50: #f2f9ea;
  --success-s75: #c8e6ab;
  --success-s100: #b1db88;
  --success-s200: #90cc54;
  --success-s300: #79c131;
  --success-s400: #558722;
  --success-s500: #4a761e;
  --info-i50: #e9f7fe;
  --info-i75: #a3dcfd;
  --info-i100: #7dcefc;
  --info-i200: #44b8fa;
  --info-i300: #1eaaf9;
  --info-i400: #1577ae;
  --info-i500: #126898;
  --font-family-roboto: Roboto;
  --font-family-courier: Courier Prime;
  --line-height-140: 140%;
  --line-height-150: 150%;
  --font-size-11px: 0.786rem;
  --font-size-13px: 0.929rem;
  --font-size-14px: 1.000rem;
  --font-size-16px: 1.143rem;
  --font-size-18px: 1.286rem;
  --font-size-20px: 1.429rem;
  --font-size-23px: 1.643rem;
  --font-size-26px: 1.857rem;
  --font-size-29px: 2.071rem;
  --font-size-32px: 2.286rem;
  --font-size-36px: 2.571rem;
  --letter-spacing-0: 0%;
  --letter-spacing-10: 5%;
  --gradient-primary-45: linear-gradient(45deg, #fff 0%, #56b5bc 100%);
  --gradient-primary-90: linear-gradient(90deg, #fff 0%, #56b5bc 100%);
  --gradient-primary-180: linear-gradient(180deg, #c1c4ce 0%, #56b5bc 100%);
  --gradient-primary-270: linear-gradient(270deg, #fff 0%, #56b5bc 100%);
  --gradient-normal-180: linear-gradient(180deg, #fafafb 0%, #c1c4ce 100%);
  --gradient-secondary-45: linear-gradient(45deg, #fff 0%, #ef8b6d 100%);
  --gradient-secondary-90: linear-gradient(90deg, #fff 0%, #ef8b6d 100%);
  --gradient-secondary-180: linear-gradient(180deg, #fff 0%, #ef8b6d 100%);
  --gradient-secondary-270: linear-gradient(270deg, #fff 0%, #ef8b6d 100%);
  --border-line-accent-1-color: #bae1e4;
  --border-line-accent-1-width: 1;
  --border-line-accent-1-style: solid;
  --border-line-primary-01-color: #97adb4;
  --border-line-primary-01-width: 1;
  --border-line-primary-01-style: solid;
  --border-dash-accent-01-color: #bae1e4;
  --border-dash-accent-01-width: 1;
  --border-dash-accent-01-style: dashed;
  --border-dash-primary-01-color: #97adb4;
  --border-dash-primary-01-width: 1;
  --border-dash-primary-01-style: dashed;
  --token-set-order-0: globals;
  --token-set-order-1: Core/__colors;
  --token-set-order-2: Core/__layout;
  --token-set-order-3: Core/__typography;
  --token-set-order-4: Components/__buttons;
}
