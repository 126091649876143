.profile__image__uploader {
  display: flex;
  flex-direction: column;

  .file__uploader__inner {
    min-height: 200px;
    height: 200px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: dashed 2px #f4d4c3;
    border-radius: 5px;
    overflow: hidden;
  }

  .error {
    margin-top: 4px;

    .message {
      color: red;
      font-size: var(--h5);
    }
  }

  input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }

  .image--preview {
    position: relative;

    img {
      width: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .default-text {
    text-align: center;
    font-size: var(--h5);
    color: #a4bfc8;
  }

  &:hover .hover-text {
    opacity: 1;
  }

  .hover-text {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--h5);
    color: #fff;
    text-align: center;
    z-index: 1;
    pointer-events: none;
    transition: var(--animate-1);
  }
}
