aside {
}

.disabled {
  pointer-events: none;
}

.menu-h-gap {
  padding-bottom: 22em;
}

.angle__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 72px;

  img {
    height: 100%;
    max-height: 100%;
  }
}

.menuTip,
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a i,
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a.sub_menu::after,
aside .menu-items li {
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.brighttheme-success {
  border: 2px solid #02cfb3 !important;
}

.brighttheme-error {
  border: 2px solid #f36861 !important;
}

.brighttheme-info {
  border: 2px solid #00b8d4 !important;
}

.brighttheme-notice {
  border: 2px solid #57899d !important;
}

.ui-pnotify-closer,
.ui-pnotify-sticker {
  margin-left: 0.4em;
  margin-top: -10px;
}

.menuTip {
  position: absolute;
  padding: 10px 15px;
  z-index: 9999999999999;
  left: 70px;
  opacity: 0;
  filter: alpha(opacity=0);
  background-color: rgba(0, 180, 241, 0.8);
  white-space: nowrap;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  font-size: 12px;
  -webkit-transform: translateX(8%);
  -moz-transform: translateX(8%);
  -ms-transform: translateX(8%);
  -o-transform: translateX(8%);
  transform: translateX(8%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.menuTip::after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: rgba(0, 180, 241, 0.8);
  border-width: 6px;
  margin-top: -6px;
}

aside {
  --top-space: 0;

  // background: #E6ECEE;
  position: relative;
  top: var(--top-space);
  left: 0;
  bottom: 0;
  height: calc(100vh - var(--top-space));
  display: block;
  z-index: 3;
  overflow: hidden;
}

aside .logo {
  width: 100%;
  height: auto;
  display: block;
  background: #006bb6;
  background-image: -webkit-radial-gradient(50% 50%, #03a9f4, #0295d7);
  background-image: radial-gradient(50% 50%, #03a9f4, #0295d7);
}

aside .logo .logo_link {
  display: block;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  padding: 20px 15px;
}

aside .menu-items::after,
aside .slide-menu .menu-items::after,
aside .slide-menu nav::after {
  content: "";
  display: table;
  clear: both;
}
aside .logo .logo_link img {
  width: 100%;
  max-width: none;
  vertical-align: middle;
  text-align: center;
}
aside .menu-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
  // background: #245a6c;
  height: 100%;
}
aside .h-m-menu-lineBreak,
aside .h-m-menu-mainTitle,
aside .h-m-menu-subTitle {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  float: left;
}
aside .menu-items {
}

.corner-radius {
  border-top-right-radius: 30px;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--nav-width) !important;

  .start {
    flex: 1 1 0px;
  }
  .center {
    padding: var(--layout-spacing-32) 0;
  }

  .end {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 16px;
  }
}

aside .menu-items::after {
}

aside .menu-items li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 18px;
  font-weight: 400 !important;

  display: flex;
  text-decoration: none !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  padding: var(--layout-spacing-4) 0;

  .nav-item,
  a {
    // color: rgba(36, 89, 107, 0.7);
    // padding: 14px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .icon__button__element {
      --icon-button-icon-color: #245a6c;
      border-radius: 50%;
    }

    i {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

aside .menu-items li {
  a:hover,
  .nav-item:hover {
    // background: #347084;
    // color: rgba(36, 89, 107, 0.9);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
    /* margin-left: 3px; */
    opacity: 0.7;
  }
}

aside .menu-items li {
  .nav-item:hover span,
  a:hover span {
    display: block;
  }
}

aside .menu-items li {
  .nav-item.active,
  a.active {
    // background: #347084;
    // color: rgba(36, 89, 107, 1);
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;

    /* margin-left: 3px; */

    .icon__button__element {
      --icon-button-icon-color: var(--color-accent-300, #56b5bc);
      background-color: #fff;
    }
  }
}

aside .slide-menu {
  position: relative;
  height: 100%;
}

aside .slide-menu nav {
  position: relative;
  height: 100%;
  width: 100%;
  padding-top: 40px;
}

/*//////////// menu tooltip color changes ////////////////*/
aside .slide-menu nav.tipWhite .menuTip {
  background: rgba(0, 180, 241, 0.8);
  color: #fff;
}
aside .slide-menu nav.tipWhite .menuTip::after {
  border-right-color: rgba(0, 180, 241, 0.8);
}
/*///////////////////////////////////////////////////////*/
aside .slide-menu .menu-items {
  position: relative;
  z-index: 1000;
  height: 100%;
}
aside .slide-menu .menu-items {
}
aside .slide-menu .menu-items::after {
}
aside .slide-menu .menu-panel {
  left: 61px;
  position: absolute;
  top: 60px;
  height: 100%;
  z-index: 0;
  background: #347084;
  display: none;
  width: 270px;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}
.scroll-textarea > .scroll-content > textarea,
.scroll-wrapper > .scroll-content {
  max-width: none !important;
  overflow: scroll !important;
  position: relative !important;
  top: 0;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a::after,
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li::after,
aside .h-m-menu-inner::after {
  content: "";
  display: table;
  clear: both;
}
aside .h-m-menu-inner {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 35px;
}
aside .h-m-menu-inner {
}
aside .h-m-menu-inner::after {
}
aside .h-m-menu-inner > .container-fluid {
  height: 100%;
}
aside .h-m-menu-inner > .container-fluid > li {
  height: -webkit-calc(100% - 70px);
  height: -moz-calc(100% - 70px);
  height: calc(100% - 70px);
}
aside .h-m-menu-inner > .container-fluid > li > .h-m-menu-menuItems {
  height: 100%;
  overflow-y: auto;
}
aside .h-m-menu-inner ul {
  margin: 0;
  padding: 10px;
  list-style-type: none;
}
aside .h-m-menu-inner ul ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
aside .h-m-menu-inner ul li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
aside .h-m-menu-inner ul li ul {
  margin: 15px 0 0;
  padding: 0;
  list-style-type: none;
}
aside .h-m-menu-inner ul li ul li {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: list-item;
  vertical-align: middle;
  cursor: pointer;
  background: 0 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li::after {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li:hover {
  background: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li:hover i {
  color: #00b4f1;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li:hover .mainTT {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  color: #00b4f1;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li:hover .mainTT span {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li:hover > .sub_menu::after {
  color: #00b4f1;
  -webkit-transform: translateX(5%);
  -moz-transform: translateX(5%);
  -ms-transform: translateX(5%);
  -o-transform: translateX(5%);
  transform: translateX(5%);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li.active {
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li.active i {
  color: #fccb5f;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li.active .mainTT {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  color: #fccb5f;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li.active .mainTT span {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li.active > .sub_menu::after {
  color: #fccb5f;
  -webkit-transform: translateX(5%);
  -moz-transform: translateX(5%);
  -ms-transform: translateX(5%);
  -o-transform: translateX(5%);
  transform: translateX(5%);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a {
  width: 100%;
  display: block;
  padding: 15px;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a::after {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a.sub_menu::after {
  content: ">";
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 18px;
  font-weight: 400 !important;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: 6px;
  float: right;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu .title::after,
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu::after {
  content: "";
  display: table;
  clear: both;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a i {
  /* font-family: menu, Arial, Helvetica, sans-serif !important; */
  font-size: 20px;
  /* font-weight: 400 !important; */
  display: inline-block;
  line-height: 28px;
  margin-right: 10px;
  width: 30px;
  vertical-align: middle;
  -webkit-transition: color 0.5s;
  -moz-transition: color 0.5s;
  transition: color 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a .mainTT,
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a .mainTT span {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a .mainTT {
  font-weight: 500 !important;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateY(8px);
  -moz-transform: translateY(8px);
  -ms-transform: translateY(8px);
  -o-transform: translateY(8px);
  transform: translateY(8px);
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li a .mainTT span {
  font-weight: 400 !important;
  font-size: 9px;
  display: block;
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  margin-top: 5px;
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu {
  margin-left: -15px;
  margin-right: -15px;
  margin: 0;
  padding: 0 1px;
  height: 100%;
  width: 100%;
  background: #347084;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  opacity: 0;
  filter: alpha(opacity=0);
  cursor: default;
  -webkit-transform: translateX(98%);
  -moz-transform: translateX(98%);
  -ms-transform: translateX(98%);
  -o-transform: translateX(98%);
  transform: translateX(98%);
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu::after {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu::after {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu.active {
  display: block;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu > li {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: list-item;
  vertical-align: middle;
  background: 0 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  filter: alpha(opacity=0);
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu > li:hover {
  background: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  transition: background 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu > li > a,
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  > li
  > a.sub_menu::after {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu > li:hover > a {
  color: #00b4f1;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  > li:hover
  .sub_menu::after {
  color: #00b4f1;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu > li > a {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  font-size: 13px;
  text-decoration: none;
  width: 100%;
  display: block;
  padding: 15px;
  transition: 0.5s;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  > li
  > a.sub_menu::after {
  top: 50%;
  right: 0;
  content: ">";
  font-family: Roboto, menu, Arial, Helvetica, sans-serif !important;
  font-size: 18px;
  font-weight: 400 !important;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: 0;
  float: right;
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu > li.active {
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  > li.active
  > a {
  color: #fccb5f;
  padding-left: 20px;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  > li.active
  > a.sub_menu::after {
  color: #fccb5f;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu .title {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  margin-top: 28px;
  margin-left: 15px;
  vertical-align: middle;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu .title {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu .title::after {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu .title span {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 9px;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-top: 5px;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul .title,
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul > li,
aside .h-m-menu-mainTitle {
  font-weight: 500 !important;
  font-size: 16px;
  text-transform: none;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el-notify-header::after,
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  ul
  .title::after,
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul::after,
aside .h-m-menu-lineBreak::after {
  content: "";
  display: table;
  clear: both;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul {
  margin-left: -15px;
  margin-right: -15px;
  margin: 0;
  padding: 0 15px;
  width: 100%;
  position: absolute;
  top: 110px;
  bottom: 10px;
  left: 0;
  cursor: default;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul::after {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul::after {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul > li {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: list-item;
  vertical-align: middle;
  background: 0 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul > li:hover {
  background: rgba(255, 255, 255, 0.05);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: background 0.5s;
  -moz-transition: background 0.5s;
  transition: background 0.5s;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  ul
  > li:hover
  > a {
  color: #00b4f1;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  ul
  > li:hover
  .sub_menu::after {
  color: #00b4f1;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul > li > a {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 500 !important;
  font-size: 13px;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  width: 100%;
  display: block;
  padding: 15px;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  ul
  > li
  > a.sub_menu::after {
  top: 50%;
  right: 0;
  content: ">";
  font-family: Roboto, menu, Arial, Helvetica, sans-serif !important;
  font-size: 18px;
  font-weight: 400 !important;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  margin-left: 10px;
  margin-top: 0;
  float: right;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul > li.active {
  background: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  ul
  > li.active
  > a {
  color: #fccb5f;
  padding-left: 20px;
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  ul
  > li.active
  > a.sub_menu::after {
  color: #fccb5f;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul .title {
  display: inline-block;
  margin-top: 34px;
  margin-left: 15px;
  vertical-align: middle;
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul .title {
}
aside
  .h-m-menu-inner
  ul
  li
  .h-m-menu-menuItems
  > li
  ul.submenu
  ul
  .title::after {
}
aside .h-m-menu-inner ul li .h-m-menu-menuItems > li ul.submenu ul .title span {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 400 !important;
  font-size: 9px;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-top: 5px;
}
aside .h-m-menu-mainTitle {
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
}
aside .h-m-menu-subTitle {
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-weight: 300 !important;
  font-size: 10px;
  color: #fff;
  font-style: normal !important;
  speak: none;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-top: 4px;
  line-height: 14px;
}
aside .h-m-menu-lineBreak {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 93.33333%;
  float: none;
  margin: 20px auto 0;
}
aside .h-m-menu-lineBreak {
}
aside .h-m-menu-lineBreak::after {
}
