.form__container {
  .form__inline {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;

    .form__group {
      margin-bottom: 0 !important;

      &.full-column {
        grid-column: 1/4;
      }
    }

    .actions {
      .form__group__element {
        padding-top: 12px;
        gap: 8px;
        display: flex;
        align-items: center;
      }
    }
  }
}
