.page__selector {
  display: block;

  .text {
    color: #245A6C;
    font-size: var(--h5-bold-font-size);
    font-weight: 700;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
