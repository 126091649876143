.accordion {
  --dash-line: linear-gradient(
    to right,
    #8e919c 50%,
    rgba(255, 255, 255, 0) 0%
  );
  display: block;
  margin-bottom: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 16px 0;

    .btn___expand {
      width: 32px;
      height: 32px;
      font-size: 14px;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      border-radius: 4px;
      transition: var(--animate-1);
      transform: rotate(-90deg);

      &--is-expanded {
        transform: rotate(0deg);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }

      &::before {
        margin-top: -5px;
      }
    }

    .title {
      font-size: var(--h3);
      color: #245a6c;
    }

    .left {
      display: flex;
      align-items: center;
    }

    .action {
      padding-left: 16px;
      display: flex;
      align-items: center;
      flex: 1 1 0;

      &::before {
        content: "";
        display: block;
        background-image: var(--dash-line);
        background-position: bottom;
        background-size: 8px 1px;
        background-repeat: repeat-x;
        width: 100%;
        margin-right: 0;
        height: 1px;
      }

      button {
        margin-left: 16px;
      }
    }
  }

  &__body {
    display: block;
  }

  &__body--card {
    border: 0.5px solid #b0c5e5;
    background-color: #fff;
    border-radius: 5px;
    padding: 24px;
    box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.031);
    margin-top: 16px;
    width: 100%;
  }
}
