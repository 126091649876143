.swal2-container {
  z-index: 999999 !important;

  .btn-alert {
    margin: 0 4px !important;
    min-width: 100px;
    border: none;
    outline: none;

    color: var(--button-text-white, #fff);
    /* Small/Medium */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 21px */

    border-radius: var(--button-borderRadius-sm, 4px);
    background: var(--button-success-active, #79c131);

    display: inline-flex !important;
    padding: 0 var(--layout-spacing-12);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: var(--layout-size-32);

    &:hover {
      background: var(--button-success-hover, #558722);
    }
    &.btn-alert-cancel {
      background: var(--button-primary-active, #56b5bc);
      // background: var(--button-disabled, #c1c4ce);

      &:hover {
        background: var(--button-primary-hover, #3c7f84);
      }
    }

    // &.swal2-confirm {
    //   order: 3;
    // }
  }
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgb(0 0 0 / 50%) !important;
  // backdrop-filter: blur(24px) !important;
}

.swal2-container.sys__alert {
  .swal2-popup {
    border-radius: 16px;
    padding-bottom: var(--layout-spacing-28);
  }

  .swal2-icon {
    margin: var(--layout-spacing-28) auto 0;
    // font-size: 9px;
    width: 44px;
    height: 44px;
    border: none;

    .swal2-icon-content {
      width: 44px;
      height: 44px;
    }

    img {
      max-width: 100%;
      width: 100%;
    }

    .warning__icon {
      filter: brightness(0) saturate(100%) invert(83%) sepia(56%)
        saturate(2437%) hue-rotate(302deg) brightness(95%) contrast(104%);
    }
  }

  .swal2-title {
    margin-top: var(--layout-spacing-16);
    padding: var(--layout-spacing-4);
    color: var(--font-color-shade-primary, #012732);
    font-family: Roboto;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 32.2px */
  }

  .swal2-html-container {
    margin: 0 auto;
    max-width: 70%;
    width: 70%;
    padding: var(--layout-spacing-4);
    color: var(--font-color-shade-primary, #012732);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
  }

  .swal2-actions {
    margin: var(--layout-spacing-16) auto 0;
    padding: 0;
  }
}
