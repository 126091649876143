@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

html {
  height: 100%;
}

body {
  background: linear-gradient(135deg, #2a99ef 20%, #52d9e5 80%);
  background-repeat: no-repeat;
}

.uploadInput {
  /* padding: 10px; */
  /* border: 1px solid #ccc; */
  /* background: linear-gradient(135deg, #ffa000 20%, #ff5000 80%); */
  color: #000;
  max-width: 500px;
  /* box-shadow: 2px 2px 5px 2px hsla(0, 0%, 0%, 0.15); */
}

.imagePreviewContainer {
  display: flex;
  padding-top: 10px;
  flex: 1 1 100vw;
  flex-flow: row wrap;
}

.previewItem {
  display: flex;
  margin: 10px;
  padding: 10px;
  flex: 0 0 250px;
  background: #fff;
  box-shadow: 1px 2px 5px 0px hsla(0, 0%, 0%, 0.35);
}

.imagePreview {
  max-width: 50;
  height: 50px;
}

.details {
  padding: 10px;
}

.details h6 {
  font-size: 10pt;
  fong-weight: normal;
  color: #999;
  padding: 5px;
  margin: 0;
}

.details i {
  cursor: pointer;
}

.dragDrop {
  margin-top: 30px;
  margin-left: 30px;
  height: 113px;
  background: hsla(0, 0%, 100%, 0.25);
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  color: #cad7db;
  border: 2px dashed #eee;
  width: 258px;
}

.dragging {
  background-color: #fff;
  color: lightsteelblue;
}

.dragging i.material-icons {
  animation: bounce 1s infinite ease-in-out;
}

.inside {
  margin-top: 10%;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}


.dragDrop.layout-auto {
  margin-top: 0;
  margin-left: 0;
  height: 100%;
  width: 100%;
}