@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

#mid{
    width: 96%; padding: 10px 2% 10px 2%;
    display: flex; flex-wrap: wrap;
    justify-content: center;
}
.midLeft, .midRight{
    width: 180px; background-color: rgba(255,255,255,0.2);
    margin: 5px 2% 5px 0; border-radius: 8px;
    height: 600px; box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.list{
    height: 300px; display: flex; align-items: center; width: 180px;
}
.list ul li{
    display: flex; align-items: center;
    margin: 5px; width: 170px;
    height: 50px;
    cursor: pointer;
}

.list img{
    width: 20px; height: 20px; float: left;
    padding-left: 10px;
}
.list ul li span{
    padding-left: 10px; font-weight: 700;
}
li{
    list-style: none;
}
.midRight{
    width: 300px; display: flex; flex-direction: column;
    align-items: center; justify-content: space-evenly;
}
.midRight h2{
    font-weight: 900; font-size: 30px;
    color: #245a6c;
}
.midRight li{
    margin: 8px; cursor: pointer;
    padding: 5px; background-color: rgba(255,255,255,0.1);
    border-radius: 8px;
}
.midRight li h3{
    color: #845EC2; font-weight: 700;
}
.midMid{
    width: 50%;
    flex-direction: column;
    display: flex; align-items: center;
    margin: 5px 2% 5px 0;
}
.midMid1, .midMid2{
    background-color: rgba(255,255,255,0.2); width: 100%;
    display: flex; justify-content: space-evenly;
    align-items: center; height: 100px; border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    margin-bottom: 20px; flex-wrap: wrap;
}
.topHead{
    width: 30%;
}
.topHead h1{
    font-size: 35px; font-weight: 900;
    color: #245a6c;
}
.topHead p{
    color: #6FC0C6; font-weight: 900;
}
.midMid1 ul{
    width: 60%; text-align: center;
    display: flex; align-items: center; justify-content: space-evenly;
}
.midMid1 ul li h3{
    font-weight: 900; font-size: 30px;
}
.midMid2{
    height: 50px;
}
.midMid2 ul{
    width: 90%; justify-content: space-evenly; align-items: center; height: 90%;
    display: flex;
}
.midMid2 ul li{
    cursor: pointer; font-size: 15px;
    font-weight: 700; width: 22%; justify-content: center; align-items: center; height: 80%;
    display: flex;
}
.midMid2 ul hr{
    width: 1px; height: 18px; background-color: black; border: none;
}
.activeMid{
    color: #6FC0C6;
    border-radius: 2px;
}
.midMid3{
    width: 100%; border-radius: 8px; display: flex;
    flex-wrap: wrap; align-items: center; justify-content: space-evenly;
}
.progress{
    display: flex; flex-direction: column; justify-content: space-evenly;
    width: 40%; background-color: rgba(255,255,255,0.1); margin: 2%;
    padding: 3%; border-radius: 8px; box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
progress{
    height: 5px;
    width: 100%;
    background-color: #245a6c;
}
.progress h3{
    font-size: 22px; color:#245a6c;
}
.progress a{
    font-size: 15px; height: 35px;
    margin-top: 10px; display: flex; align-items: center;
    justify-content: center; border-radius: 8px;
    width: 40%; text-align: center;
    background-color:#6FC0C6; color: white;
}
#footer{
    float: right;
}
#footer a{
    text-transform: uppercase;
    font-weight: 900;
    color: black;
}
@media only screen and (max-width: 900px) {
    #nav{
        justify-content: space-between;
    }
    .navLeft{
        margin-left: 10px;
    }
    .menu{
        width: 40px; height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .menu .bar{
        display: block;
        position: absolute;
        width: 25px; height: 2px;
        background-color: black;
    }
    .b1{
        transform: translateY(-5px);
    }
    .b3{
        transform: translateY(5px);
    }
    #navList{
        display: none;
        flex-direction: column;
        position: absolute;
        top: 90px;
        height: 200px;
        z-index: 100; box-shadow: 0 0 10px rgba(0,0,0,0.1);
        border-radius: 5px;
        left: 0;
        width: 96%;
        margin: 5px 2% 5px 2%;
        background-color: #fff;
    }
    #navList li a{
        text-align: left;
        width: 120px;
        padding: 5px 0;
    }
    #navList li img{
        float: left; width: 18px; height: 18px;
    }
    #navList li a span{
        display: flex; font-size: 16px;
        padding: 0 5px; font-weight: 400;
        color: black;
    }
    .search{
        display: none;
    }
    .symbol{
        display: block;
        width: 50px; height: 50px;
        background-color: rgba(255,255,255,0.2);
        display: flex; align-items: center; justify-content: center;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        border-radius: 50%; cursor: pointer; position: absolute;
        right: 5px; bottom: 5px; position: fixed;
    }
    .cross{
        width: 25px; height: 3px;
        background-color: black;
        position: absolute; border-radius: 100px;
    }
    .c2{
        transform: rotate(90deg);
    }
    .midLeft{
        display: none;
        background-color: white;
        width: 50px; height: 300px;
        position: absolute; bottom: 70px;
        right: 0; position: fixed;
    }
    .list{
        width: 50px;
    }
    .list ul li{
        width: 40px;
    }
    .list ul li span{
        display: none;
    }
    .midRight{
        width: 98%;
    }
    .midRight ul li{
        width: 95%;
    }
    .midMid{
        width: 100%;
    }
    .midMid1{
        flex-direction: column; height: 200px;
    }
    .topHead{
        width: 100%; text-align: center;
    }
    .midMid1 ul{
        width: 100%;
    }
    .midMid2 hr{
        display: none;
    }
    .progress{
        width: 100%; min-height: 200px;
    }
}