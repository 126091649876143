.backdrop__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--zindex-modal-backdrop);
  overflow-y: auto;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
}

