.sys__logo {
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    width: var(--layout-size-24);

    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
