.float__actions {
  pointer-events: none;
  z-index: 49;
  &__iw {
    pointer-events: auto;
    box-shadow: 0 -2px 6px 0px rgb(0 0 0 / 7%);
    border-bottom-left-radius: var(--border-radius-16);
    border-bottom-right-radius: var(--border-radius-16);

    .action__item {
      display: flex !important;
      gap: 8px;

      button {
        min-width: 90px;
      }
    }
  }
}
