.user__setting {
  display: flex;
  flex-direction: column;

  .avatar__wr {
    display: flex;
    cursor: pointer;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: #aaaeb7;
    margin: var(--layout-size-12) 0;
  }

  .btn__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 24px;
    outline: 0;
    border: none;
    background-color: transparent;
    color: var(--color-primary-200);

    &::after {
      display: none;
    }
  }
}

#setting__options {
  --ui-popover-min-width: 265px;

  left: 24px !important;
  padding: 24px 0;

  .tenet-list {
    .heading {
      font-size: var(--h6);
      font-weight: 600;
      padding: 0 20px;
      color: var(--font-default-black);
      margin-bottom: 16px;
      // text-transform: uppercase;
    }

    ul {
      display: flex;
      flex-direction: column;

      li {
        .item__inner {
          border: none;
          background-color: transparent;
          font-size: var(--h5);
          width: 100%;
          outline: none;
          text-align: left;

          transition: var(--animate-1);
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          &:hover {
            background: var(--color-accent-75);

            .btn__tenant__setting {
              display: flex;
            }
          }

          &.active {
            background: var(--color-accent-50);
            font-weight: 600;

            .btn__tenant__setting {
              display: flex;
            }
          }

          span {
            display: block;
            padding: 10px 0 10px 20px;
            color: var(--color-primary-200);
            flex: 1;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .btn__tenant__setting {
            display: none;
            --icon-button-icon-color: var(--color-primary-200);
            margin: 0 12px 0 12px;
            width: var(--icon-button-size) !important;

            &:hover {
              background: var(--layout-background-n40);
              border-radius: 99px;
            }
          }
        }
      }
    }
  }

  .line {
    margin: 12px 0;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .user_info {
    padding: 0 24px;
    display: flex;
    align-items: center;

    .start {
      flex: 1;
      min-width: 0;

      .name {
        font-size: var(--h5);
        color: hsl(195, 50%, 28%);
        font-weight: 600;
      }

      .email {
        font-size: var(--h6);
        color: hsl(195, 50%, 58%);
      }
    }

    .end {
      margin-right: var(--layout-spacing-minus-12);
      
      .btn__user__edit {
        --icon-button-icon-color: var(--font-tint-04);

        &:hover {
          --icon-button-icon-color: var(--font-tint-01);
        }
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;

    button {
      width: 100%;
    }
  }
  ul {
    margin: 0;
    padding: 0;

    li {
    }
  }
}
