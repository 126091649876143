

.card {
  .info {
    border: none;
    padding: 0;
    margin: 0;

    &__text {
      padding: 0;
    }
  }


  .btn__read {
    border: none;
    outline: 0;
    background-color: transparent;
    color: var(--text-clr-gray-500);
    transition: var(--animate-1);

    &:hover {
      color: var(--text-clr-1);
    }

    &.is__read {
      color: var(--text-clr-1);
      cursor: auto;
    }
  }
}
