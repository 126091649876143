

.setting__sidebar {

    --border-clr: #B0C5E5;
    --setting-box-shadow: rgba(0, 0, 0, 0.03) 0px 40px 60px;
    --radius:5px;
    
    max-width: 250px;
    min-width: 250px;
    display: block;
    border: solid 1px var(--border-clr);
    box-shadow: var(--setting-box-shadow);
    border-radius: var(--radius);
    overflow: hidden;
    margin-bottom: 40px;
    
}