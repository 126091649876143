.ui__popover {
  --ui-popover-max-width: 276px;
  --ui-popover-min-width: 276px;
  z-index: 5;
  --ui-popover-shadow: 0 1px 6px 2px rgba(0, 0, 0, 0.12);

  background-color: #fff;
  border-radius: 8px;
  max-width: var(--ui-popover-max-width);
  min-width: var(--ui-popover-min-width);
  box-shadow: var(--ui-popover-shadow);
}
