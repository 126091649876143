.setting-form {
  & + .setting-details {
    margin-top: 24px;
  }
}

.form__group--button {
  padding-top: 0;
}

.sm-rmsc {
  --rmsc-h: 32px;
}

.disc--list {
  margin: 0 32px;
  padding: 0;
  display: block;
  margin-right: 0;

  li {
    list-style: disc;
    font-size: var(--h5);
    color: #404a5f;
    padding: 4px 0;

    &::marker {
      color: #e89f7a;
      font-size: 16px;
      line-height: 1;
    }
  }
}


.client-region-countries {
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
}
