.text__input {
  display: flex;
  align-items: center;

  .input-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    color: var(--input-icon-color);
    pointer-events: none;
  }

  &__right--action {

   input {
    padding-right: 35px !important;
   }

    .right-action {
        position: absolute;
        right: 3px;
        top: 3px;
        width: 32px;
        height: 32px;
    }
  }
}
