$color_1: #347084;
$color_2: #ffffff;
$color_3: #b0c5e5;
$background-color_1: #ffffff;
$border-color_1: #fafdff;

.layout {
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  grid: "header header header" auto "leftSide body ." 1fr "footer footer footer" auto / auto 1fr auto;
  gap: 0.0625em;
}
.header-l {
  grid-area: header;
  // height: 2rem;
  top: 0em;
  left: 0em;
  // width: 120em;
  // height: 3.125em;
  background: #ffffff;
  -webkit-box-shadow: 0px 0.75em 0.875em #b0c5e50f;
  box-shadow: 0px 0.75em 0.875em #b0c5e50f;
  opacity: 1;
  position: -webkit-sticky;
  position: sticky;
  z-index: 9;
}
.body {
  grid-area: body;
  top: 0em;
  left: 0em;
  // width: 1920px;
  // height: 1080px;
  background: #fafdff;
  opacity: 1;
}
.hnormal {
  font: normal normal 0.9375em Roboto;
  letter-spacing: -0.025em;
}
.header-two-bars {
  font: 13px Roboto, Helvetica, sans-serif;
  .header-limiter {
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 100%;
    min-height: 100%;
  }
  h1 {
    float: left;
    font: normal 1.75em Roboto, Cookie, Arial, Helvetica, sans-serif;
    line-height: 2.8em;
    margin: 0;
    span {
      color: $color_1;
    }
  }
  .header-first-bar-l {
    background-color: $background-color_1;
    -webkit-box-shadow: 0 0.0625em 0.0625em #ccc;
    box-shadow: 0 0.0625em 0.0625em #ccc;
    padding: 8px 16px;
    // height: 3.85em;
    color: $color_1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-bottom-style: ridge;
    border-width: px;
    border-color: $border-color_1;
    a {
      color: $color_1;
      text-decoration: none;
    }
    nav {
      font: normal normal normal 0.625em/0.8125em Roboto;
      letter-spacing: 0.0125em;
      color: $color_1;
      text-transform: uppercase;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      padding: 0;

      a {
        display: inline-block;
        padding: 0 5px;
        opacity: 0.9;
        text-decoration: none;
        line-height: 1;
        &:hover {
          opacity: 1;
        }
      }
      a.selected {
        border-radius: 2px;
        background-color: $background-color_1;
        padding: 8px 12px;
      }
    }
    a.signup-button {
      font-weight: bold;
      order: 1;

      border-radius: 3px;
      background: #347084 0% 0% no-repeat padding-box;
      height: 28px;
      width: 102px;
      padding: 0 20px;
      border: 1px solid #5e6367;
      font: normal normal normal 10px/13px Roboto;
      letter-spacing: 0.2px;
      color: $color_2;
      text-transform: uppercase;
      line-height: 27px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    a.h-button {
      font-weight: bold;
      float: right;
      background: transparent 0% 0% no-repeat padding-box;
      height: 40px;
      padding: 0 20px;
      font: normal normal normal 19px/16px Roboto;
      letter-spacing: 0px;
      color: $color_3;
      line-height: 40px;
      margin-right: 5px;
      margin-top: 5px;
    }
  }
  .header-second-bar {
    background-color: $background-color_1;
    -webkit-box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, 0.05);
    padding: 10px 106px;
    height: 10px;
    h2 {
      line-height: 40px;
      margin: 0;
      float: left;
      a {
        font-size: 16px;
        color: $color_1;
        text-decoration: none;
      }
    }
    nav {
      text-align: left;
      line-height: 20px;
      font: normal normal medium 11px/13px Roboto;
      letter-spacing: -0.22px;
      color: $color_1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      a {
        display: inline-block;
        color: $color_1;
        text-decoration: none;
      }
      i.fa {
        color: $color_1;
        margin: 0 4px 0 15px;
      }
    }
  }
}
@media all and (max-width: 800px) {
  .header-two-bars {
    .header-second-bar {
      nav {
        font-size: 14px;
      }
    }
  }
}
@media all and (max-width: 600px) {
  .header-two-bars {
    .header-first-bar {
      padding: 20px 0;
      h1 {
        float: none;
        margin: -8px 0 2px;
        text-align: center;
        font-size: 24px;
        line-height: 1;
      }
      nav {
        margin: 0;
        float: none;
        font-size: 13px;
        a.selected {
          padding: 5px 8px;
        }
      }
      .logout-button {
        display: none;
      }
    }
    .header-second-bar {
      padding: 20px 0;
      h2 {
        float: none;
        margin: 0 0 12px;
      }
      nav {
        text-align: center;
        a {
          display: block;
          padding: 8px;
        }
        i.fa {
          margin-left: 0;
        }
      }
    }
  }
}
