.member {
  --item--gap: -12px;

  display: flex;
  align-items: center;
  // margin-right: 24px;

  .dot {
    width: 12px;
    height: 12px;
    background-color: #e89f7a;
    border-radius: 999px;
    border: none;
    outline: none;
    margin-right: 20px;
    // margin-left: 24px;
  }

  .divider.small {
    height: 24px;
  }
}

.member__list {
  // display: flex;
  // align-items: center;
  margin-right: 4px;

  // .member__item {
  //   display: flex;
  //   margin-left: var(--item--gap);
  //   transition: var(--animate-1);
  //   z-index: 1;

  //   &:hover {
  //     scale: 1.1;
  //     z-index: 2;
  //   }
  // }

  // .btn__more__member {
  //   background-color: #afbbbb;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: var(--h6);
  //   text-align: center;
  //   border-radius: 999px;
  //   border: 2px solid #fff;
  //   color: #fff;
  //   width: 26px;
  //   height: 26px;
  //   max-width: 26px;
  //   max-height: 26px;
  //   margin-left: var(--item--gap);
  //   z-index: 2;
  // }
}
