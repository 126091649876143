@import "./base/index.scss";

#root,
body {
  min-height: 100vh;

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--layout-background-n50) rgba(0, 0, 0, 0);
  }

  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--layout-background-n50) rgb(255, 255, 255);
  }

  // ::-webkit-scrollbar {
  //   width: 12px;
  // }

  // /* Track */
  // ::-webkit-scrollbar-track {
  //   // background: yellow;
  // }

  // /* Handle */
  // ::-webkit-scrollbar-thumb {
  //   background: #525252;
  //   border-radius: 8px !important;
  // }
}

.base__content__contain__area {
  height: calc(100vh - var(--nav-height));
}

.event-pointer {
  cursor: pointer;
}

.grid-col-1\/4 {
  grid-column: 1 / 4;
}
.grid-col-1\/2 {
  grid-column: 1 / 2;
}
.grid-col-1\/3 {
  grid-column: 1 / 3;
}

.bt {
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;

  &__size-36 {
    width: 36px;
    height: 36px;
  }

  &__font-20 {
    font-size: 20px;
  }

  &__font-18 {
    font-size: 18px;
  }
}

.btn-base {
  border: none;
  outline: none;

  &.btn-space-0 {
    padding: 0;
    margin: 0;
  }

  &.btn-bg-none {
    background-color: transparent;
  }

  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
}

.input_field {
  position: relative;

  .lbl,
  span.lbl {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
  }
}

.fields-list {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  li {
    display: inline-flex;
    margin: 0 4px;
    font-size: var(--h5);
    font-weight: 600;
    &::after {
      content: ",";
    }

    &:last-child {
      &::after {
        content: "";
      }
    }
  }
}

.page__content.full-h {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .page__content__iW {
    min-height: 100%;
    flex: 1 1 0px;
  }
}

.doc-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .nda__conntent {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
}

.onb__member__addToShortlist {
  border: none !important;
}

.profile__uploader {
  width: 190px;
}

// ! Deprecated
.select-search {
  .select-search__option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.field__group--mb-none {
  .field__group {
    margin-bottom: 0 !important;
  }
}

@import "./plugins/sweet.alert.scss";
