.setting__panel {
  --panel-title-clr: #404a5f;
  --panel-title-last-clr: #347084;

  display: block;
  width: 100%;

  .setting__panel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .breadcrumbs {
      display: flex;
      align-items: center;

      .breadcrumb__text {
        font-size: var(--h1);
        color: var(--panel-title-clr);
        font-weight: 700;
        display: inline-flex;
        align-items: center;

        .icon-arrow {
          margin: 0 16px;
          width: 16px;
          height: 16px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
        }

        &--last {
          color: var(--panel-title-last-clr);
        }
      }
    }

    .actions {
      margin-left: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      button {
        margin-left: 8px;
      }
    }
  }
}
