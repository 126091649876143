.action-col {
  display: flex;
  align-items: center;
  gap: 4px;
}

.table__btn-action {
  font-size: var(--h5);
  cursor: pointer;
  padding: 2px 4px;
  text-align: center;
  color: #949aa6;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &.active {
    color: #56b5bc;
  }

  &.pending {
    color: #e89f7a;
  }
}

.action__button.action__button--update {
  img {
    width: 18px;
  }
}
