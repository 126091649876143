.card-container {
	* {
		box-sizing: border-box;
	}
	h3 {
		margin: 10px 0;
		font-weight: bold;
        font-size: 15px;
	}
	h6 {
		margin: 5px 0;
		text-transform: uppercase;
		font-weight: bold;
        font-size: 10px;
	}
	p {
		font-size: 10px;
		line-height: 21px;
		font-weight: bold;
	}
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
	color: #3B4263;
	padding-top: 35px;
	position: relative;
	width: 200px;
	max-width: 100%;
	text-align: center;
	.pro {
		color: #231E39;
		border-radius: 3px;
		font-size: 14px;
		font-weight: bold;
		padding: 3px 7px;
		position: absolute;
		top: 30px;
		left: 220px;
	}
	.round {
		border: 1px solid #03BFCB;
		border-radius: 50%;
		padding: 7px;
	}
	[class*='close-'] {
		color: #777;
		font: 14px/100% arial, sans-serif;
		position: absolute;
		right: 5px;
		text-decoration: none;
		text-shadow: 0 1px 0 #fff;
		top: 10px;
        cursor: pointer;
	}
	.close-edit {
		color: #777;
		font: 14px/100% arial, sans-serif;
		position: absolute;
		right: 35px;
		text-decoration: none;
		text-shadow: 0 1px 0 #fff;
		top: 10px;
        cursor: pointer;
		&:after {
			content: '';
		}
	}
}
