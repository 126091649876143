.chip__card__wrapper {
    display: flex;
    gap: 16px;
}

.chip__card {
  --chip-card-border-style: dashed #4a7f91;

  display: block;
  border-radius: 8px;
  border: var(--chip-card-border-style);
  border-width: 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: var(--animate-1);

  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  &--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: var(--chip-card-border-style);
    border-bottom-width: 1px;
    padding: 16px 16px;

    .title {
      color: #56b5bc;
      font-size: var(--h5);
      font-weight: 600;
    }

    .actions {
      display: flex;
      align-items: center;
      margin-left: 32px;

      .action__button {
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
  }

  &--body {
    display: block;
    padding: 12px 16px;
  }
}
